<template>
    <div class="" id="timeline-component">
        <goal-tags-filter-panel></goal-tags-filter-panel>
        <timeline-wrapper :width="width"></timeline-wrapper>
        <div class="row">
            <div class="col-sm-12">
                <step :task="task" v-for="task in taskOutsideTimeline" :key="task.id"></step>
            </div>
        </div>
        <div class="row margin-15-top" v-show="showMembersVisibilitySwitch">
            <div class="col-sm-12">
                <strong class="pointer" @click="expandMembers">Show other members.</strong>
            </div>
        </div>
        <div class="row margin-15-top" v-show="showOtherMembers">
            <div class="col-sm-12">
                <strong class="pointer" @click="hideMembers">Hide other members.</strong>
            </div>
        </div>
    </div>
</template>

<script>

    import Step from './Step.vue';
    import GoalTagsFilterPanel from '../../../GoalTagsFilterPanel.vue';

    import TimelineWrapper from './TimelineWrapper.vue';

    export default {
        components: {TimelineWrapper, Step, GoalTagsFilterPanel},
        props: {
            width: {
                type: Number
            }
        },
        computed: {
            taskOutsideTimeline() {
                return this.$store.getters['timeline/taskOutsideTimeline'];
            },
            totalRowCount() {
                return this.$store.getters['timeline/totalRowCount'];
            },
            showMembersVisibilitySwitch() {
                return this.$store.getters['timeline/showMembersVisibilitySwitch'];
            },
            showOtherMembers() {
                return this.$store.state.timeline.showOtherMembers;
            }
        },
        methods: {
            expandMembers() {
                this.$store.commit('timeline/set_show_other_members', true);
            },
            hideMembers() {
                this.$store.commit('timeline/set_show_other_members', false);
            },
        },
        name: "TimelineContainer"
    };
</script>

<style scoped lang="scss">


</style>