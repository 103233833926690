const WindowHeightAwareMixin = {
    data() {
        return {
            windowHeight: undefined
        };
    },
    methods: {
        onWindowHeightUpdate(windowHeight) {
            // document.body.style.height=`${windowHeight}px`;
            // document.body.style.overflow=`hidden`;
        },
        updateWindowHeight: function updateElWidth() {
            this.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
            this.onWindowHeightUpdate(this.windowHeight);
        },
    },
    mounted() {
        this.updateWindowHeight();
        window.addEventListener('resize', this.updateWindowHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowHeight);
    }
};


export default WindowHeightAwareMixin;