<template>
    <modal v-model="opened" :transition='0' :append-to-body="true" :backdrop="false">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="goal-task-steps-popup">
            <div class="goal-task-steps-popup__container">
                <div class="row " v-for="(step, i) in steps">
                    <div class="col-sm-12 goal-task-steps-popup__step-fields">
                        Sub-step {{i+1}} (<a href="javascript:;" @click.stop.prevent="removeStep(step)">Remove</a>) :
                        <input v-model="step.name" type="text" class="form-control" maxlength="255"
                               placeholder="Sub-step name"/>

                        <span class="goal-task-steps-popup__step-fields__description-link__container"
                              v-show="!step.description&&!step.showDescription">
                        <a href="javascript:;" style="font-size: 13px;" @click.stop.prevent="showDescription(step)"
                           v-show="step.name">+ Add Description</a>
                    </span>

                        <textarea :disabled="!step.name" name="" id="" rows="3" class="form-control" v-model="step.description"
                                  style="margin-top: 10px" placeholder="Step description" maxlength="3000"
                                  v-show="step.description||step.showDescription"></textarea>
                        <hr class="hr10">
                    </div>
                </div>
            </div>

        </div>
        <div class="text-right">
            <a href="javascript:;" @click.stop.prevent="addStep" v-show="canAddStep">+ Add Sub-step</a>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="closePopup">{{cancelButton}}</button>
            <button class="btn btn-mm btn-green save-button"
                    @click="save">
                {{saveButton}}
            </button>
        </div>
    </modal>
</template>

<script>
    import _ from 'lodash';
    import Vue from 'vue';

    const MAX_STEPS_COUNT = 10;
    const INITIAL_STEPS = [
        {name: '', description: ''},
        {name: '', description: ''},
        {name: '', description: ''},
    ];
    export default {
        name: "GoalTaskStepsPopup",
        data() {
            return {
                opened: false,
                steps: [],

                header: `${this.$customTerm('Task')} Sub-steps`,
                cancelButton: 'Cancel',
                saveButton: 'Next',// or next?
            };
        },
        computed: {
            canAddStep() {
                return this.steps.length < MAX_STEPS_COUNT;
            }
        },
        methods: {
            resetData() {

            },
            addStep() {
                this.steps.push({name: '', description: ''});
            },
            removeStep(step) {
                this.steps.splice(this.steps.indexOf(step), 1);
            },
            showDescription(step) {
                Vue.set(step, 'showDescription', true);
            },
            show(steps = INITIAL_STEPS) {
                this.steps = [];
                for (let step of steps) {
                    this.steps.push(Object.assign({}, step));
                }
                this.opened = true;
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });
            },
            save() {

                const stepsToSave = [];
                for (let step of this.steps) {
                    if (step.name) {
                        stepsToSave.push({name: step.name, description: step.description});
                    }
                }
                this.resolve(stepsToSave);
                this.opened = false;
            },
            closePopup() {
                this.reject('closed');
                this.opened = false;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../../styles/const";

    .goal-task-steps-popup {
        &__container {
            max-height: 70vh;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &__step-fields {
            &__description-link {
                &__container {
                    min-height: 13px;
                    width: 100%;
                    display: inline-block;
                }
            }
        }
    }
</style>