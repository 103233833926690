<template>
    <span v-if="completableInstance.completed" class="vertical-middle completion-info">
        <span v-if="completedBySigningOff">
            <span v-if="completableInstance.provided_assistance&&completableInstance.provided_assistance.length"
                  class="completion-info__assistance">
                    {{ assistanceLabel }};
                </span>
            <span v-if="completableInstance.completed_by">signed off by <strong><simple-link
                    :target="completableInstance.completed_by" tab-index="-1"></simple-link></strong>
            </span>
        </span>
        <span v-if="completedByStepsCompleted"> all sub-steps are signed off</span>
        <span v-if="completedByTimeout"> auto completed</span>
        <span v-if="completedAtTimeStamp">on <span>{{ completedAtTimeStamp }}</span></span>
    </span>
</template>

<script>
    import {PROVIDED_ASSISTANCE_OPTIONS_LABELS} from "../config";

    const DEFAULT = 0, BY_TIMEOUT = 3, BY_STEPS_COMPLETED = 4;
    import moment from 'moment';

    export default {
        props: {
            completableInstance: Object
        },
        computed: {
            completedAtTimeStamp() {
                if (this.completableInstance.completed_at) {
                    const format = this.completedByTimeout ? 'DD/MM/YYYY' : 'DD/MM/YYYY, h:mm a';
                    return moment(this.completableInstance.completed_at).format(format);
                }
            },
            completedBySigningOff() {
                return this.completableInstance.completion_type === DEFAULT;
            },
            completedByTimeout() {
                return this.completableInstance.completion_type === BY_TIMEOUT;
            },
            completedByStepsCompleted() {
                return this.completableInstance.completion_type === BY_STEPS_COMPLETED;
            },
            assistanceLabel() {
                const res = [];
                for (const assistance of this.completableInstance.provided_assistance) {
                    res.push(assistance.name || PROVIDED_ASSISTANCE_OPTIONS_LABELS[assistance.code]);
                }
                return res.join(', ')
            }
        },
        methods: {},
        name: "TaskMainInfoCompletionData"
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";

    .completion-info {
        color: $tag-gray;
        font-size: 16px;

        &__assistance {
            text-transform: lowercase;
        }
    }

</style>