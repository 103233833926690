import Vue from 'vue';
import _ from 'lodash';
import { customTermFilter } from '../../../vue/filters/CustomTerm';
import { simplePopupFactory } from 'shared/simple_popups/simplePopupFactory';
import CreateGoalCategoryPopup from '../goals/CreateGoalCategoryPopup';
import goal_categories_resource from '../goals/goal_categories_resource';
import RouterCheckAvailabilityMixin from '../../../vue/common_components/RouterCheckAvailabilityMixin';
import goal_filter_settings_resource from './goal_filter_settings_resource';
import { CurrentUserClass } from 'shared/CurrentUser';
import FinishGoalPopup from '~/angular/app/projects/FinishGoalPopup.vue';
import { GoalFactory } from '~/angular/app/projects/goal_settings_factory/goalFactory';
import GoalsListResource from '~/angular/app/projects/project_list_resource';
import UnFinishGoalPopup from '~/angular/app/projects/UnFinishGoalPopup.vue';

const InitialFilter = {
    categories: [],
    owners: [],
    show_archived: false,
    show_my: true,
    show_shared: false,
};

function customizer(objValue, othValue) {
    if (_.isBoolean(objValue) && _.isBoolean(othValue) && objValue === othValue) {
        return true;
    }
    if (_.isArray(objValue) && _.isArray(othValue) && objValue.length === othValue.length) {
        return true;
    }
}

export default {
    mixins: [RouterCheckAvailabilityMixin],
    data() {
        return {
            targetUsersID: undefined,
            targetUsersName: undefined,
            goalsListMainResource: undefined,
            loadMoreResource: undefined,
            availableCategories: [],
            categoriesForFilter: [],
            sharersNames: [],

            categories: [],

            searchQuery: '',
            filter: _.merge({}, InitialFilter)
        };
    },
    computed: {
        targetUserIsMe() {
            return this.targetUsersID === new CurrentUserClass().id;
        },
        initialFilter() {
            return _.merge({}, InitialFilter);
        },
        filterIsActive() {
            return !_.isEqualWith(this.initialFilter, this.filter, customizer);
        },
        nothingFound() {
            return !this.categories.length && (this.filterIsActive || this.searchQuery);
        },
        noGoals() {
            return !this.categories.length && !this.filterIsActive && !this.searchQuery;
        }
    },
    methods: {
        loadGoals() {
            this.$loadingSpinner.show();
            this.goalsListMainResource(this.targetUsersID, this.filter, this.searchQuery)
                .then(resp => {
                    this.categories = resp.data;
                })
                .finally(this.$loadingSpinner.hide);
        },
        loadMoreInCategory(category) {
            this.$loadingSpinner.show();
            this.loadMoreResource(this.targetUsersID, category.id, category.goals.length, 10, this.filter, this.searchQuery)
                .then(resp => {
                    category.goals = category.goals.concat(resp.data.results);
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => this.$loadingSpinner.hide());
        },
        async archiveGoal({
            goal,
            category
        }) {
            const popupAttrs = {};
            if (!this.targetUserIsMe) {
                popupAttrs.forUser = {
                    name: this.targetUsersName,
                    id: this.targetUsersID
                };
            }
            if (goal.viewer_is_admin) {
                popupAttrs.canArchiveForAll = true;
            }
            const {
                vm,
                popupComponent,
                fEl,
            } = simplePopupFactory.mount_vue_popup(FinishGoalPopup, popupAttrs);
            const res = await popupComponent.show().catch((err) => {
            });
            vm.$destroy();
            if (!res) {
                return;
            }
            try {
                this.$loadingSpinner.show();
                await GoalsListResource().archive_goal(this.targetUsersID, goal.id, { for_all: res.forAll });
                if (this.filter.show_archived) {
                    goal.is_finished_by_page_owner = true;
                    if (res.forAll) {
                        goal.is_finished = true;
                    }
                } else {
                    category.goals.splice(category.goals.indexOf(goal), 1);
                    category.count--;
                }
            } catch (e) {
                this.$notifications.error(e);
            } finally {
                this.$loadingSpinner.hide();
            }
        },
        async unArchiveGoal({
            goal,
            category
        }) {
            const popupAttrs = {};
            if (!this.targetUserIsMe) {
                popupAttrs.forUser = {
                    name: this.targetUsersName,
                    id: this.targetUsersID
                };
            }
            if (goal.viewer_is_admin) {
                popupAttrs.canArchiveForAll = true;
                popupAttrs.isCurrentlyFinished = goal.is_finished;
            }
            const {
                vm,
                popupComponent,
                fEl,
            } = simplePopupFactory.mount_vue_popup(UnFinishGoalPopup, popupAttrs);
            const res = await popupComponent.show().catch((err) => {
            });
            vm.$destroy();
            if (!res) {
                return;
            }
            try {
                this.$loadingSpinner.show();
                await GoalsListResource().un_archive_goal(this.targetUsersID, goal.id, { for_all: res.forAll });
                goal.is_finished_by_page_owner = false;
                if (res.forAll) {
                    goal.is_finished = false;
                }
            } catch (e) {
                this.$notifications.error(e);
            } finally {
                this.$loadingSpinner.hide();
            }
        },
        search(val) {
            this.searchQuery = val;
            this.loadGoals();
        },
        cancelSearch(val) {
            this.searchQuery = '';
            this.loadGoals();
        },
        getGoalFactory() {
            return new GoalFactory();
        },
        async createGoal(category = null) {
            const gf = this.getGoalFactory();
            const goalData = await gf.startGoalCreation(category?.id).catch(() => {
            });
            if (!goalData) {
                return;
            }
            this.$loadingSpinner.show();
            try {
                this.loadGoals();
            } catch (e) {
                this.$notifications.error(e);
                this.$loadingSpinner.hide();
            }
        },
        async createGoalCategory() {
            const {
                popupComponent,
                fEl,
                vm
            } = simplePopupFactory.mount_vue_popup(CreateGoalCategoryPopup);
            try {
                const res = await popupComponent.show();
                this.$loadingSpinner.show();
                try {
                    const { data } = await goal_categories_resource.create_category({
                        ...res,
                        owner: this.targetUsersID,
                    });
                    this.availableCategories.push(data);
                    this.categories.push(data);
                    this.categories = _.orderBy(this.categories, c => c.name);
                    console.log(this.categories);
                    this.$notifications.success('Category created');
                    this.$loadingSpinner.hide();
                } catch (e) {
                    this.$loadingSpinner.hide();
                    this.$notifications.error(e);
                }

            } catch (e) {
                console.log(e);
            } finally {
                vm?.$destroy();
            }
        },
        async editGoalCategory(category) {
            const {
                popupComponent,
                fEl,
                vm
            } = simplePopupFactory.mount_vue_popup(CreateGoalCategoryPopup);
            try {
                const res = await popupComponent.show(category);
                if (res === 'delete') {
                    this.$loadingSpinner.show();
                    try {
                        await goal_categories_resource.delete_category(category.id);
                        this.loadGoals();
                        this.$notifications.success('Category Deleted');
                    } catch (e) {
                        this.$loadingSpinner.hide();
                        this.$notifications.error(e);
                    }
                } else {
                    this.$loadingSpinner.show();
                    try {
                        const { data } = await goal_categories_resource.update_category(category.id, res);
                        const targetCategory = this.categories.filter(c => c.id === category.id)[0];
                        if (targetCategory) {
                            for (let k of Object.keys(data)) {
                                if (['name', 'video_description', 'audio_description', 'image'].includes(k)) {
                                    Vue.set(targetCategory, k, data[k]);
                                }
                            }
                        }
                        this.$notifications.success('Category Updated');
                        this.$loadingSpinner.hide();
                    } catch (e) {
                        this.$loadingSpinner.hide();
                        this.$notifications.error(e);
                    }
                }
            } catch (e) {
                console.log(e);
            } finally {
                vm?.$destroy();
            }
        },
        filterChanged(filterParams) {
            this.filter = filterParams;
            this.loadGoals();
        },
        openFilterPopup() {
            this.$refs.filterPopup.open();
        },
        async $_getMySavedFilterSettings() {
            const { data: filterSettings } = await goal_filter_settings_resource.get_settings(new CurrentUserClass().id);
            for (let k of Object.keys(filterSettings || {})) {
                Vue.set(this.filter, k, filterSettings[k]);
            }
        },
    }
};
