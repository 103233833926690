var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center member", style: _vm.memberRowStyle },
    [
      _c("img", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.member.name,
            expression: "member.name",
          },
        ],
        staticClass: "img-circle",
        attrs: { src: _vm.member.avatar, alt: "", width: "60", height: "60" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }