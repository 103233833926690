<template>
    <modal v-model="opened" :transition='0' :append-to-body="true">
        <div slot="header">
            <a id="close_popup" @click="closePopup"></a>
            <h3 class="modal-title">{{header}}</h3>
        </div>
        <div class="goal-task-steps-popup">
            <div class="row">
                <div class="col-sm-12">
                    <label for="step-name">Sub-step Name:</label>
                    <input id="step-name" v-model="name" type="text" class="form-control" maxlength="255"
                           placeholder="Sub-step name"/>
                </div>
            </div>
            <hr class="hr10">
            <div class="row ">
                <div class="col-sm-12">
                    <label for="step-description">Sub-step Description:</label>
                    <textarea id="step-description" name="" rows="5" class="form-control"
                              v-model="description"
                              style="margin-top: 10px" placeholder="Step description" maxlength="3000"
                    ></textarea>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-xs-4 text-left">
                    <button class="btn btn-mm btn-red" v-if="deleteButtonAllowed" @click="deleteStep">{{deleteButton}}
                    </button>
                </div>
                <div class="col-xs-8">
                    <button class="btn btn-mm" @click="closePopup">{{cancelButton}}</button>
                    <button class="btn btn-mm btn-green save-button" :disabled="!allowSave"
                            @click="save">
                        {{saveButton}}
                    </button>
                </div>
            </div>

        </div>
    </modal>
</template>

<script>
    const DEFAULT_POPUP_CONFIG = {
        saveButton: 'Save',
        cancelButton: 'Cancel',
        deleteButton: 'Remove',
        deleteButtonAllowed: false,
        header: 'Create Step',
        info: '',
    };
    export default {
        name: "StepAddEditRemovePopup",
        data() {
            return {
                opened: false,
                name: '',
                description: '',

                initialName: '',
                initialDescription: '',

                saveButton: DEFAULT_POPUP_CONFIG.saveButton,
                cancelButton: DEFAULT_POPUP_CONFIG.cancelButton,
                deleteButton: DEFAULT_POPUP_CONFIG.deleteButton,
                deleteButtonAllowed: DEFAULT_POPUP_CONFIG.deleteButtonAllowed,
                header: DEFAULT_POPUP_CONFIG.header,
                info: DEFAULT_POPUP_CONFIG.info,

            };
        },
        computed: {
            allowSave() {
                if (!this.name) {
                    return false;
                }
                if (this.initialName) {
                    if (this.initialName === this.name && this.initialDescription === this.description) {
                        return false;
                    }
                }
                return true;
            }
        },
        methods: {
            show(initial = {name: '', description: ''}, popupConfig = {}) {
                this.opened = true;
                this.initialName = this.name = initial.name;
                this.initialDescription = this.description = initial.description;
                for (let key in DEFAULT_POPUP_CONFIG) {
                    this[key] = DEFAULT_POPUP_CONFIG[key];
                }
                for (let key in popupConfig) {
                    this[key] = popupConfig[key];
                }
                return new Promise((res, rej) => {
                    this.resolve = res;
                    this.reject = rej;
                });

            },
            closePopup() {
                this.opened = false;
            },
            save() {
                this.resolve({
                    type: 'save',
                    data: {
                        name: this.name,
                        description: this.description,
                    }
                });
                this.opened = false;
            },
            deleteStep() {
                this.resolve({
                    type: 'delete',
                });
                this.opened = false;
            }
        }
    };
</script>

<style scoped>

</style>