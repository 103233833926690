var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "navigation-group" },
      [
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isMember,
                expression: "isMember",
              },
            ],
            attrs: {
              to: { name: "GoalDetailPageMainTasks", query: _vm.$route.query },
              replace: "",
            },
          },
          [
            _c("vue-folder-button", {
              attrs: {
                active: _vm.$route.name === "GoalDetailPageMainTasks",
                text: _vm.tasksLabel,
                "icon-class": "fa-tasks",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isMember,
                expression: "isMember",
              },
            ],
            staticClass: "hidden-xs",
            attrs: {
              to: {
                name: "GoalDetailPageMainTimeline",
                query: _vm.$route.query,
              },
              replace: "",
            },
          },
          [
            _c("vue-folder-button", {
              attrs: {
                active: _vm.$route.name === "GoalDetailPageMainTimeline",
                text: "Timeline",
                "icon-class": "fa-clock-o",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "GoalDetailPageMainAbout", query: _vm.$route.query },
              replace: "",
            },
          },
          [
            _c("vue-folder-button", {
              attrs: {
                active: _vm.$route.name === "GoalDetailPageMainAbout",
                text: "About",
                "icon-class": "fa-info",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isMember &&
                  (!_vm.isShowMoreNeeded || _vm.showMorePressed),
                expression: "isMember&&(!isShowMoreNeeded||showMorePressed)",
              },
            ],
            staticClass: "hidden-xs",
            attrs: {
              to: {
                name: "GoalDetailPageMainAttachments",
                query: _vm.$route.query,
              },
              replace: "",
            },
          },
          [
            _c("vue-folder-button", {
              attrs: {
                active: _vm.$route.name === "GoalDetailPageMainAttachments",
                text: "Attachments",
                "icon-class": "fa-file-text",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isMember &&
                  (!_vm.isShowMoreNeeded || _vm.showMorePressed),
                expression: "isMember&&(!isShowMoreNeeded||showMorePressed)",
              },
            ],
            staticClass: "hidden-xs",
            attrs: {
              to: {
                name: "GoalDetailPageMainMembers",
                query: _vm.$route.query,
              },
              replace: "",
            },
          },
          [
            _c("vue-folder-button", {
              attrs: {
                active: _vm.$route.name === "GoalDetailPageMainMembers",
                text: "Members",
                "icon-class": "fa-users",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("vue-folder-button", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowMoreNeeded,
              expression: "isShowMoreNeeded",
            },
          ],
          staticClass: "hidden-xs",
          attrs: {
            text: "",
            "icon-class": "fa-ellipsis-h",
            color: "color-header",
            "inner-color": "#9c9c9c",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.showMore.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "actions-group" },
      [
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isAdmin,
                expression: "isAdmin",
              },
            ],
            staticClass: "hidden-xs",
            attrs: {
              to: { name: "GoalDetailPageExports", query: _vm.$route.query },
              replace: "",
            },
          },
          [
            _c("vue-folder-button", {
              attrs: {
                "no-text-on-mobile": "",
                active: _vm.$route.name === "GoalDetailPageExports",
                text: "Export PDF",
                "icon-class": "fa-arrow-down",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.filterAllowed
          ? _c("vue-folder-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$route.name === "GoalDetailPageMainTasks",
                  expression: "$route.name==='GoalDetailPageMainTasks'",
                },
              ],
              staticClass: "hidden-xs",
              attrs: {
                color: _vm.filterButtonColor,
                text: _vm.filterTasksLabel,
                "max-width": 100,
                "icon-class": "fa-filter",
                "no-text-on-mobile": true,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.editFilter.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isFullScreenPossible
          ? _c("vue-folder-button", {
              staticClass: "hidden-xs",
              attrs: {
                text: "Full Screen",
                "icon-class": "fa-expand-arrows-alt",
                "no-text-on-mobile": true,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.runFullscreen.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isEditModePossible
          ? _c(
              "vue-folder-button",
              {
                attrs: {
                  text: _vm.editModeButtonLabel,
                  "icon-class": "fa-pencil",
                  "no-text-on-mobile": true,
                  "icon-prefix": "far",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleEditMode.apply(null, arguments)
                  },
                },
              },
              [
                _vm.editMode
                  ? _c(
                      "span",
                      {
                        staticClass: "fa-stack",
                        staticStyle: { width: "32px" },
                        attrs: { slot: "icon" },
                        slot: "icon",
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-pencil fa-stack-1x",
                          staticStyle: { position: "absolute", top: "-1px" },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "far fa-slash fa-stack-1x fa-inverse",
                          staticStyle: { position: "absolute", top: "-1px" },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.iCanMakeMeAdmin
          ? _c("vue-folder-button", {
              attrs: {
                text: "Make Me Admin",
                "icon-class": "fa-user-secret",
                "no-text-on-mobile": true,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.makeMeAdmin.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("vue-folder-button", {
          staticClass: "hidden-xs",
          attrs: {
            text: "Back",
            color: "blue",
            "icon-class": "fa-reply",
            "no-text-on-mobile": true,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.goBack.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "dropdown",
          { attrs: { "menu-right": "" } },
          [
            _c("vue-folder-button", {
              staticClass: "dropdown-toggle hidden-sm hidden-md hidden-lg",
              attrs: {
                "icon-class": "fa-ellipsis-v",
                "no-text-on-mobile": "",
                text: "Menu",
                role: "menu",
                id: " menu-dropdown",
              },
            }),
            _vm._v(" "),
            _c("template", { slot: "dropdown" }, [
              _vm.isMember
                ? _c(
                    "li",
                    {
                      staticClass: "dropdown-menu-link",
                      class: {
                        "dropdown-menu-link_active":
                          _vm.$route.name === "GoalDetailPageMainTimeline",
                      },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "GoalDetailPageMainTimeline",
                              query: _vm.$route.query,
                            },
                            replace: "",
                            role: "button",
                            "aria-owns": "menu-dropdown",
                          },
                        },
                        [
                          _c("i", { staticClass: "far fa-clock" }),
                          _vm._v(" Timeline\n                    "),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isMember
                ? _c(
                    "li",
                    {
                      staticClass: "dropdown-menu-link",
                      class: {
                        "dropdown-menu-link_active":
                          _vm.$route.name === "GoalDetailPageMainAttachments",
                      },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "GoalDetailPageMainAttachments",
                              query: _vm.$route.query,
                            },
                            replace: "",
                            role: "button",
                            "aria-owns": "menu-dropdown",
                          },
                        },
                        [
                          _c("i", { staticClass: "far fa-file-alt" }),
                          _vm._v(" Attachments\n                    "),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isMember
                ? _c(
                    "li",
                    {
                      staticClass: "dropdown-menu-link",
                      class: {
                        "dropdown-menu-link_active":
                          _vm.$route.name === "GoalDetailPageMainMembers",
                      },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "GoalDetailPageMainMembers",
                              query: _vm.$route.query,
                            },
                            replace: "",
                            role: "button",
                            "aria-owns": "menu-dropdown",
                          },
                        },
                        [
                          _c("i", { staticClass: "far fa-users" }),
                          _vm._v(" Members\n                    "),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "li",
                    {
                      staticClass: "dropdown-menu-link",
                      class: {
                        "dropdown-menu-link_active":
                          _vm.$route.name === "GoalDetailPageExports",
                      },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "GoalDetailPageExports",
                              query: _vm.$route.query,
                            },
                            replace: "",
                            role: "button",
                            "aria-owns": "menu-dropdown",
                          },
                        },
                        [
                          _c("i", { staticClass: "far fa-arrow-down" }),
                          _vm._v(" Export PDF\n                    "),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.filterAllowed && _vm.$route.name === "GoalDetailPageMainTasks"
                ? _c(
                    "li",
                    {
                      staticClass: "dropdown-menu-link",
                      attrs: { "aria-owns": "menu-dropdown" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#", role: "button", tabindex: "0" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.editFilter.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "far fa-filter" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.filterTasksLabel) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFullScreenPossible
                ? _c(
                    "li",
                    {
                      staticClass: "dropdown-menu-link",
                      attrs: { "aria-owns": "menu-dropdown" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#", role: "button", tabindex: "0" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.runFullscreen.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "far fa-expand-arrows-alt" }),
                          _vm._v(" Full Screen\n                    "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("li", {
                staticClass: "divider",
                attrs: { role: "separator" },
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "dropdown-menu-link",
                  attrs: { "aria-owns": "menu-dropdown" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#", role: "button", tabindex: "0" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.goBack.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "far fa-reply" }),
                      _vm._v(" Back\n                    "),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }