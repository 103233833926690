<template>
    <div class="">
        <div :style="staticBackgroundStyle"></div>
        <div id="timeline-cancel-fullscreen-panel" v-if="fullScreenEnabled||fullScreenFallbackEnabled"
             @click="onStopFullscreen">
            <i class="far fa-compress-arrows-alt" id="timeline-cancel-fullscreen-panel__collapse-icon"></i>
        </div>
        <div id="timeline-container" class="timeline-container" :style="timelineContainerStyle"
        >
            <div class="timeline-container__inner" :style="timelineContainerInnerStyle"
                 ref="timeline-container__inner">
                <members-list :width="membersListWidth" :content-height="requiredTimelineContentHeight"></members-list>
                <timeline
                        :width="timelineWidth"
                        ref="timeline"
                        :content-height="requiredTimelineContentHeight"
                        @offsetYChanged="onTimelineOffsetYChanged"
                ></timeline>
            </div>
            <scroller :width="usedWidth" @onPosChanged="scrollerPosChanged" style="z-index:5;"></scroller>
            <modal ref="modal" v-model="opened" :transition='0' :append-to-body="false">
                <div slot="header" v-if="openedStep">
                    <a id="close_popup" @click="closePopup"></a>
                    <h3 class="modal-title">{{openedStep.name}}</h3>
                </div>
                <div v-if="openedStep">
                    Show some basic task data here.
                    e.g. description, steps, comments count.
                </div>
                <div slot="footer">
                    <div class="row">
                        <div class="col-xs-12">
                            <button class="btn btn-mm btn-green" @click="openDetailedTaskPage">Open Detail</button>
                            <button class="btn btn-mm save-button" @click="closePopup">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import Timeline from './Timeline.vue';
    import MembersList from './MembersList.vue';
    import Scroller from './Scroller.vue';
    import {goalHeaderBus, GOAL_HEADER_BUS_ACTIONS} from '../GoalDetailPageHeader/goalHeaderBus';
    import {fullScreenBus, FULL_SCREEN_BUS_EVENTS} from './fullScreenBus.js';
    import WindowHeightAwareMixin from "../../../../../../vue/mixins/WindowHeightAwareMixin";
    // import Modal from "uiv/src/components/modal/Modal";

    export default {
        name: "TimelineWrapper",
        mixins: [WindowHeightAwareMixin],
        components: {Timeline, MembersList, Scroller},
        props: {
            width: {
                type: Number
            }
        },
        data() {
            return {
                opened: false,
                openedStep: undefined,

                timelineOffsetY: 0,
            };
        },

        computed: {
            fullScreenEnabled() {
                return this.$store.state.timeline.fullScreenEnabled;
            },
            fullScreenFallbackEnabled() {
                return this.$store.state.timeline.fullScreenFallbackEnabled;
            },
            requiredTimelineContentHeight() {
                return this.$store.state.timeline.rowHeight * this.totalRowCount + 60 + 25;
            },
            contentCanBeScrolled() {
                return this.requiredTimelineContentHeight > this.windowHeight;
            },
            staticBackgroundStyle() {
                if (!this.fullScreenFallbackEnabled) {
                    return {'display': 'none'};
                } else {
                    return {
                        'position': 'fixed',
                        'top': '0',
                        'left': '0',
                        'width': '100%',
                        'height': `${this.windowHeight}px`,
                        'background-color': '#333',
                        'display': 'block',
                        'z-index': '1022',
                    };
                }
            },
            usedWidth() {
                if (this.fullScreenEnabled || this.fullScreenFallbackEnabled) {
                    return document.body.scrollWidth;
                } else {
                    return this.width;
                }
            },
            totalRowCount() {
                return this.$store.getters['timeline/totalRowCount'];
            },
            membersListWidth() {
                if (this.usedWidth > 500) {
                    return 80;
                } else {
                    return 60;
                }

            },
            timelineWidth() {
                return this.usedWidth - this.membersListWidth;
            },
            timelineContainerStyle() {
                const style = {
                    'background-color': '#f2f4f9',
                };
                // style['height'] = '500px';
                style['overflow'] = 'hidden';
                if (this.fullScreenFallbackEnabled) {
                    style['position'] = 'fixed';
                    style['top'] = '0';
                    style['left'] = '0';
                    style['width'] = '100%';
                    style['z-index'] = '1023';
                }
                if (!this.contentCanBeScrolled && (this.fullScreenFallbackEnabled || this.fullScreenEnabled)) {
                    const offsetTop = Math.round((this.windowHeight - this.requiredTimelineContentHeight) / 2);
                    style['top'] = `${offsetTop}px`;
                    // style['height'] = `${this.windowHeight}px`;
                }
                return style;
            },
            timelineContainerInnerStyle() {
                const style = {};
                if (this.contentCanBeScrolled && (this.fullScreenFallbackEnabled || this.fullScreenEnabled)) {
                    style['height'] = `${this.windowHeight}px`;
                    style['overflow'] = 'hidden';
                }
                return style;
            },
            requestFullscreenFunction() {
                const el = this.timelineContainer;
                return el.requestFullscreen || el.webkitRequestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.mozRequestFullscreen || el.msRequestFullscreen;
            },
            requestFullscreenAvailable() {
                return _.isFunction(this.requestFullscreenFunction);
            }
        },
        methods: {
            openDetailedTaskPage() {

                document.location.href = this.openedStep.link;
            },
            closePopup() {
                this.opened = false;
                this.openedStep = undefined;
            },
            scrollerPosChanged(val) {
                this.$refs.timeline.scrollPosChanged(val);
            },

            runFullScreenNative() {

            },
            runFullscreenFallback() {
                (document.body || document.getElementsByTagName('body')[0]).style.overflow = 'hidden';
                this.$store.commit('timeline/toggle_fullscreen_fallback_mode', true);

            },
            stopFullscreenFallback() {
                (document.body || document.getElementsByTagName('body')[0]).style.overflow = 'auto';
                this.$store.commit('timeline/toggle_fullscreen_fallback_mode', false);
            },
            onRunFullScreen() {
                this.runFullscreenFallback();
                // if (this.requestFullscreenAvailable) {
                //     this.requestFullscreenFunction.call(this.timelineContainer);
                // } else {
                //     alert('Not supported by your browser');
                // }

            },
            onStopFullscreen() {
                this.stopFullscreenFallback();
            },
            fullscreenChangedHandler() {
                const fullScreenEnabled = (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement != null);
                this.$store.commit('timeline/toggle_fullscreen_mode', fullScreenEnabled);
            },
            stepInFullScreenModeClickedHandler(step) {
                this.opened = true;
                this.openedStep = step;
            },
            onTimelineOffsetYChanged(val) { // -1 or 1 usually. -1 - down, 1-up;
                const maxOffset = this.requiredTimelineContentHeight - this.windowHeight;
                const minOffset = 0;
                let newOffset = this.timelineOffsetY - val;
                if (newOffset < minOffset) {
                    newOffset = minOffset;
                } else if (newOffset > maxOffset) {
                    newOffset = maxOffset;
                }
                this.timelineOffsetY = newOffset;
            }
        },
        watch: {
            timelineOffsetY(n, o) {
                this.$refs['timeline-container__inner'].scrollTop = n;
            }
        },
        mounted() {
            goalHeaderBus.$on(GOAL_HEADER_BUS_ACTIONS.RUN_FULLSCREEN_ON_TIMELINE, this.onRunFullScreen);
            this.timelineContainer = document.querySelector('#timeline-container');

            if (document.addEventListener) {
                document.addEventListener('webkitfullscreenchange', this.fullscreenChangedHandler, false);
                document.addEventListener('mozfullscreenchange', this.fullscreenChangedHandler, false);
                document.addEventListener('fullscreenchange', this.fullscreenChangedHandler, false);
                document.addEventListener('MSFullscreenChange', this.fullscreenChangedHandler, false);
            }
            fullScreenBus.$on(FULL_SCREEN_BUS_EVENTS.STEP_CLICKED_IN_FULLSCREEN_MODE, this.stepInFullScreenModeClickedHandler);
        },
        beforeDestroy() {
            fullScreenBus.$off(FULL_SCREEN_BUS_EVENTS.STEP_CLICKED_IN_FULLSCREEN_MODE, this.stepInFullScreenModeClickedHandler);
            document.removeEventListener('webkitfullscreenchange', this.fullscreenChangedHandler);
            document.removeEventListener('mozfullscreenchange', this.fullscreenChangedHandler);
            document.removeEventListener('fullscreenchange', this.fullscreenChangedHandler);
            document.removeEventListener('MSFullscreenChange', this.fullscreenChangedHandler);
        }
    };
</script>

<style scoped lang="scss">
    .timeline-container {
        width: 100%;
        position: relative;
    }

    #timeline-cancel-fullscreen-panel {
        display: inline-block;
        width: 50px;
        line-height: 65px;
        height: 50px;
        cursor: pointer;
        text-align: center;
        background-color: rgba(0, 0, 20, 0.7);
        z-index: 1024;
        position: fixed;
        right: 15px;
        top: 15px;
        border-radius: 5px;

        &__collapse-icon {
            color: white;
            font-size: 35px;
        }
    }
</style>
