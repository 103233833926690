import { DjangoUrls } from 'DjangoUrls';
import axios from 'axios';

const PREFIX = 'api.v1:goal_reports-';

const GoalReportsResource = {
    get_reports(wikiId, params) {
        const url = DjangoUrls[`${PREFIX}get-reports`](wikiId);
        return axios({
            method: 'get',
            url,
            params: {
                ...params
            },
        });
    },
    update_reports_status(wikiId, reportsIdList) {
        const url = DjangoUrls[`${PREFIX}update-reports-status`](wikiId);
        return axios({
            method: 'post',
            url,
            data: {
                id_list: reportsIdList,
            },
        });
    },

    create_report(wikiId, data) {
        const url = DjangoUrls[`${PREFIX}create-report`](wikiId);
        return axios({
            method: 'post',
            url,
            data
        });
    },

    delete_report(wikiId, reportId) {
        const url = DjangoUrls[`${PREFIX}delete-report`](wikiId);
        return axios({
            method: 'delete',
            url,
            data:{
                id: reportId,
            }
        });
    },
};

export default GoalReportsResource;
