var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("strong", [
      _c("a", { attrs: { href: _vm.firstEventLink } }, [
        _vm._v(_vm._s(_vm.event.event.name)),
      ]),
    ]),
    _vm._v(" "),
    _vm.event.is_periodic && _vm.event.occurrences.length > 0
      ? _c("span", [
          _vm.event.occurrences.length === 1
            ? _c("span", [
                _vm._v("(" + _vm._s(_vm.occurrences[0].start_date) + ")"),
              ])
            : _c(
                "span",
                [
                  _vm._v("("),
                  _vm._l(_vm.occurrences, function (occurrence, i) {
                    return _c("span", [
                      _c("a", { attrs: { href: occurrence.link } }, [
                        _vm._v(_vm._s(occurrence.start_date)),
                      ]),
                      i < _vm.event.occurrences.length - 1
                        ? _c("span", [_vm._v(", ")])
                        : _vm._e(),
                    ])
                  }),
                  _vm.hiddenOccurrencesCount
                    ? _c(
                        "a",
                        {
                          staticClass: "pointer",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.revealHiddenOccurrences.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.hiddenOccurrencesCount) + " more..."
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(")"),
                ],
                2
              ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }