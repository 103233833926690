<template>
    <div class="task-tags-list-filter" v-show="visibleTags.length">
        <div class="task-tags-list-filter__title">Tags in this {{ "Goal"|customTerm }}:</div>
        <div @click="toggleTagFilter(tag)" class="task-tags-list-filter__tag" v-for="tag in visibleTags"
             :class="{active: tag.active}">{{tag.name}}
        </div>
        <div @click="showAll" class="task-tags-list-filter__show-all" v-show="isShowAllButtonVisible">show all...</div>
    </div>
</template>

<script>
    const AMOUNT_OF_TAGS_VISIBLE_BY_DEFAULT = 12;
    import Vue from 'vue';

    export default {
        name: "GoalTagsFilterPanel",
        data() {
            return {
                showAllPressed: false
            };
        },
        computed: {
            tagsInProject() {
                return this.$store.getters['goalDetailPageStore/tagsInProject'];
            },
            tags() {
                return this.$store.getters['goalDetailPageStore/tagsInProject'].map(t => ({
                    name: t,
                    active: this.$store.state.goalDetailPageStore.tagsInFilter.indexOf(t) !== -1
                }));
            },
            visibleTags() {
                if (this.showAllPressed || this.tags.length < AMOUNT_OF_TAGS_VISIBLE_BY_DEFAULT) {
                    return this.tags;
                }
                else {
                    return this.tags.slice(0, AMOUNT_OF_TAGS_VISIBLE_BY_DEFAULT);
                }
            },
            isShowAllButtonVisible() {
                return !this.showAllPressed && this.tags.length > AMOUNT_OF_TAGS_VISIBLE_BY_DEFAULT;
            }
        },
        methods: {
            toggleTagFilter(tag) {
                this.$store.commit('goalDetailPageStore/toggle_filter_by_tag', tag.name);
            },
            showAll() {
                this.showAllPressed = true;
            },
            checkForHiddenActiveTags() {
                if (!this.showAllPressed) {
                    for (let activeTag of this.$store.state.goalDetailPageStore.tagsInFilter) {
                        if (this.$store.getters['goalDetailPageStore/tagsInProject'].indexOf(activeTag) >= AMOUNT_OF_TAGS_VISIBLE_BY_DEFAULT) {
                            this.showAllPressed = true;
                            return;
                        }
                    }
                }
            }
        },
        watch: {
            tagsInProject() {
                this.checkForHiddenActiveTags();
            }
        },
        mounted() {
            this.checkForHiddenActiveTags();
        }
    };
</script>

<style scoped lang="scss">
    @import "../styles/const";
    @import "../styles/mixins";

    .task-tags-list-filter {
        margin-bottom: 10px;
        &__tag {
            @include tag;
            cursor: pointer;
        }
        &__title {
            display: inline-block;
            font-weight: bolder;
            font-size: 18px;
            vertical-align: top;
            line-height: 28px;
        }
        &__show-all {
            display: inline-block;
            font-size: 16px;
            cursor: pointer;
            vertical-align: top;
            line-height: 28px;
        }
    }
</style>