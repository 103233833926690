<template>
    <span>
        <strong>
            <a :href="firstEventLink">{{event.event.name}}</a>
        </strong>
            <span v-if="event.is_periodic&&event.occurrences.length>0">
                <span v-if="event.occurrences.length===1">({{occurrences[0].start_date}})</span>
                <span v-else>(<span v-for="(occurrence,i) in occurrences"><a :href="occurrence.link">{{occurrence.start_date}}</a><span
                        v-if="i<(event.occurrences.length-1)">, </span></span><a href="javascript:;" v-if="hiddenOccurrencesCount"
                                                                                    class="pointer"
                                                                                    @click.prevent="revealHiddenOccurrences">{{hiddenOccurrencesCount}} more...</a>)</span>
            </span>
    </span>

</template>

<script>
    export default {
        props: {
            event: Object
        },
        data() {
            return {
                hiddenOccurrencesRevealed: false
            };
        },
        computed: {
            firstEventLink() {
                if (this.event.is_periodic && this.event.occurrences.length === 1) {
                    return this.event.occurrences[0].link;
                } else {
                    return this.event.event.link;
                }
            },
            occurrences() {
                if (this.hiddenOccurrencesRevealed) {
                    return this.event.occurrences;
                } else {
                    return this.event.occurrences.slice(0, 3);
                }
            },
            hiddenOccurrencesCount() {
                if (this.hiddenOccurrencesRevealed) {
                    return 0;
                }
                if (this.event.occurrences <= 3) {
                    return 0;
                }
                return this.event.occurrences.length - 3;
            }
        },
        methods: {
            revealHiddenOccurrences() {
                this.hiddenOccurrencesRevealed = true;
            },
        },
        name: "TaskMainInfoLinkedEvent"
    };
</script>

<style scoped>

</style>