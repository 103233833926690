var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tasks-list-page-task", style: _vm.taskBackgroundColor },
    [
      _c("div", {}, [
        _c(
          "div",
          { staticClass: "tasks-list-page-task__avatar-part" },
          [
            _vm.routerNavigation
              ? _c(
                  "router-link",
                  { attrs: { to: _vm._f("backHrefQuery")(_vm.task.link) } },
                  [
                    _c("div", {
                      staticClass: "tasks-list-page-task__avatar-part__avatar",
                      style: _vm.avatarStyle,
                    }),
                  ]
                )
              : _c(
                  "a",
                  { attrs: { href: _vm._f("backHref")(_vm.task.link) } },
                  [
                    _c("div", {
                      staticClass: "tasks-list-page-task__avatar-part__avatar",
                      style: _vm.avatarStyle,
                    }),
                  ]
                ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tasks-list-page-task__content-part" }, [
          _c(
            "div",
            { staticClass: "tasks-list-page-task__content-part__title" },
            [
              _vm.routerNavigation
                ? _c(
                    "router-link",
                    {
                      staticStyle: { color: "#333", "text-decoration": "none" },
                      attrs: { to: _vm._f("backHrefQuery")(_vm.task.link) },
                    },
                    [_vm._v(_vm._s(_vm._f("cutName")(_vm.task.name, 110)))]
                  )
                : _c(
                    "a",
                    {
                      staticStyle: { color: "#333", "text-decoration": "none" },
                      attrs: { href: _vm._f("backHref")(_vm.task.link) },
                    },
                    [_vm._v(_vm._s(_vm._f("cutName")(_vm.task.name, 110)))]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tasks-list-page-task__content-part__description" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("cutName")(_vm.task.description, 110)) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "margin-5-top tasks-list-page-task__content-part__goal-data",
            },
            [
              _vm.routerNavigation
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: _vm._f("backHrefQuery")(_vm.task.project.link),
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img-circle vertical-middle",
                        attrs: {
                          src: _vm.task.project.avatar,
                          width: "40",
                          height: "40",
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _c(
                    "a",
                    {
                      attrs: {
                        href: _vm._f("backHrefQuery")(_vm.task.project.link),
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img-circle vertical-middle",
                        attrs: {
                          src: _vm.task.project.avatar,
                          width: "40",
                          height: "40",
                          alt: "",
                        },
                      }),
                    ]
                  ),
              _vm._v(" "),
              _vm.routerNavigation
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: _vm._f("backHrefQuery")(_vm.task.project.link),
                      },
                    },
                    [
                      _c("span", { staticClass: "vertical-middle" }, [
                        _vm._v(
                          _vm._s(_vm._f("cutName")(_vm.task.project.name, 100))
                        ),
                      ]),
                    ]
                  )
                : _c(
                    "a",
                    {
                      attrs: {
                        href: _vm._f("backHref")(_vm.task.project.link),
                      },
                    },
                    [
                      _c("span", { staticClass: "vertical-middle" }, [
                        _vm._v(
                          _vm._s(_vm._f("cutName")(_vm.task.project.name, 100))
                        ),
                      ]),
                    ]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "margin-5-top tasks-list-page-task__content-part__assignee-data inline-block",
            },
            [
              _c("img", {
                staticClass: "img-circle vertical-middle",
                attrs: {
                  src: _vm.assigneeAvatar,
                  width: "40",
                  height: "40",
                  alt: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "goal-task-component-desktop__content__assignee-and-time__time inline-block",
            },
            [_c("span", [_vm._v(_vm._s(_vm.timeLabel))])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "goal-task-component-desktop__content__assignee-and-time__comments-counter-container inline-block vertical-middle",
              class: { "add-margin": _vm.task.attachments_count <= 0 },
            },
            [
              _vm.task.comments_count
                ? _c(
                    "span",
                    {
                      staticClass: "fa-stack",
                      staticStyle: {
                        "vertical-align": "top",
                        "font-size": "17px",
                      },
                    },
                    [
                      _c("i", { staticClass: "fal fa-comment fa-stack-2x" }),
                      _vm._v(" "),
                      _vm.routerNavigation
                        ? _c(
                            "router-link",
                            {
                              staticClass: "fa-stack-1x",
                              staticStyle: {
                                color: "#333",
                                "text-decoration": "none",
                              },
                              attrs: {
                                to: _vm._f("backHrefQuery")(_vm.task.link),
                              },
                            },
                            [_vm._v(_vm._s(_vm.task.comments_count))]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "fa-stack-1x",
                              staticStyle: {
                                color: "#333",
                                "text-decoration": "none",
                              },
                              attrs: {
                                href: _vm._f("backHrefQuery")(_vm.task.link),
                              },
                            },
                            [_vm._v(_vm._s(_vm.task.comments_count))]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.task.tags.length,
              expression: "task.tags.length",
            },
          ],
          staticClass: "margin-5-top",
        },
        [_c("task-tags-list", { attrs: { tags: _vm.task.tags } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }