var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroller__container", style: [_vm.containerWidthStyle] },
    [
      _c("div", {
        staticClass: "scroller__grip",
        style: _vm.gripStyle,
        on: {
          mousedown: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.gripMouseDown.apply(null, arguments)
          },
          touchstart: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.gripMouseDown.apply(null, arguments)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }