import { render, staticRenderFns } from "./CreateGoalCategoryPopup.vue?vue&type=template&id=3c967c7c&scoped=true&"
import script from "./CreateGoalCategoryPopup.vue?vue&type=script&lang=js&"
export * from "./CreateGoalCategoryPopup.vue?vue&type=script&lang=js&"
import style0 from "./CreateGoalCategoryPopup.vue?vue&type=style&index=0&id=3c967c7c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c967c7c",
  null
  
)

export default component.exports