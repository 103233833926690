<template>
    <basic-modal
            @hide="close"
            v-model="opened"
            :aria-label="header"
            role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="create-category-popup__avatar-container"
                     :style="avatarStyle"
                     @click="setAvatar"
                     @keydown.enter.stop.prevent="setAvatar"
                     tabindex="0"
                     role="button"
                     aria-label="Set avatar"
                >
                    <div class="create-category-popup__reset-avatar-button"
                         v-if="!avatarReset&&(avatarImage||avatarBytes)"
                         tabindex="0"
                         role="button"
                         aria-label="Reset avatar"
                         @click.stop.prevent="resetAvatar"
                         @keydown.enter.stop.prevent="resetAvatar"
                    >
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                </div>
                <small>Click to update picture</small>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <strong>Category Name:</strong>
                    <input type="text" v-model="name" placeholder="Give your Category a name" class="form-control"
                           maxlength="63"
                           ref="categoryName"/>
                </div>
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-4">
                <media-preview
                        :item="audioDescription"
                        v-if="audioDescription"
                        @onDelete="resetAudio"
                        no-border
                        allow-delete
                        show-name
                ></media-preview>
                <button
                        v-else
                        class="btn btn-mm btn-blue text-left"
                        tabindex="0"
                        style="width: 100%"
                        @click="addAudio">
                    <span>
                        <span class="vertical-middle"
                              style="text-align: center;
                            display: inline-block;"
                        >
                            <i class="far fa-volume-up  create-category-popup__media-button-icon"></i>
                        </span>
                        <span class="create-category-popup__media-button-label"> + Add Audio</span>
                    </span>
                </button>
            </div>
            <div class="col-sm-8" v-if="!audioDescription">
                Add an audio description
            </div>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-4">
                <media-preview
                        :item="videoDescription"
                        v-if="videoDescription"
                        no-border
                        allow-delete
                        show-name
                        @onDelete="resetVideo"
                ></media-preview>
                <button
                        v-else
                        class="btn btn-mm btn-blue text-left"
                        tabindex="0"
                        style="width: 100%"
                        @click="addVideo">
                    <span>
                        <span
                                class="vertical-middle"
                                style="text-align: center;
                                display: inline-block;"
                        >
                            <i class="fa fa-video-camera create-category-popup__media-button-icon"></i>
                        </span>
                        <span class="create-category-popup__media-button-label"> + Add Video</span>
                    </span>
                </button>
            </div>
            <div class="col-sm-8" v-if="!videoDescription">
                Add an introduction video
            </div>
        </div>
        <div slot="footer" class="row">
            <div class="col-xs-4 text-left">
                <button class="btn btn-mm btn-red" v-if="editMode" @click="del">Delete</button>
            </div>
            <div class="col-xs-8 text-right">
                <button class="btn btn-mm btn-default" @click="close">Cancel</button>
                <button class="btn btn-mm btn-green" :disabled="!allowSave" @click="ok">{{ saveButtonText }}</button>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import BasicModal from "../../../vue/common_components/BasicModal";
    import PopupPromiseMixin from "../../../vue/mixins/PopupPromiseMixin";
    import Vue from "vue";
    import {DEFAULT_AVATARS, STATIC_URL} from "shared/constants";

    export default {
        name: "CreateGoalCategoryPopup",
        mixins: [PopupPromiseMixin],
        components: {BasicModal},
        data() {
            return {
                name: '',

                avatarImage: '',
                avatarBytes: '',
                avatarReset: false,

                audioDescription: undefined,
                videoDescription: undefined,

                editMode: false,
            };
        },
        computed: {
            firstFocusEl() {
                return this.$refs.categoryName;
            },
            allowSave() {
                return this.name.length > 2;
            },
            saveButtonText() {
                return this.editMode ? 'Save' : 'Create';
            },
            header() {
                return this.editMode ? `Edit Category/Vision` : `New Category/Vision`;
            },
            avatarStyle() {
                const style = {};
                style['background-image'] = `url(${DEFAULT_AVATARS.GOAL})`;
                if (this.avatarImage && !this.avatarReset) {
                    style['background-image'] = `url(${this.avatarImage})`;
                }
                if (this.avatarBytes && !this.avatarReset) {
                    style['background-image'] = `url(data:image/jpeg;base64,${this.avatarBytes})`;
                }
                return style;
            },
        },
        methods: {
            setInitial(category = undefined) {
                if (category) {
                    this.name = category.name;
                    if (category.image && (category.image.indexOf(STATIC_URL) === -1)) {
                        this.avatarImage = category.image;
                    }
                    this.audioDescription = category.audio_description;
                    this.videoDescription = category.video_description;
                    this.editMode = true;
                }
            },
            async setAvatar() {
                this.tmpHide();
                try {
                    const addMedia = Vue.getAngularModule('addMedia');
                    this.avatarBytes = await addMedia.addAvatarByCrop();
                    this.avatarReset = false;
                } catch (e) {
                    console.log(e);
                    this.tmpShow();
                }
                this.tmpShow();
            },
            async addAudio() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.tmpHide();
                const default_callback = ([mc]) => {
                    this.audioDescription = mc;
                    this.tmpShow();
                };
                const cancel_callback = () => {
                    this.tmpShow();
                };
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        type: 'audio',
                    },
                    {
                        default_callback,
                        cancel_callback
                    }
                );
            },
            async addVideo() {
                const addMedia = Vue.getAngularModule('addMedia');
                this.tmpHide();
                const default_callback = ([mc]) => {
                    this.videoDescription = mc;
                    this.tmpShow();
                };
                const cancel_callback = () => {
                    this.tmpShow();
                };
                addMedia.add_media(
                    {
                        allow_add_from_library: true,
                        max_count: 1,
                        type: 'video',
                    },
                    {
                        default_callback,
                        cancel_callback
                    }
                );
            },
            resetAvatar() {
                this.avatarBytes = '';
                this.avatarImage = '';
                this.avatarReset = true;
            },
            resetVideo() {
                this.videoDescription = undefined;
            },
            resetAudio() {
                this.audioDescription = undefined;
            },
            ok() {
                const res = {
                    name: this.name,
                };
                if (this.avatarBytes) {
                    res.image = this.avatarBytes;
                }
                if (this.avatarReset) {
                    res.image = null;
                }
                if (this.audioDescription) {
                    res.audio_description = this.audioDescription.id;
                }
                if (this.videoDescription) {
                    res.video_description = this.videoDescription.id;
                }
                this.save(res);
            },
            del() {
                this.save('delete');
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "styles/const.scss";

  .create-category-popup {
    &__avatar-container {
      position: relative;
      width: 70px;
      height: 70px;
      border-radius: 5px;
      margin: auto;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }

    &__reset-avatar-button {
      cursor: pointer;
      position: absolute;
      right: -9px;
      top: -9px;
      color: $red;
      font-size: 30px;
      z-index: 6;
    }

    &__media-button-label {
      vertical-align: top;
      line-height: 26px;
    }

    &__media-button-icon {
      line-height: 26px;
      font-size: 27px;
    }
  }

</style>