var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "step",
      style: [
        _vm.stepSize,
        _vm.stepAppearanceStyle,
        _vm.draggingStyle,
        _vm.resizingStyle,
        _vm.stepBackgroundColor,
      ],
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
        },
        touchend: _vm.onTouchEnd,
      },
    },
    [
      _c("div", { staticClass: "inner", style: _vm.innerBlockStyle }, [
        _c("span", {
          staticClass: "align-helper",
          staticStyle: { width: "1px" },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.avatarTooltip,
                expression: "avatarTooltip",
              },
            ],
            staticClass: "inline-block",
          },
          [
            _c("img", {
              staticClass: "img-circle",
              style: _vm.avatarStyle,
              attrs: { src: _vm.task.avatar },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "inline-block",
            staticStyle: { "vertical-align": "top" },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.taskNameTooltip,
                    expression: "taskNameTooltip",
                  },
                ],
              },
              [
                _c(
                  "a",
                  {
                    ref: "taskname",
                    style: _vm.headerStyle,
                    attrs: { href: _vm._f("backHrefQuery")(_vm.task.link) },
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                      },
                      touchstart: function ($event) {
                        $event.stopPropagation()
                      },
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.clickOnHeader.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm._f("cutName")(_vm.task.name, 25)))]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.task.comments_count
              ? _c(
                  "span",
                  {
                    staticClass: "fa-stack",
                    staticStyle: {
                      "vertical-align": "top",
                      "font-size": "17px",
                    },
                  },
                  [
                    _c("i", { staticClass: "fal fa-comment fa-stack-2x" }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fa-stack-1x",
                        staticStyle: {
                          color: "#333",
                          "text-decoration": "none",
                        },
                        attrs: { href: _vm._f("backHrefQuery")(_vm.task.link) },
                      },
                      [_vm._v(_vm._s(_vm.task.comments_count))]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.isEditable
          ? _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editMode,
                  expression: "editMode",
                },
              ],
              staticClass: "fa fa-arrows-alt dragdrop-grip",
              staticStyle: { "font-size": "22px" },
              style: _vm.resizeGripStyle,
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripClicked.apply(null, arguments)
                },
                touchstart: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.dragGripTouched.apply(null, arguments)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.insideTimeline && _vm.isEditable
          ? _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editMode,
                  expression: "editMode",
                },
              ],
              staticClass: "resize-grip",
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.resizeGripClicked.apply(null, arguments)
                },
                touchstart: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.resizeGripTouched.apply(null, arguments)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
              },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }