var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("vue-simple-header", [
        _c("span", { attrs: { slot: "headerTitle" }, slot: "headerTitle" }, [
          _vm._v(_vm._s(_vm._f("customTerm")("Goals")) + " list"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "panelRight" }, slot: "panelRight" },
          [
            _c("vue-search-input", {
              attrs: { "min-letters-count": 2 },
              on: { search: _vm.onSearch, cancel: _vm.onSearchCancel },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("my-stuff-list-my-item", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.query,
            expression: "!query",
          },
        ],
        attrs: {
          "router-navigation": true,
          name: _vm.myGoalsName,
          "image-url": _vm.icon,
          link: _vm.myGoalsHref,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.results.length,
              expression: "results.length",
            },
          ],
          staticClass: "row",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-sm-12 list_header",
              staticStyle: { "font-size": "21px", "font-weight": "bolder" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("customTerm")("Goals")) +
                  " I manage (" +
                  _vm._s(_vm.totalCount) +
                  ")\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          _vm._l(_vm.results, function (user) {
            return _c("users-whose-goals-i-manage-page-user", {
              key: user.id,
              attrs: { user: user },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.allLoaded && _vm.results.length === 0,
              expression: "allLoaded&&results.length===0",
            },
          ],
          staticClass: "row text-center",
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadMore,
            expression: "loadMore",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("span", { staticStyle: { "font-size": "20px" } }, [
        _vm._v("No results..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }