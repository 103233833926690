var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, "append-to-body": true },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "goal-task-steps-popup" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("label", { attrs: { for: "step-name" } }, [
              _vm._v("Sub-step Name:"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "step-name",
                type: "text",
                maxlength: "255",
                placeholder: "Sub-step name",
              },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "hr10" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("label", { attrs: { for: "step-description" } }, [
              _vm._v("Sub-step Description:"),
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description",
                },
              ],
              staticClass: "form-control",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                id: "step-description",
                name: "",
                rows: "5",
                placeholder: "Step description",
                maxlength: "3000",
              },
              domProps: { value: _vm.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.description = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-4 text-left" }, [
            _vm.deleteButtonAllowed
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-red",
                    on: { click: _vm.deleteStep },
                  },
                  [_vm._v(_vm._s(_vm.deleteButton) + "\n                ")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-8" }, [
            _c(
              "button",
              { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
              [_vm._v(_vm._s(_vm.cancelButton))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green save-button",
                attrs: { disabled: !_vm.allowSave },
                on: { click: _vm.save },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.saveButton) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }