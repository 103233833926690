<template>
    <router-view></router-view>
</template>

<script>
    import FAVICONS from "shared/favicons";

    export default {
        name: "GoalsMainRouter",
        metaInfo() {
            return {
                title: this.$customTerm('Goals'),
                titleTemplate: `%s - Multi Me`,
                link: [
                    {rel: 'icon', href: FAVICONS.goals, type: 'image/x-icon', vmid: 'favicon',}
                ],
            };
        }

    };
</script>

<style scoped>

</style>