/**
 * Created by orion on 09.05.17.
 */
import {DjangoUrls} from 'DjangoUrls';
import axios from "axios";

let _ = require('lodash');
TasksListResource.$inject = ['$http'];
const PREFIX = 'api.v1:tasks_list-';

function TasksListResource($http) {
    return {
        get_my_tasks: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-my-tasks`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },
        get_users_tasks_as_buddy: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-users-tasks-as-buddy`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },
        get_users_tasks_as_viewer: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-users-tasks-as-viewer`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },

        get_data_for_filter: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-data-for-filter`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },
        get_data_for_filter_as_buddy: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-data-for-filter-as-buddy`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },
        get_data_for_filter_as_viewer: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-data-for-filter-as-viewer`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },

        get_popular_tags: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-popular-tags`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },
        get_popular_tags_as_buddy: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-popular-tags-as-buddy`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },
        get_popular_tags_as_viewer: function (userId, params = {}) {
            const url = DjangoUrls[`${PREFIX}get-popular-tags-as-viewer`](userId);
            return axios({
                method: 'GET',
                url,
                params
            });
        },
    };
}

export default TasksListResource;