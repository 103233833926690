<template>
    <div class="tasks-list-page-task" :style="taskBackgroundColor">
        <div class="">
            <div class="tasks-list-page-task__avatar-part">
                <router-link :to="task.link|backHrefQuery" v-if="routerNavigation">
                    <div class="tasks-list-page-task__avatar-part__avatar" :style="avatarStyle"></div>
                </router-link>
                <a :href="task.link|backHref" v-else>
                    <div class="tasks-list-page-task__avatar-part__avatar" :style="avatarStyle"></div>
                </a>

            </div>
            <div class="tasks-list-page-task__content-part">
                <div class="tasks-list-page-task__content-part__title">
                    <router-link :to="task.link |backHrefQuery" style="color: #333;text-decoration: none;" v-if="routerNavigation">{{task.name
                        |cutName(110)}}</router-link>
                    <a :href="task.link |backHref" style="color: #333;text-decoration: none;" v-else>{{task.name
                        |cutName(110)}}</a>
                </div>
                <div class="tasks-list-page-task__content-part__description">
                    {{task.description |cutName(110)}}
                </div>
                <div class="margin-5-top tasks-list-page-task__content-part__goal-data">
                    <router-link :to="task.project.link | backHrefQuery" v-if="routerNavigation">
                        <img :src="task.project.avatar" width="40" height="40" class="img-circle vertical-middle"
                             alt=""/>
                    </router-link>
                    <a :href="task.project.link | backHrefQuery" v-else>
                        <img :src="task.project.avatar" width="40" height="40" class="img-circle vertical-middle"
                             alt=""/>
                    </a>
                    <router-link :to="task.project.link | backHrefQuery" v-if="routerNavigation">
                        <span class="vertical-middle">{{task.project.name |cutName(100)}}</span>
                    </router-link>
                    <a :href="task.project.link | backHref" v-else>
                        <span class="vertical-middle">{{task.project.name |cutName(100)}}</span>
                    </a>
                </div>
                <div class="margin-5-top tasks-list-page-task__content-part__assignee-data inline-block">
                    <img :src="assigneeAvatar" width="40" height="40" class="img-circle vertical-middle" alt=""/>
                </div>

                <div class="goal-task-component-desktop__content__assignee-and-time__time inline-block">
                    <span>{{timeLabel}}</span>
                </div>

                <div class="goal-task-component-desktop__content__assignee-and-time__comments-counter-container inline-block vertical-middle"
                     :class="{'add-margin':task.attachments_count<=0}">

                    <span class="fa-stack" style="vertical-align: top; font-size: 17px" v-if="task.comments_count">
                        <i class="fal fa-comment fa-stack-2x"></i>
                        <router-link  class="fa-stack-1x" style="color: #333; text-decoration: none"  :to="task.link |backHrefQuery" v-if="routerNavigation">{{task.comments_count}}</router-link>
                        <a class="fa-stack-1x" style="color: #333; text-decoration: none" :href="task.link |backHrefQuery" v-else>{{task.comments_count}}</a>
                    </span>
                </div>

            </div>
        </div>
        <div class="margin-5-top" v-show="task.tags.length">
            <task-tags-list :tags="task.tags"></task-tags-list>
        </div>
    </div>
</template>

<script>
    import TaskTagsList from '../GoalDetailPage/TaskTagsList.vue';
    import config from '../../goals/config';
    import moment from 'moment';
    import {getTaskBackgroundColor} from "../GoalDetailPage/common";
    import RouterNavigationMixin from "../../../../vue/mixins/RouterNavigationMixin";

    export default {
        name: "TasksListPageTask",
        components: {TaskTagsList},
        mixins: [RouterNavigationMixin],
        props: {
            task: Object,
            layout: {
                type: String
            }
        },
        data() {
            return {
                tasks: []
            };
        },
        computed: {
            isUnscheduled() {
                return (!this.task.start_date && !this.task.end_date);
            },
            timeLabel() {
                let format = this.layout === 'mobile' ? 'DD/MM/YY' : 'DD MMMM YY';
                // let format = 'DD/MM/YY';
                if (this.isUnscheduled) {
                    return 'Unscheduled';
                }
                return `${this.task.start_date ? moment(this.task.start_date, config.API_DATE_FORMAT).format(format) : '???'} - ${this.task.end_date ? moment(this.task.end_date, config.API_DATE_FORMAT).format(format) : '???'}`;
            },
            avatarStyle() {
                return {'background-image': `url('${this.task.avatar}')`};
            },
            taskBackgroundColor() {
                return {'background-color': getTaskBackgroundColor(this.task)};
            },
            assigneeAvatar() {
                if (this.task.assignee) {
                    return this.task.assignee.avatar;
                } else {
                    return config.EMPTY_ASSIGNEE_AVATAR;
                }
            },
            assigneeName() {
                let name;
                if (this.task.assignee) {
                    name = this.task.assignee.name;
                } else {
                    name = config.EMPTY_ASSIGNEE_NAME;
                }
                return name;
            }
        },
        methods: {},
        mounted() {
        }
    };
</script>

<style scoped lang="scss">
    @import "../../styles/const";
    @import "../../styles/mixins";

    .tasks-list-page-task {
        padding: 5px;
        @include wide_item_container(130px);

        &__avatar-part {
            vertical-align: top;
            @media (min-width: 400px) {
                width: 120px;
            }

            @media (max-width: 399px) {
                width: 85px;
            }

            display: inline-block;

            &__avatar {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                @media (min-width: 400px) {
                    width: 110px;
                    height: 110px;
                }

                @media (max-width: 399px) {
                    width: 80px;
                    height: 80px;
                }

                border-radius: 5px;
                margin: auto;
            }
        }

        &__content-part {
            vertical-align: top;
            display: inline-block;
            @media (min-width: 400px) {
                width: calc(100% - 130px);
            }
            @media (max-width: 399px) {
                width: calc(100% - 90px);
            }

            &__title {
                font-weight: bolder;
                font-size: 16px;
            }

            &__description {

            }

            &__assignee-data {
                a {
                    color: #333;
                    text-decoration: none
                }
            }

            &__goal-data {
                a {
                    color: #333;
                }
            }
        }
    }
</style>