/**
 * Created by orion on 09.05.17.
 */
import {DjangoUrls} from 'DjangoUrls';

GoalTaskDetailResource.$inject = ['$http'];

function GoalTaskDetailResource($http) {
    const PREFIX = 'api.v1:goal_task_detail-';
    return {
        get_task_data: function (taskId, accessCode = undefined) {
            const url = DjangoUrls[`${PREFIX}get-task-data`](taskId);
            return $http({
                method: 'GET',
                url,
                params: accessCode ? {'access_code': accessCode} : undefined
            });
        },
    };
}

export default GoalTaskDetailResource;