<template>
    <div class="join-request-component">
        <div v-if="!isRequestPending">
            {{ notJoinedFormattedText }} <br/><br/>
            <button class="btn btn-mm" @click="join()">Join {{ name }}</button>
        </div>
        <div v-else>
            {{ requestPendingFormattedText }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "JoinRequest",
        props: {
            name: {
                type: String,
                required: true
            },
            notJoinedText: {
                type: String,
                required: false
            },
            requestPendingMessage: {
                type: String,
                required: false
            },
            isRequestPending: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            notJoinedFormattedText() {
                if (this.notJoinedText) {
                    return this.notJoinedText;
                }
                if (this.name) {
                    return `You are not member of this ${this.name}.`;
                }
                return `You are not member`;
            },
            requestPendingFormattedText() {
                if (this.requestPendingMessage) {
                    return this.requestPendingMessage;
                } else {
                    return `You will join this ${this.name} after approval. We will notify you.`;
                }
            }
        },
        methods: {
            join() {
                this.$emit('join');
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../angular/app/styles/mixins";

  .join-request-component {
    @include wide_item_container(100px);
    margin-top: 15px;
    padding: 15px;
    font-size: 18px;
  }
</style>