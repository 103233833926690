var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    _vm._l(_vm.sortedCategories, function (category) {
      return _c("goals-list-category", {
        key: category.id,
        attrs: {
          category: category,
          "allow-create-goal": _vm.goalCreationAllowedInCategory(category),
          "allow-archive-goal": _vm.allowArchiveGoal,
          "router-navigation": _vm.routerNavigation,
        },
        on: {
          loadMore: function ($event) {
            return _vm.loadMore(category)
          },
          archiveGoal: _vm.archiveGoal,
          editCategory: _vm.editCategory,
          unArchiveGoal: _vm.unArchiveGoal,
          createGoalInCategory: _vm.createGoalInCategory,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }