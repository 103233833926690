<template>
    <div class="goals-i-manage-goal">
        <div class="goals-i-manage-goal__image-part">
            <a :href="link+'?from_list=1'">
                <img :src="user.avatar" class="img-circle goals-i-manage-goal__image-part__image"/></a>
        </div>
        <div class="goals-i-manage-goal__content-part">
            <a :href="link">
                <img :src="icon"
                     class="goals-i-manage-goal__content-part__icon" alt=""></a>
            <a :href="link">{{user.name}}'s {{ "Goals"|customTerm }}</a>
        </div>
    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import {DASHBOARD_ICONS, DASHBOARD_ITEMS} from "../../../dashboard/dashboard";

    export default {
        props: {
            user: {
                type: Object
            },
            goalsPageLink: {
                type: String,
                required: false
            }
        },
        computed: {
            icon() {
                return DASHBOARD_ICONS[DASHBOARD_ITEMS.GOALS];
            },
            link() {
                return this.goalsPageLink || DjangoUrls['user_detail'](this.user.name) + 'goals';
            }
        },
        name: "UsersWhoseGoalsIManagePageUser"
    };
</script>

<style scoped lang="scss">
    @import "../../../styles/mixins";

    .goals-i-manage-goal {
        @include managedUserInList();
    }
</style>