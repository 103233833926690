<template>
    <div class="goal-category">
        <div class="goal-category__header" v-if="category.name!=='Default'">
            <div class="goal-category__header-avatar" :style="categoryAvatar"></div>
            <div class="goal-category__header-audio-description" v-if="category.audio_description">
                <audio-toggle :audio-caption="category.audio_description" fa-class="fal"></audio-toggle>
            </div>
            <div class="goal-category__header-video-description"
                 v-if="category.video_description"
                 :style="videoDescriptionStyle"
                 role="button"
                 tabindex="0"
                 aria-label="Play Video Description"
                 @click="playVideoDescription(category.video_description)"
                 @keydown.enter.stop.prevent="playVideoDescription(category.video_description)"
            >
                <i class="fal fa-play color-blue pointer" aria-hidden="true"></i>
            </div>
            <div class="goal-category__header-context">
                <div class="goal-category__header-title">
                    <span>{{ category.name }}</span>
                    <i
                            v-if="category.is_editable"
                            tabindex="0"
                            role="button"
                            aria-label="Edit category"
                            @click="editCategory"
                            @keydown.enter.stop.prevent="editCategory"
                            class="fal fa-pencil pointer color-tag-gray-dark"
                    ></i>
                </div>
                <div
                        class="goal-category__header-create-goal-label"
                        v-if="allowCreateGoal">
                    <span
                            @click="createGoalInCategory"
                            @keydown.enter.prevent.stop="createGoalInCategory"
                            tabindex="0"
                            role="button"
                            class="pointer"
                    >
                        <i
                                class="fa fa-plus-circle"
                                aria-hidden="true"
                                v-tooltip="createNewGoalTooltip"
                        ></i>
<!--                        <span class="hidden-xs">Create new {{ "Goal"|customTerm }}</span>-->
                    </span>
                </div>
            </div>
        </div>
        <goals-list-item
                :goal="goal"
                v-for="goal in category.goals"
                :key="goal.id"
                :allow-archive-goal="allowArchiveGoal"
                :router-navigation="routerNavigation"
                @archiveGoal="archiveGoal"
                @unArchiveGoal="unArchiveGoal"
        ></goals-list-item>
        <div v-show="allowLoadMore" class="text-center pointer" @click="loadMore"><strong>Load More...</strong></div>
    </div>
</template>

<script>
    import GoalsListItem from "./GoalsListItem.vue";
    import RouterNavigationMixin from "../../../../vue/mixins/RouterNavigationMixin";
    import mediaContentUtils from "../../view_media/mediaContentUtils";
    import Vue from "vue";

    export default {
        components: {GoalsListItem},
        mixins: [RouterNavigationMixin],
        props: {
            category: Object,
            allowCreateGoal: {
                type: Boolean,
                default: false
            },
            allowArchiveGoal: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            categoryAvatar() {
                return {'background-image': `url('${this.category.image}')`};
            },
            allowLoadMore() {
                return this.category.count > this.category.goals.length;
            },
            videoDescriptionStyle() {
                if (this.category.video_description) {
                    return {
                        'background-image': `url(${new mediaContentUtils().get_img_url(this.category.video_description)})`
                    };
                }
            },
            createNewGoalTooltip(){
                return `Create new ${this.$customTerm('Goal')}`
            },
        },
        methods: {
            loadMore() {
                this.$emit('loadMore');
            },
            archiveGoal(goal) {
                this.$emit('archiveGoal', {goal, category: this.category});
            },
            unArchiveGoal(goal) {
                this.$emit('unArchiveGoal', {goal, category: this.category});
            },
            createGoalInCategory() {
                this.$emit('createGoalInCategory', this.category);
            },
            editCategory() {
                this.$emit('editCategory', this.category);
            },
            playVideoDescription(mediaItem) {
                const viewMediaFactory = Vue.getAngularModule('viewMediaFactory');
                viewMediaFactory.show_media([mediaItem], 0);
            },
        },
        name: "GoalsListCategory"
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";
  @import "../../styles/mixins";

  .goal-category {
    overflow: hidden;
    @include wide_item_container;

    &__header-avatar {
      vertical-align: top;
      background-size: cover;
      background-position: center;
      width: 70px;
      height: 70px;
      background-repeat: no-repeat;
      margin: 5px;
      border: solid 3px $black;
      border-radius: 5px;
      display: inline-block;
    }

    &__header-audio-description {
      width: 70px;
      height: 70px;
      min-width: 70px;
      min-height: 70px;
      font-size: 60px;
      line-height: 70px;
      text-align: center;
      margin: 5px;
    }

    &__header-video-description {
      width: 70px;
      height: 70px;
      min-width: 70px;
      min-height: 70px;
      font-size: 60px;
      line-height: 64px;
      text-align: center;
      margin: 5px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: solid 3px $blue;
      border-radius: 5px;
    }

    &__header-context {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      align-content: center;
    }

    &__header-title {
      vertical-align: top;
      display: inline-block;
      font-size: 26px;
      font-weight: bolder;
    }

    &__header-create-goal-label {
      text-align: right;
      position: absolute;
      top: 0;
      right: 3px;
      //flex-grow: 1;
      //font-size: 22px;
      font-size: 28px;
      @media (max-width: $screen-xs-max) {
        font-size: 28px;
      }
      color: $tag-gray;
    }

    &__header {
      position: relative;
      font-size: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: #e2e2e2;
      border-bottom: solid 1px $gray;
    }
  }
</style>