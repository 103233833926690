<template>
    <div class="scroller__container" :style="[containerWidthStyle]">
        <div class="scroller__grip" @mousedown.stop.prevent="gripMouseDown" @touchstart.stop.prevent="gripMouseDown"
             :style="gripStyle"></div>
    </div>
</template>

<script>/* eslint-disable indent */
import MouseEventsMixin from '../../../../../../vue/mixins/MouseEventsMixin.js';

export default {
    mixins: [MouseEventsMixin],
    props: {
        width: {
            type: Number
        }
    },
    data() {
        return {
            gripClicked: false,
            previousMousePositionX: 0,
            gripLeftOffset: this.width / 2 - 25,
            minGripLeftOffset: 0
        };
    },
    methods: {
        gripMouseDown(e) {
            this.gripClicked = true;
            this.previousMousePositionX = e.clientX;
        },
        onMouseUp() {
            this.gripClicked = false;
        },
        onPointerMove(coords) {
            if (!this.gripClicked) {
                return;
            }
            if (coords.x > this.previousMousePositionX && this.gripLeftOffset < this.maxGripLeftOffset) {
                const increaseOn = Math.min(this.maxGripLeftOffset - this.gripLeftOffset, coords.x - this.previousMousePositionX);
                this.gripLeftOffset += increaseOn;
                this.emitPosChanged(increaseOn);
                if (this.gripLeftOffset === this.maxGripLeftOffset) {
                    this.gripLeftOffset = this.width / 2 - 25;
                }
            }
            if (coords.x < this.previousMousePositionX && this.gripLeftOffset > this.minGripLeftOffset) {
                const decreaseOn = Math.min(this.gripLeftOffset, this.previousMousePositionX - coords.x);
                this.gripLeftOffset -= decreaseOn;
                this.emitPosChanged(-decreaseOn);
                if (this.gripLeftOffset === 0) {
                    this.gripLeftOffset = this.width / 2 - 25;
                }
            }
            this.previousMousePositionX = coords.x;
        },
        emitPosChanged(val) {
            this.$emit('onPosChanged', val / this.maxGripLeftOffset);
        }
    },
    computed: {
        maxGripLeftOffset() {
            return this.width - 50;
        },
        gripStyle() {
            let style = {};
            style['left'] = `${this.gripLeftOffset}px`;
            if (this.gripClicked) {
                style['background-color'] = '#b1b1b1';
            } else {
                style['background-color'] = '#c1c1c1';
            }
            return style;
        },
        containerWidthStyle() {
            return {width: `${this.width}px`};
        }
    },
    watch: {
        maxGripLeftOffset(n, o) {
            if (this.gripLeftOffset > n) {
                this.gripLeftOffset = this.width / 2 - 25;
            }
        }
    },
    mounted() {
    },
    beforeDestroy() {

    },
    name: 'Scroller'
};
</script>

<style scoped lang="scss">
    .scroller {
        &__container {
            position: absolute;
            bottom: 0;
            height: 25px;
            margin: auto;
            background-color: #e2e4e9;
        }
        &__grip {
            position: relative;
            height: 25px;
            width: 50px;
            cursor: pointer;
            border: solid 1px #8b8b8b;
            border-radius: 15px;
            /*box-shadow: 0 0 2px 2px #333;*/
        }
    }
</style>