var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "join-request-component" }, [
    !_vm.isRequestPending
      ? _c("div", [
          _vm._v("\n        " + _vm._s(_vm.notJoinedFormattedText) + " "),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-mm",
              on: {
                click: function ($event) {
                  return _vm.join()
                },
              },
            },
            [_vm._v("Join " + _vm._s(_vm.name))]
          ),
        ])
      : _c("div", [
          _vm._v(
            "\n        " + _vm._s(_vm.requestPendingFormattedText) + "\n    "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }