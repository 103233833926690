import moment from "moment";
import config from "../../../../goals/config";
import Vue from "vue";
import _ from "lodash";
import {customTermFilter} from "../../../../../../vue/filters/CustomTerm";

const API_DATE_FORMAT = config.API_DATE_FORMAT;

let toInt = d => parseInt(d.replace('-', '').replace('-', ''));

let getAssigneeId = task => {
    if (task.assignee) {
        return task.assignee.id;
    }
    return -1; // pseudo-member "Anybody" with id = -1
};

export default {
    namespaced: true,
    state() {
        return {
            rowHeight: 80,
            colWidth: 40,
            draggedTask: undefined,
            isTaskResizing: false,
            realCurrentDate: undefined,
            currentDate: undefined,
            scale: undefined, // 'weeks' || 'days'
            minLoadedDate: undefined,
            maxLoadedDate: undefined,
            showOtherMembers: false,

            fullScreenEnabled: false,
            fullScreenFallbackEnabled: false,
        };
    },
    mutations: {
        toggle_fullscreen_mode(state, val) {
            state.fullScreenEnabled = val;
        },
        toggle_fullscreen_fallback_mode(state, val) {
            state.fullScreenFallbackEnabled = val;
        },
        set_show_other_members(state, val) {
            state.showOtherMembers = val;
        },
        set_min_loaded_date(state, date) {
            state.minLoadedDate = moment(date);
        },
        set_max_loaded_date(state, date) {
            state.maxLoadedDate = moment(date);
        },
        set_task_resizing(store) {
            store.isTaskResizing = true;
        },
        reset_task_resizing(store) {
            store.isTaskResizing = false;
        },
        set_dragged_task(store, task) {
            store.draggedTask = task;
        },
        reset_dragged_task(store) {
            store.draggedTask = undefined;
        },
        update_task_data_if_changed(store, data) {
            let {task, assignee, start_date, end_date} = data;
            if (assignee && (!task.assignee || task.assignee.id !== assignee.id)) {
                task.assignee = assignee;
            }
            if (start_date && task.start_date !== start_date) {
                task.start_date = start_date;
            }
            if (end_date && task.end_date !== end_date) {
                task.end_date = end_date;
            }
        },
        set_timeline_scale(store, scale) { // scale ['Days', 'Months', 'Years']
            switch (scale) {
                case 'Days': {
                    store.colWidth = 70;
                    store.currentDate = store.realCurrentDate;
                    store.scale = 'days';
                    return;
                }
                case 'Weeks': {
                    store.colWidth = 40;
                    store.currentDate = store.realCurrentDate;
                    store.scale = 'days';
                    return;
                }
                case 'Months': {
                    store.colWidth = 40;
                    store.currentDate = moment(this.realCurrentDate).weekday(0).toDate();
                    store.scale = 'weeks';
                    return;
                }
            }
        },
        set_current_date(store, date) { // scale 'd' || 'm'
            store.realCurrentDate = date;
            store.currentDate = date;
        }
    },
    actions: {
        drag_n_drop_task({state, commit, getters}, data) {
            let {assignee, task, date} = data;
            let backup_assignee = task.assignee;
            let backup_start_date = task.start_date;
            let backup_end_date = task.end_date;

            let current_start_date;
            let current_end_date;
            let new_start_day = moment(date).format(API_DATE_FORMAT);
            let new_end_day;

            if (task.start_date && task.end_date) {
                current_start_date = moment(task.start_date, API_DATE_FORMAT);
                current_end_date = moment(task.end_date, API_DATE_FORMAT);
                let days_diff = Math.abs(current_end_date.diff(current_start_date, 'days'));
                if (new_start_day === current_start_date && task.assignee && task.assignee.id === assignee.id) {
                    return;
                }
                new_end_day = moment(date).add(days_diff, 'days').format(API_DATE_FORMAT);
            } else {
                const defaultDaysCount = getters.scale === 'weeks' ? 7 * 3 : 3;
                new_end_day = moment(date).add(defaultDaysCount, 'days').format(API_DATE_FORMAT);
            }

            commit('update_task_data_if_changed', {
                task,
                assignee,
                start_date: new_start_day,
                end_date: new_end_day
            });

            const assigneeCheckConfirmation = new Promise((resolve, reject) => {
                if (state.fullScreenEnabled || state.fullScreenFallbackEnabled) { // no popups
                    resolve();
                    return;
                }
                if (assignee.id !== -1 && (!backup_assignee || assignee.id !== backup_assignee.id)) {
                    Vue.getAngularModule('simplePopupFactory').show_popup(`Assign ${customTermFilter('Task')}`,
                        `Are you sure you want to assign this ${customTermFilter('Task')} to <strong>${assignee.name}</strong>?`,
                        'Yes', 'Cancel')
                        .then(() => {
                            resolve();
                        }, () => {
                            reject('cancel');
                        });
                } else {
                    resolve(); // assignee not changed
                }
            });

            assigneeCheckConfirmation.then(function () {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                Vue.getAngularModule('GoalTaskResource').update_date_and_assignee_timeline(task.id, {
                    assignee_id: assignee.id,
                    start_date: new_start_day,
                    end_date: new_end_day
                })
                    .then(resp => {
                        commit('goalDetailPageStore/update_task_from_server_data', resp.data, {root: true});
                        Vue.notifications.success('Updated', undefined, getters.notificationsContainer);
                    }, err => {
                        Vue.notifications.error(err || 'Error', undefined, getters.notificationsContainer);
                        commit('goalDetailPageStore/update_task_data_if_changed', {
                            task,
                            assignee: backup_assignee,
                            start_date: backup_start_date,
                            end_date: backup_end_date
                        }, {root: true});
                    })
                    .finally($rootScope.hide_dimmer);
            }, function () {
                commit('update_task_data_if_changed', {
                    task,
                    assignee: backup_assignee,
                    start_date: backup_start_date,
                    end_date: backup_end_date
                });
            })
                .finally(() => {
                    commit('reset_dragged_task');
                });
        },
        resize_task({state, commit, getters}, data) {
            let {task, days_count: cols_count} = data;
            let backup_end_date = task.end_date;
            let new_end_date;
            if (getters.scale === 'weeks') {
                new_end_date = moment(task.start_date, API_DATE_FORMAT).add(cols_count - 1, 'weeks').weekday(6);
            } else {
                new_end_date = moment(task.start_date, API_DATE_FORMAT).add(cols_count - 1, 'days');
            }
            commit('update_task_data_if_changed', {
                task,
                end_date: new_end_date.format(API_DATE_FORMAT)
            });
            Vue.getAngularModule('GoalTaskResource').change_task_days_count(task.id, {days_count: new_end_date.diff(moment(task.start_date, API_DATE_FORMAT), 'days') + 1})
                .then(resp => {
                    commit('goalDetailPageStore/update_task_from_server_data', resp.data, {root: true});
                    Vue.notifications.success('Updated1', undefined, getters.notificationsContainer);
                }, err => {
                    Vue.notifications.error(err || 'Error', undefined, getters.notificationsContainer);
                    commit('update_task_data_if_changed', {
                        task,
                        end_date: backup_end_date
                    });
                });

        }
    },
    getters: {
        scale(state, getters) {
            if (state.scale) {
                return state.scale;
            }
            if (getters.differenceBetweenLatestAndNewestTasks > 7 * 6) {
                return 'weeks';
            } else {
                return 'days';
            }
        },
        notificationsContainer(state) {
            if (state.fullScreenEnabled || state.fullScreenFallbackEnabled) {
                return '#timeline-container';
            } else {
                return 'body';
            }
        },
        currentDate(state, getters) {
            if (getters.scale === 'days') {
                return state.currentDate;
            }
            let day = moment(state.currentDate);
            return day.weekday(0).toDate();

        },
        membersForTimeline(state, getters, rootState) {
            const fake_anybody_member_to_concat = [{
                'name': config.EMPTY_ASSIGNEE_NAME,
                'id': config.EMPTY_ASSIGNEE_ID,
                'avatar': config.EMPTY_ASSIGNEE_AVATAR
            }];

            const memberIsVisible = (member) => {
                if (rootState.goalDetailPageStore.isAdmin) {
                    return true;
                }
                if (state.showOtherMembers) {
                    return true;
                }
                return member.id === rootState.goalDetailPageStore.currentUser.id;

            };

            if (!rootState.goalDetailPageStore.isAdmin && rootState.goalDetailPageStore.goal.hide_tasks) {
                return rootState.goalDetailPageStore.members.filter(m => m.id === rootState.goalDetailPageStore.currentUser.id);
            } else {
                return rootState.goalDetailPageStore.members.filter(m => memberIsVisible(m)).concat(fake_anybody_member_to_concat);
            }
        },
        showMembersVisibilitySwitch(state, getters, rootState) {
            if (rootState.goalDetailPageStore.isAdmin) {
                return false;
            }
            if (rootState.goalDetailPageStore.goal.hide_tasks) {
                return false;
            }
            if (state.showOtherMembers) {
                return false;
            }
            return getters.membersForTimeline.length < rootState.goalDetailPageStore.members.length;
        },
        taskOutsideTimeline(state, getters, rootState, rootGetters) {
            return rootGetters['goalDetailPageStore/flattenedTasks'].filter(t => !(t.start_date && t.end_date));
        },
        differenceBetweenLatestAndNewestTasks(state, getters, rootState, rootGetters) {
            const tasks_with_dates = rootGetters['goalDetailPageStore/flattenedTasks'].filter(t => (t.start_date && t.end_date));
            let newestTask, oldestTask;
            for (let task of tasks_with_dates) {
                if (!newestTask || newestTask.start_date < task.start_date) {
                    newestTask = task;
                }
                if (!oldestTask || oldestTask.start_date > task.start_date) {
                    oldestTask = task;
                }
            }
            if (!oldestTask || !newestTask) {
                return 0;
            }
            return moment(newestTask.start_date, API_DATE_FORMAT).diff(moment(oldestTask.start_date, API_DATE_FORMAT), 'days');
        },
        tasksTimeStorage(state, getters, rootState, rootGetters) {
            const storage = {};

            let firstUndefined = arr => {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === undefined) {
                        return i;
                    }
                }
                return arr.length;
            };

            const orderedAndFilteredTasks = _.orderBy(rootGetters['goalDetailPageStore/flattenedTasks'].filter(t => t.start_date && t.end_date), t => t.start_date);
            let current_task_index;
            for (let task of orderedAndFilteredTasks) {
                if (storage[getAssigneeId(task)] === undefined) {
                    storage[getAssigneeId(task)] = {};
                }
                let start_date = moment(task.start_date, API_DATE_FORMAT);
                if (getters.scale === 'weeks') {
                    start_date = start_date.weekday(0); // Jump to Monday
                }
                const daysAmountPerStep = getters.scale === 'weeks' ? 7 : 1;
                let end_date = moment(task.end_date, API_DATE_FORMAT);
                let i_date = start_date;
                while (end_date.diff(i_date, 'days') >= 0) {
                    let dateAsInt = toInt(i_date.format(API_DATE_FORMAT));
                    if (storage[getAssigneeId(task)][dateAsInt] === undefined) {
                        if (current_task_index === undefined) {
                            current_task_index = 0;
                        }
                        storage[getAssigneeId(task)][dateAsInt] = {'count': 1, 'tasks': []};
                        storage[getAssigneeId(task)][dateAsInt]['tasks'][current_task_index] = task.id;
                    } else {
                        storage[getAssigneeId(task)][dateAsInt]['count']++;
                        if (current_task_index === undefined) {
                            current_task_index = firstUndefined(storage[getAssigneeId(task)][dateAsInt]['tasks']);
                        }
                        storage[getAssigneeId(task)][dateAsInt]['tasks'][current_task_index] = task.id;
                    }
                    i_date.add(daysAmountPerStep, 'days');
                }
                current_task_index = undefined;
            }
            return storage;
        },
        rowCounts(state, getters) {
            const counterDict = {};
            getters.membersForTimeline.map(m => {
                counterDict[m.id] = 1;
            });
            _.forEach(getters.tasksTimeStorage, (val, assigneeId) => {
                let max = 1;
                _.forEach(getters.tasksTimeStorage[assigneeId], (val) => {
                    if (val['count'] > max) {
                        max = val['count'];
                    }
                });
                if (counterDict[assigneeId]) {
                    counterDict[assigneeId] = max;
                }
            });
            return counterDict;
        },
        totalRowCount(state, getters) {
            let count = 0;
            _.forEach(getters.rowCounts, v => {
                count += v;
            });
            return count;
        },
        tasksStartHashTable(state, getters, rootState, rootGetters) {
            if (!state.minLoadedDate || !state.maxLoadedDate) {
                return {};
            }
            const table = {};
            for (let task of rootGetters['goalDetailPageStore/flattenedTasks'].filter(t => t.start_date && t.end_date)) {

                let startDay = moment(task.start_date, API_DATE_FORMAT);
                let endDay = moment(task.end_date, API_DATE_FORMAT);

                if (getters.scale === 'weeks') {
                    startDay.weekday(0);
                    endDay.weekday(6);
                }

                let direction;
                let dateToAppendAsInt;
                const startDateLoaded = state.minLoadedDate.diff(startDay) <= 0;
                const endDateLoaded = state.maxLoadedDate.diff(endDay) >= 0;

                if (startDateLoaded) {
                    direction = 'toRight';
                    dateToAppendAsInt = parseInt(startDay.format(API_DATE_FORMAT).replace('-', '').replace('-', ''));

                } else {
                    if (endDateLoaded) {
                        direction = 'toLeft';
                        dateToAppendAsInt = parseInt(endDay.format(API_DATE_FORMAT).replace('-', '').replace('-', ''));
                    } else {

                        direction = 'toRight';
                        dateToAppendAsInt = parseInt(state.minLoadedDate.format(API_DATE_FORMAT).replace('-', '').replace('-', ''));
                    }
                }
                if (getters.tasksTimeStorage[getAssigneeId(task)][dateToAppendAsInt]) {
                    const index = getters.tasksTimeStorage[getAssigneeId(task)][dateToAppendAsInt]['tasks'].indexOf(task.id);
                    table[`${getAssigneeId(task)}_${dateToAppendAsInt}_${index}`] = {task, direction};
                }
            }
            return table;
        }
    }
};