var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.tags.length,
          expression: "tags.length",
        },
      ],
      staticClass: "task-tags-list-filter",
    },
    [
      _vm.title
        ? _c("div", { staticClass: "task-tags-list-filter__title" }, [
            _vm._v("Popular Tags:"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.tags, function (tag) {
        return _c(
          "div",
          {
            key: _vm.tagsRepresentation(tag) + tag.id,
            staticClass: "task-tags-list-filter__tag",
            class: { active: _vm.isTagActive(tag) },
            on: {
              click: function ($event) {
                return _vm.toggleTagFilter(tag)
              },
            },
          },
          [_vm._v(_vm._s(_vm.tagsRepresentation(tag)) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }