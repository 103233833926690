var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "goal_tasks_list__container" }, [
    _c("div", { staticStyle: { float: "right" } }, [
      _c(
        "button",
        {
          staticClass: "btn btn-mm btn-default",
          on: { click: _vm.createReport },
        },
        [
          _c("i", { staticClass: "fa fa-plus" }),
          _vm._v(" Create Export\n        "),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "h3",
      {
        staticStyle: {
          "font-size": "24px",
          "margin-bottom": "5px",
          "margin-top": "5px",
        },
      },
      [_vm._v(_vm._s(_vm._f("customTerm")("Goal")) + " Exports")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row margin-15-top" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "reports_table_outer_container" }, [
          _c("table", { staticClass: "table table-striped" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.reports, function (report) {
                return _c("tr", [
                  _c("td", [_vm._v("#" + _vm._s(report.id))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(report.name))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.getStatusRepresentation(report))),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(report.completed_time))]),
                  _vm._v(" "),
                  _c("td", [
                    report.link
                      ? _c("a", { attrs: { href: report.link } }, [
                          _vm._v("Download"),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("i", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Delete",
                          expression: "'Delete'",
                        },
                      ],
                      staticClass: "fas fa-trash pointer color-footer-red",
                      staticStyle: { "font-size": "18px" },
                      attrs: {
                        tabindex: "0",
                        role: "button",
                        "aria-label": "Delete Report",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteReport(report)
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.deleteReport(report)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.loading && _vm.reports.length === 0
          ? _c("div", { staticClass: "alert alert-info" }, [
              _vm._v("\n                Nothing to show\n            "),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.totalPage > 1
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12 text-right" },
            [
              _c("pagination", {
                attrs: { "total-page": _vm.totalPage },
                on: { change: _vm.changePage },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("#ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Created at")]),
        _vm._v(" "),
        _c("th", [_vm._v("Link")]),
        _vm._v(" "),
        _c("th", [_vm._v("Delete")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }