<template>
    <div class="vue-timeline-component__timeline" :style="[timelineHeight]" :class="timelineClasses">
        <div class="vue-timeline-component__scroll-box__container vue-timeline-component__noselect"
             :style="containerStyle" ref="container"
             @mousedown.stop.prevent="mousedownOnTimeline" @touchstart.stop.prevent="touchstartOnTimeline">
            <div class="vue-timeline-component__scroll-box__content" :style="contentStyle">
                <column :width="colWidth" :date="column" v-for="column in columns"
                        :key="column.toDateString()"></column>
            </div>
        </div>
    </div>
</template>

<script>
    import MouseEventsMixin from '../../../../../../vue/mixins/MouseEventsMixin.js';
    import Column from './ColumnFunc';
    import offsetBus from './offsetBus.js';

    export default {
        components: {Column},
        mixins: [MouseEventsMixin],
        name: "Timeline",
        props: {
            width: {
                type: Number
            },
            contentHeight: {
                type: Number,
            },
            watchYOffset: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                columns: [],
                offset: 0,
                contentClicked: false,
                previousMousePositionX: 0,
                previousMousePositionY: 0,
            };
        },
        beforeMount() {
            this.initTimeline();
        },
        computed: {
            'colWidth'() {return this.$store.state.timeline.colWidth},
            'draggedTask'() {return this.$store.state.timeline.draggedTask},
            'isTaskResizing'() {return this.$store.state.timeline.isTaskResizing},
            'rowHeight'() {return this.$store.state.timeline.rowHeight},
            scale() {
                return this.$store.getters['timeline/scale'];
            },
            currentDate() {
                return this.$store.getters['timeline/currentDate'];
            },
            containerWidth() {
                return this.width;
            },
            timelineClasses() {
                const classes = [];
                if (this.draggedTask) {
                    classes.push('dragging');
                }
                return classes;
            },
            containerStyle() {
                const style = {
                    width: `${this.containerWidth - 2}px`
                };
                if (this.isTaskResizing) {
                    style['cursor'] = 'col-resize';
                } else {
                    if (this.contentClicked || this.draggedTask) {
                        style['cursor'] = 'grabbing';
                    } else {
                        style['cursor'] = 'grab';
                    }
                }


                return style;
            },
            contentWidth() {
                return this.columns.length * this.colWidth;
            },
            contentStyle() {
                return {width: `${this.contentWidth}px`};
            },
            timelineHeight() {
                return {height: `${this.contentHeight}px`};
            },
            maxOffset() {
                return this.columns.length * this.colWidth - this.containerWidth;
            },
        },
        watch: {
            offset(n, o) {
                this.$refs.container.scrollLeft = n;
                if (this.offset > this.contentWidth - this.containerWidth - 50) {
                    this.extendToRight();
                }
                if (this.offset < 50) {
                    this.extendToLeft();
                    this.offset = (50 - this.offset) + this.colWidth * 30;
                }
                offsetBus.$emit('offsetChanged', this.offset);
            },
            colWidth(n, o) {
                this.offset = this.offset / o * n;
            },
            scale(n, o) {
                this.initTimeline();
            }

        },
        methods: {
            extendToLeft() {
                const multiplier = this.$store.getters['timeline/scale'] === 'weeks' ? 7 : 1;
                for (let i = 1; i < 35; i++) {
                    this.columns.unshift(new Date(this.columns[0].getTime() - (24 * 60 * 60 * 1000 * multiplier)));
                }
                this.$store.commit('timeline/set_min_loaded_date', this.columns[0]);
            },
            extendToRight() {
                const multiplier = this.$store.getters['timeline/scale'] === 'weeks' ? 7 : 1;
                for (let i = 1; i < 35; i++) {
                    this.columns.push(new Date(this.columns[this.columns.length - 1].getTime() + (24 * 60 * 60 * 1000 * multiplier)));
                }
                this.$store.commit('timeline/set_max_loaded_date', this.columns[this.columns.length - 1]);
            },
            initTimeline() {
                this.columns = [];
                this.columns.push(this.currentDate);
                this.extendToLeft();
                this.extendToRight();
                this.offset = (this.columns.length * this.$store.state.timeline.colWidth - this.containerWidth) / 2;
            },
            scrollPosChanged(val) {
                this.offset += val * this.containerWidth * 2;
                // this.$refs.container.scrollLeft = this.$refs.container.scrollLeft + val * 400
            },
            mousedownOnTimeline(e) {
                this.contentClicked = true;
                this.previousMousePositionX = e.clientX;
                this.previousMousePositionY = e.clientY;
            },
            touchstartOnTimeline(e) {
                if (e.targetTouches.length !== 1) {
                    return;
                }
                this.contentClicked = true;
                this.previousMousePositionX = e.targetTouches[0].clientX;
                this.previousMousePositionY = e.targetTouches[0].clientY;
            },
            onMouseUp() {
                this.contentClicked = false;
                this.stopOffsetMoveIfStarted();
            },
            processContentDragging(coords) {
                let coordsDifference = coords.x - this.previousMousePositionX;
                let coordsDifferenceY = coords.y - this.previousMousePositionY;
                let offset = this.offset - coordsDifference;
                if (offset < 0) {
                    offset = 0;
                }
                if (offset > this.maxOffset) {
                    offset = this.maxOffset;
                }

                if (this.watchYOffset && coordsDifferenceY) {
                    this.$emit('offsetYChanged', coordsDifferenceY);
                }

                this.offset = offset;
                this.previousMousePositionX = coords.x;
                this.previousMousePositionY = coords.y;
            },
            startOrContinueOffsetMove(direction) {
                if (this.moveintervalID !== undefined) {
                    return;
                }
                let delta;
                if (this.containerWidth > 500) {
                    delta = this.containerWidth * 0.0025;
                } else {
                    delta = this.containerWidth * 0.005;
                }


                this.moveintervalID = setInterval(() => {
                    if (direction === 'right' && this.offset < this.maxOffset) {
                        this.offset += delta;
                    }
                    if (direction === 'left' && this.offset > 0) {
                        this.offset -= delta;
                    }
                }, 15);

            },
            stopOffsetMoveIfStarted() {
                if (this.moveintervalID !== undefined) {
                    clearInterval(this.moveintervalID);
                }
                this.moveintervalID = undefined;
            },
            onPointerMove(coords) {
                if (this.contentClicked) {
                    this.processContentDragging(coords);
                }
                if (this.$store.state.timeline.draggedTask) {
                    let rect = this.$el.getBoundingClientRect();
                    if (rect.y > coords.y || rect.y + rect.height < coords.y) {
                        this.stopOffsetMoveIfStarted();
                        return;
                    }
                    if (rect.x + 50 > coords.x) {
                        this.startOrContinueOffsetMove('left');
                        return;
                    }
                    if (rect.x + rect.width - 50 < coords.x) {
                        this.startOrContinueOffsetMove('right');
                        return;
                    }
                    this.stopOffsetMoveIfStarted();
                } else {
                    this.stopOffsetMoveIfStarted();
                }

            }
        }
    };
</script>

<style lang="scss">
    .vue-timeline-component {
        &__timeline {
            display: inline-block;
            float: right;

            &.dragging {
                .column__user-section:hover {
                    background-color: #c4ebce !important;
                }
            }
        }

        &__scroll-box {
            &__container {
                width: 100%;
                position: relative;
                margin: auto;
                overflow-x: hidden;
                overflow-y: hidden;

            }

            &__content {
            }
        }

        &__noselect {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            /* Non-prefixed version, currently
                                             supported by Chrome and Opera */
        }
    }


</style>