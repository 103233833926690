var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.completableInstance.completed
    ? _c("span", { staticClass: "vertical-middle completion-info" }, [
        _vm.completedBySigningOff
          ? _c("span", [
              _vm.completableInstance.provided_assistance &&
              _vm.completableInstance.provided_assistance.length
                ? _c("span", { staticClass: "completion-info__assistance" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.assistanceLabel) +
                        ";\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.completableInstance.completed_by
                ? _c("span", [
                    _vm._v("signed off by "),
                    _c(
                      "strong",
                      [
                        _c("simple-link", {
                          attrs: {
                            target: _vm.completableInstance.completed_by,
                            "tab-index": "-1",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.completedByStepsCompleted
          ? _c("span", [_vm._v(" all sub-steps are signed off")])
          : _vm._e(),
        _vm._v(" "),
        _vm.completedByTimeout
          ? _c("span", [_vm._v(" auto completed")])
          : _vm._e(),
        _vm._v(" "),
        _vm.completedAtTimeStamp
          ? _c("span", [
              _vm._v("on "),
              _c("span", [_vm._v(_vm._s(_vm.completedAtTimeStamp))]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }