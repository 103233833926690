import { render, staticRenderFns } from "./GoalDetailPageMainTasks.vue?vue&type=template&id=ee66d3ba&scoped=true&"
import script from "./GoalDetailPageMainTasks.vue?vue&type=script&lang=js&"
export * from "./GoalDetailPageMainTasks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee66d3ba",
  null
  
)

export default component.exports