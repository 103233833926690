<template>
    <div class="task-tags-list-filter" v-show="tags.length">
        <div class="task-tags-list-filter__title" v-if="title">Popular Tags:</div>
        <div @click="toggleTagFilter(tag)" class="task-tags-list-filter__tag" v-for="tag in tags"
             :class="{active: isTagActive(tag)}" :key="tagsRepresentation(tag)+tag.id">{{ tagsRepresentation(tag) }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "TasksListPageTagsFilter",
        props: {
            tags: Array,
            title: {
                type: String,
                default: 'Popular Tags:'
            },
            tagsRepresentation: {
                type: Function,
                default: t => t
            }
        },
        data() {
            return {
                selectedTags: []
            };
        },
        methods: {
            toggleTagFilter(tag) {
                if (this.isTagActive(tag)) {
                    this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
                } else {
                    this.selectedTags.push(tag);
                }
                this.$emit('tagsFilterChanged', this.selectedTags);
            },
            isTagActive(tag) {
                return this.selectedTags.indexOf(tag) !== -1;
            }
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";
  @import "../../styles/mixins";

  .task-tags-list-filter {
    margin-bottom: 10px;

    &__tag {
      @include tag;
      cursor: pointer;
    }
  }
</style>