import * as _ from "lodash";
import {VISUAL_DATE_FORMAT} from "../events/new_calendar/config";
import {customTermFilter} from "../../../vue/filters/CustomTerm";
import { GoalFactory } from '~/angular/app/projects/goal_settings_factory/goalFactory';
import Vue from 'vue';
import { selectFactory } from '~/angular/app/shared/select_factory/select_factory';
import GoalTaskResource from '~/angular/app/projects/project_task_resource';

linkToGoalFactory.$inject = ['$q', 'goalTaskFactory'];

function linkToGoalFactory($q, goalTaskFactory) {
    let RESOURCE, ENTRY_ID;
    let task_id_list_deferred;
    let DEPENDANTS_LIST;
    let ALLOW_CREATE_GOAL;
    let RETURN_OBJECT;

    function show_goals_tasks_lists(goal_id, auto_create = false) {

        function create_task(goal_id) {
            goalTaskFactory.show_create_goal_task_wizard(goal_id, undefined, undefined, true)
                .then(res => {
                    GoalTaskResource().create_task(goal_id, res)
                        .then(success => {
                            show_goals_tasks_lists(goal_id);
                        }, err => {
                            Vue.notifications.error(err || 'Error');
                        });
                }, cancel => {
                    show_goals_tasks_lists(goal_id);
                });
        }

        if (auto_create) {
            create_task(goal_id);
            return;
        }

        let select_task_def = selectFactory.endpoint_selector(
            _.partial(RESOURCE.get_users_tasks_for_link_in_goal, ENTRY_ID, goal_id),
            true,
            {
                header: `Select ${customTermFilter('Tasks')}`,
                info: `Select or de-select ${customTermFilter('Tasks')} from list`,
                button_yes: 'Select',
                button_no: 'Cancel',
                empty: `There are no ${customTermFilter('Tasks')} in this ${customTermFilter('Goal')}.`,
                additional_buttons: [{
                    text: `+ New ${customTermFilter('Task')}`,
                    classes: 'btn btn-mm btn-green',
                    result_code: 'create_task'
                }]
            },
            false,
            false,
            RETURN_OBJECT,
        );

        select_task_def.then(result => {
            if (result === 'create_task') {
                create_task(goal_id);
            } else {
                task_id_list_deferred.resolve(result);
            }

        }, cancel => {
            return task_id_list_deferred.reject();
        });
    }

    function show_goals_list(for_user) {
        let POPUP_CONFIG = {
            header: `Select ${customTermFilter('Goal')}`,
            info: `Select or de-select ${customTermFilter('Goal')} from list`,
            button_yes: 'Select',
            button_no: 'Cancel',
            empty: `You do not have any ${customTermFilter('Goals')} to select`,
            additional_buttons: []
        };
        if (ALLOW_CREATE_GOAL) {
            POPUP_CONFIG.additional_buttons.push(
                {
                    text: `+ New ${customTermFilter('Goal')}`,
                    classes: 'btn btn-mm btn-green',
                    result_code: 'create_goal'
                }
            );
        }
        const additionalFilters = [];
        additionalFilters.push({
            name: 'show_archived_goals',
            text: `Show hidden ${customTermFilter('Goals')}`,
            inputType: 'switch',
            filter: true,
            value: false,
            paramsWhenTrue: {
                show_archived_goals: true
            }
        });

        POPUP_CONFIG['additional_filters'] = additionalFilters;

        let goal_selector = selectFactory.endpoint_selector(
            _.partial(RESOURCE.get_users_goals_for_link, ENTRY_ID),
            false, POPUP_CONFIG);

        goal_selector.then(result => {
            if (result === 'create_goal') {
                const gf = new GoalFactory();
                if(for_user){
                    gf.setForUserCreationForced(for_user);
                }
                let auto_create = true;

                gf.startGoalCreation()
                    .then(res => {
                        if (res.wasCopied) {
                            auto_create = false;
                        }
                        return res.goalData;
                    }, reject => {
                        show_goals_list(for_user);
                        return  new Promise(()=>{});
                    })
                    .then(goalData => {
                        show_goals_tasks_lists(goalData.id, auto_create);
                        Vue.notifications.success(`${customTermFilter('Goal')} Created`);
                    }, err => {
                        return  new Promise(()=>{});
                    });
            } else {
                // goal_id itself then
                show_goals_tasks_lists(result);
            }
        }, () => task_id_list_deferred.reject());
    }

    function link_to_goal(resource, id, allow_create_goal = false, for_user = undefined, return_object = false) {
        RESOURCE = resource;
        ENTRY_ID = id;
        DEPENDANTS_LIST = for_user ? [for_user] : [];
        ALLOW_CREATE_GOAL = allow_create_goal;
        RETURN_OBJECT = return_object;


        task_id_list_deferred = $q.defer();

        show_goals_list(for_user);

        return task_id_list_deferred.promise;
    }

    return {link_to_goal};

}

export default linkToGoalFactory;
