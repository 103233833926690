import ColumnUsersSectionRow from './ColumnUsersSectionRowFunc.js';
import moment from 'moment';

const API_DATE_FORMAT = "YYYY-MM-DD";

function onMouseUp(store, date, member) {
    if (store.state.timeline.draggedTask) {
        if (store.state.timeline.draggedTask.assignee && store.state.timeline.draggedTask.assignee.id === member.id && moment(date).format(API_DATE_FORMAT) === store.state.timeline.draggedTask.start_date) {
            return;
        }
        store.dispatch('timeline/drag_n_drop_task', {
            task: store.state.timeline.draggedTask,
            date: date,
            assignee: member
        });
    }
}

export default {
    name: "ColumnUsersSection",
    components: {ColumnUsersSectionRow},
    functional: true,
    props: {
        member: {
            type: Object
        },
        date: {
            type: Date
        }
    },
    render(createElement, context) {
        const mainElementStyle = {
            height: `${context.parent.$store.state.timeline.rowHeight * context.parent.$store.getters['timeline/rowCounts'][context.props.member.id]}px`,
        };
        if (context.parent.$store.getters['timeline/membersForTimeline'].indexOf(context.props.member) % 2 === 1) {
            mainElementStyle['background-color'] = '#e9ebf1';
        }
        const children = [];
        for (let i = 0; i < context.parent.$store.getters['timeline/rowCounts'][context.props.member.id]; i++) {
            children.push(createElement(ColumnUsersSectionRow, {
                    props: {
                        index: i,
                        member: context.props.member,
                        date: context.props.date,
                        key: i
                    }
                })
            );
        }
        return createElement('div',
            {
                class: {'column__user-section': true},
                style: mainElementStyle,
                on: {
                    mouseup: () => {
                        onMouseUp(context.parent.$store, context.props.date, context.props.member);
                    }
                },
            }, children
        );
    }
};