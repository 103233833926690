import Vue from "vue";
import store from "../../store";
import VueRouter from "vue-router";
import MyGoalsList from "./MyGoalsList.vue";
import MyTasksList from "./MyTasksList.vue";
import GoalDetailPageMain from "./GoalDetailPage/GoalDetailPageMain.vue";
import GoalDetailPageMainTasks from "./GoalDetailPage/GoalDetailPageMainTasks/GoalDetailPageMainTasks.vue";
import GoalDetailPageMainTimeline from "./GoalDetailPage/GoalDetailPageMainTimeline/GoalDetailPageMainTimeline.vue";
import GoalDetailPageMainMembers from "./GoalDetailPage/GoalDetailPageMainMembers.vue";
import GoalDetailPageMainAbout from "./GoalDetailPage/GoalDetailPageMainAbout.vue";
import GoalDetailPageMainAttachments from "./GoalDetailPage/GoalDetailPageMainAttachments.vue";
import UsersWhoseGoalsIManagePage from "./UsersWhoseGoalsIManage/UsersWhoseGoalsIManagePage.vue";
import GoalTaskPage from "./GoalTaskPage/GoalTaskPage.vue";
import GoalsMainRouter from "./GoalsMainRouter";
import GoalDetailPageExports from '~/angular/app/goals/mainPage/GoalDetailPage/GoalDetailPageExports.vue';

NewGoalsController.$inject = ['$scope'];

function NewGoalsController($scope) {
    const MAIN_PREFIX = '/goals/';

    const goalDetailPageChildren = [
        {
            path: '',
            component: GoalDetailPageMainTasks,
            props: true,
            name: 'GoalDetailPageMainTasks',
        },
        {
            path: 'timeline/',
            component: GoalDetailPageMainTimeline,
            props: true,
            name: 'GoalDetailPageMainTimeline',
        },
        {
            path: 'members/',
            component: GoalDetailPageMainMembers,
            props: true,
            name: 'GoalDetailPageMainMembers',
        },
        {
            path: 'about/',
            component: GoalDetailPageMainAbout,
            props: true,
            name: 'GoalDetailPageMainAbout',
        },
        {
            path: 'attachments/',
            component: GoalDetailPageMainAttachments,
            props: true,
            name: 'GoalDetailPageMainAttachments',
        },
        {
            path: 'exports/',
            component: GoalDetailPageExports,
            props: true,
            name: 'GoalDetailPageExports',
        },
        {
            path: '404/',
            name: '404',
            component: {
                template: `<div class="alert alert-danger">Not Found</div>`
            },
            meta: {
                isErrorPage: true
            }
        },
        {
            path: '403/',
            name: '403',
            component: {
                template: `<div class="alert alert-danger">Forbidden</div>`
            },
            meta: {
                isErrorPage: true
            }
        }
    ];

    const router = new VueRouter({
        mode: 'history',
        routes: [
            {
                path: MAIN_PREFIX,
                component: MyGoalsList,
                props: true,
                name: 'myGoalsListPageMain',
            },
            {
                path: MAIN_PREFIX + 'tasks/',
                component: MyTasksList,
                props: true,
                name: 'myTasksListPageMain',
            },
            {
                path: MAIN_PREFIX + 'list/',
                component: UsersWhoseGoalsIManagePage,
                props: true,
                name: 'UsersWhoseGoalsIManagePage',
            },
            {
                path: MAIN_PREFIX + ':goalId(\\d+)/',
                component: GoalDetailPageMain,
                children: goalDetailPageChildren,
                props: true,
            },
            {
                path: MAIN_PREFIX + ':goalId(\\d+)/task/:taskId(\\d+)/',
                component: GoalTaskPage,
                props: true,
            },
            {
                path: MAIN_PREFIX + ':goalId(\\d+)/task/:taskId(\\d+)/code/:accessCode/',
                component: GoalTaskPage,
                props: true,
            },
            {
                path: '*',
                name: '404nf',
                component: {
                    template: `<div class="alert alert-danger">Not Found</div>`
                },
                meta: {
                    isErrorPage: true
                }
            }
        ]
    });

    const vm = new Vue({
        template: `<router-view></router-view>`,
        el: '#NewGoalsController',
        router: router,
        store,
        render(createElement, context) {
            const {props: attrs} = this;
            return createElement(GoalsMainRouter, {attrs});
        }
    });
}

export default NewGoalsController;
