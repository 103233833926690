<template>
    <div class="users-list" :style="[listWidth, listHeight]">
        <div class="scale-selector">
            <select name="timelineScale" id="timelineScale" class="form-control" :value="timelineScale"
                    @change="onSelectChange"
                    style="padding: 6px 0;">
                <option value="Days">Days</option>
                <option value="Weeks">Weeks</option>
                <option value="Months">Months</option>
            </select>
        </div>
        <member :member="member" v-for="member in members" :key="member.id"></member>
    </div>
</template>

<script>
    import Member from './MemberListMember.vue';

    const SCALE_CONVERTER = {'weeks': {40: "Months"}, 'days': {40: "Weeks", 70: 'Days'}};

    export default {
        name: "UsersList",
        components: {Member},
        props: {
            width: {
                type: Number
            },
            contentHeight: {
                type: Number,
            }
        },
        data: () => ({
            timelineScale: undefined,
            scaleInitialized: false
        }),
        computed: {
            listWidth() {
                return {width: `${this.width}px`};
            },
            listHeight() {
                return {height: `${this.contentHeight}px`};
            },
            members() {
                return this.$store.getters['timeline/membersForTimeline'];
            },
            totalRowCount() {
                return this.$store.getters['timeline/totalRowCount'];
            },
            scale() {
                return this.$store.getters['timeline/scale'];
            }
        },
        watch: {
            scale(n, o) {
                this.timelineScale = SCALE_CONVERTER[this.$store.getters['timeline/scale']][this.$store.state.timeline.colWidth];
            }
        },
        mounted() {
            this.timelineScale = SCALE_CONVERTER[this.$store.getters['timeline/scale']][this.$store.state.timeline.colWidth];
        },
        methods: {
            onSelectChange(e) {
                let val = e.target.value;
                if (val !== this.timelineScale) {
                    this.$store.commit('timeline/set_timeline_scale', val);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
  .users-list {
    display: inline-block;

    &__user {

    }
  }

  .scale-selector {
    height: 60px;
  }
</style>