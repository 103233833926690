var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tasks-list-page" },
    [
      _c("vue-simple-header", [
        _c("span", { attrs: { slot: "panelLeft" }, slot: "panelLeft" }, [
          _c("span", { staticClass: "tasks-list-page__page-title" }, [
            _vm._v(_vm._s(_vm._f("customTerm")("Tasks"))),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "span",
            [
              _c(
                "router-link",
                {
                  staticStyle: { color: "#333" },
                  attrs: { to: { name: "myGoalsListPageMain" } },
                },
                [_vm._v(_vm._s(_vm._f("customTerm")("Goals")))]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "panelRight" }, slot: "panelRight" },
          [
            _c("vue-folder-button", {
              attrs: {
                text: "Filter",
                "icon-class": "fa-filter",
                active: _vm.filterIsActive,
                "active-color": "grass-green",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.openFilterPopup.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("vue-search-input", {
              attrs: { "initial-query": _vm.filter.query },
              on: { search: _vm.search, cancel: _vm.cancelSearch },
            }),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "myGoalsListPageMain" } } },
              [
                _c("vue-folder-button", {
                  attrs: {
                    text: "Back",
                    "icon-class": "fa-reply",
                    color: "blue",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.popularTags.length
        ? _c(
            "div",
            [
              _c("tasks-list-page-tags-filter", {
                attrs: { tags: _vm.popularTags },
                on: { tagsFilterChanged: _vm.tagsFilterChanged },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadMore,
              expression: "loadMore",
            },
          ],
        },
        _vm._l(_vm.tasks, function (task) {
          return _c("tasks-list-page-task", {
            key: task.id,
            attrs: {
              task: task,
              "router-navigation": true,
              layout: _vm.goalTaskLayout,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("tasks-list-page-filter", {
        ref: "filterPopup",
        attrs: {
          "available-assignees": _vm.availableAssignees,
          "available-owners": _vm.availableOwners,
          "available-categories": _vm.availableCategories,
          initial: _vm.filter,
        },
        on: { filterChanged: _vm.filterChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }