<template>
    <div class="">
        <goals-list-category
                :category="category"
                :allow-create-goal="goalCreationAllowedInCategory(category)"
                :allow-archive-goal="allowArchiveGoal"
                :router-navigation="routerNavigation"
                v-for="category in sortedCategories"
                :key="category.id"
                @loadMore="loadMore(category)"
                @archiveGoal="archiveGoal"
                @editCategory="editCategory"
                @unArchiveGoal="unArchiveGoal"
                @createGoalInCategory="createGoalInCategory"
        ></goals-list-category>
    </div>
</template>

<script>
    import GoalsListCategory from './GoalsListCategory.vue';
    import RouterNavigationMixin from "../../../../vue/mixins/RouterNavigationMixin";

    export default {
        components: {GoalsListCategory},
        mixins: [RouterNavigationMixin],
        props: {
            categories: Array,
            availableCategories: Array,
            allowCreateGoal: {
                type: Boolean,
                default: false
            },
            allowArchiveGoal: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            sortedCategories() {
                const res = [];
                for (const c of this.categories) {
                    if (c.name !== 'Default') {
                        res.push(c);
                    } else {
                        res.unshift(c);
                    }
                }
                return res;
            },
        },
        methods: {
            goalCreationAllowedInCategory(category) {
                return this.allowCreateGoal && (category.id === null || this.availableCategories.map(c => c.id).indexOf(category.id) !== -1);
            },
            loadMore(category) {
                this.$emit('loadMore', category);
            },
            archiveGoal(data) {
                this.$emit('archiveGoal', data);
            },
            editCategory(data) {
                this.$emit('editCategory', data);
            },
            unArchiveGoal(data) {
                this.$emit('unArchiveGoal', data);
            },
            createGoalInCategory(data) {
                this.$emit('createGoalInCategory', data);
            }
        },
        name: "GoalsList"
    };
</script>

<style scoped>

</style>