import Vue from 'vue';

const GOAL_HEADER_BUS_ACTIONS = {
    GO_TO_PAGE: 'go_to_page',
    GO_BACK: 'go_back',
    TOGGLE_EDIT_MODE: 'toggle_edit_mode',
    EDIT_GOAL: 'edit_goal',
    RUN_FULLSCREEN_ON_TIMELINE: 'run_fullscreen_on_timeline',
};

const goalHeaderBus = new Vue();

export {goalHeaderBus, GOAL_HEADER_BUS_ACTIONS};