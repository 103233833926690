/**
 * Created by orion on 28.05.17.
 */
import { DjangoUrls } from 'DjangoUrls';
import _ from 'lodash';
import axios from 'axios';

GoalsListResource.$inject = ['$http'];
const PREFIX = 'api.v1:goals_list-';

function GoalsListResource($http) {
    return {
        get_goals_i_manage: function (userId, params) {
            const url = DjangoUrls[`${PREFIX}get-goals-i-manage`](userId);
            return axios({
                method: 'GET',
                url,
                cache: false,
                params
            });
        },
        get_my_goals: function (userId, params, query) {
            const url = DjangoUrls[`${PREFIX}get-my-goals`](userId);
            return axios({
                method: 'GET',
                url,
                cache: false,
                params: _.merge({ query }, params)
            });
        },
        get_users_goals_as_buddy: function (userId, params, query) {
            const url = DjangoUrls[`${PREFIX}get-users-goals-as-buddy`](userId);
            return axios({
                method: 'GET',
                url,
                cache: false,
                params: _.merge({ query }, params)
            });
        },
        get_shared_goals: function (userId, params, query) {
            const url = DjangoUrls[`${PREFIX}get-shared-goals`](userId);
            return axios({
                method: 'GET',
                url,
                cache: false,
                params: _.merge({ query }, params)
            });
        },
        load_more_in_category: function (userId, category_id = undefined, offset = 0, limit = 10, filterParams = {}, query) {
            const url = DjangoUrls[`${PREFIX}load-more-in-category`](userId);
            const params = {
                offset,
                limit
            };
            if (category_id) {
                params.category_id = category_id;
            }
            return axios({
                method: 'GET',
                url,
                cache: false,
                params: _.merge(params, filterParams, { query })
            });
        },
        load_more_in_category_as_buddy: function (userId, category_id = null, offset = 0, limit = 10, filterParams = {}, query) {
            const url = DjangoUrls[`${PREFIX}load-more-in-category-as-buddy`](userId);
            const params = {
                offset,
                limit
            };
            if (category_id) {
                params.category_id = category_id;
            }
            return axios({
                method: 'GET',
                url,
                cache: false,
                params: _.merge(params, filterParams, { query })
            });
        },
        load_more_in_category_as_viewer: function (userId, category_id = null, offset = 0, limit = 10, filterParams = {}, query) {
            const url = DjangoUrls[`${PREFIX}load-more-in-category-as-viewer`](userId);
            const params = {
                offset,
                limit
            };
            if (category_id) {
                params.category_id = category_id;
            }
            return axios({
                method: 'GET',
                url,
                cache: false,
                params: _.merge(params, filterParams, { query })
            });
        },
        archive_goal: function (userId, goalId, data) {
            const url = DjangoUrls[`${PREFIX}archive-goal`](userId);
            return axios({
                method: 'POST',
                url,
                data: {
                    id: goalId,
                    ...data
                }
            });
        },
        un_archive_goal: function (userId, goalId, data) {
            const url = DjangoUrls[`${PREFIX}un-archive-goal`](userId);
            return axios({
                method: 'POST',
                url,
                data: {
                    id: goalId,
                    ...data
                }
            });
        },
        get_users_data_for_filter_as_buddy_or_self: function (userId) {
            const url = DjangoUrls[`${PREFIX}get-users-data-for-filter-as-buddy-or-self`](userId);
            return axios({
                method: 'GET',
                url
            });
        },
        get_users_data_for_filter_as_viewer: function (userId) {
            const url = DjangoUrls[`${PREFIX}get-users-data-for-filter-as-viewer`](userId);
            return axios({
                method: 'GET',
                url
            });
        },
    };
}

export default GoalsListResource;
