var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vue-timeline-component__timeline",
      class: _vm.timelineClasses,
      style: [_vm.timelineHeight],
    },
    [
      _c(
        "div",
        {
          ref: "container",
          staticClass:
            "vue-timeline-component__scroll-box__container vue-timeline-component__noselect",
          style: _vm.containerStyle,
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.mousedownOnTimeline.apply(null, arguments)
            },
            touchstart: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.touchstartOnTimeline.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vue-timeline-component__scroll-box__content",
              style: _vm.contentStyle,
            },
            _vm._l(_vm.columns, function (column) {
              return _c("column", {
                key: column.toDateString(),
                attrs: { width: _vm.colWidth, date: column },
              })
            }),
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }