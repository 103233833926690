<template>
    <table class="table table-striped task-detail-info" style="font-size: 16px">
        <tbody>
        <tr class="hidden-print">
            <th style="width: 138px">{{ "Goal"|customTerm }}:</th>
            <td>
                <strong class="hidden-print"><a :href="task.goal.link">{{ task.goal.name |cutName(200) }}</a></strong>
            </td>
        </tr>
        <tr class="hidden-print">
            <th style="width: 138px">{{ "Goal"|customTerm }} Owner:</th>
            <td>
                <simple-link-with-avatar :target="task.goal.owner"></simple-link-with-avatar>
            </td>
        </tr>
        <tr class="visible-print">
            <td colspan="2">
                <strong class="visible-print">{{ task.goal.name }}</strong>
            </td>
        </tr>
        <tr>
            <th>Category:</th>
            <td>
                <img :src="goalCategory.image"
                     v-if="goalCategory"
                     class="border-goal img-rounded"
                     width="30"
                     height="30"
                     :alt="goalCategory.name"
                >
                <strong>{{ goalCategory ? goalCategory.name : 'Default' }}</strong>
            </td>
        </tr>
        <tr v-show="linkedEvents.length">
            <th>Linked Events:</th>
            <td>
                <span v-for="(linkedEvent,i) in linkedEvents">
                    <task-main-info-linked-event :event="linkedEvent"></task-main-info-linked-event> <span
                        v-show="i<(linkedEvents.length-1)">, </span>
                </span>

            </td>
        </tr>
        <tr>
            <th>Start Date:</th>
            <td>{{ task.start_date | gbdate }}</td>
        </tr>
        <tr>
            <th>End Date:</th>
            <td>{{ task.end_date | gbdate }}</td>
        </tr>
        <tr>
            <th>{{ "Task"|customTerm }} Description:</th>
            <td><i style="white-space: pre-wrap;">{{ task.description }}</i></td>
        </tr>

        <tr>
            <th>{{ "Task"|customTerm }} Status:</th>
            <td :class="{'color-grass-green-dark':task.completed}">
                <span class="vertical-middle">
                    <i class="fa " :class="statusIcon"></i> <strong>{{ statusText }}</strong>
                </span>
                <task-main-info-completion-data
                        :completable-instance="task"
                        v-if="task.completed"
                ></task-main-info-completion-data>
            </td>
        </tr>

        <tr v-if="showTaskStepsInInfo">
            <th>Task Sub-steps:</th>
            <td>
                <div v-for="(step, i) in task.steps">
                    <task-main-info-task-step
                            :step="step"
                            :task-id="task.id"
                            :completable="completable"
                            :allow-set-assistance-options="allowSetAssistanceOptions"
                            :allow-edit="canEditTask"
                            :index="i+1"

                            :availableCompletionOptions="availableCompletionOptions"

                            @taskCompleted="taskCompleted"
                            @taskInCompleted="taskInCompleted"
                            @editStep="editStep"
                    ></task-main-info-task-step>
                </div>
                <div v-if="canEditTask">
                    <a class="task-detail-info__add-step-label hidden-print" href="javascript:;" @click.prevent.stop="addStep">+ Add
                        Sub-step</a>
                </div>
            </td>
        </tr>

        <tr>
            <th>Who is doing it?</th>
            <td>
                <simple-link-with-avatar
                        :target="task.assignee"
                        v-if="task.assignee"
                         :style="assigneeAvatarStyle"
                ></simple-link-with-avatar>
                <img v-if="!task.assignee" :src="assigneeAvatar" class="img-circle" width="40" height="40" alt="Anybody"/>
                <strong v-if="!task.assignee">Anybody</strong>
                <span style="font-size: 28px; vertical-align: middle; margin-left: 10px;"
                      v-if="viewerCanConfirmTaskAssignment">
                    <i class="fa fa-check color-green pointer"
                       v-tooltip="'OK, I\'m on to it'"
                       aria-label="OK, I'm on to it"
                       role="button"
                       tabindex="0"
                       @click="acceptTaskAssignment"
                       @keydown.enter="acceptTaskAssignment"
                    ></i>
                    <i class="fa fa-times color-red pointer"
                       style="margin-left: 5px"
                       v-tooltip="'Sorry, I can\'t do this'"
                       aria-label="Sorry, I can't do this"
                       role="button"
                       tabindex="0"
                       @click="rejectTaskAssignment"
                       @keydown.enter="rejectTaskAssignment"
                    ></i>
                </span>
            </td>
        </tr>
        <tr v-if="task.tags.length">
            <th colspan="2">Tags:
                <task-tags-list :tags="task.tags" style="display: inline; margin-left: 10px"></task-tags-list>
            </th>
        </tr>
        <step-add-edit-remove-popup ref="step-add-edit-remove-popup"></step-add-edit-remove-popup>

        </tbody>
    </table>
</template>

<script>
    import StepAddEditRemovePopup from '../../../projects/task_detail_page/StepAddEditRemovePopup.vue';
    import TaskMainInfoCompletionData from "../../../projects/task_detail_page/TaskMainInfoCompletionData.vue";
    import config from '../../config.js';
    import TaskTagsList from '../../../projects/GoalDetailPage/TaskTagsList.vue';
    import Vue from 'vue';

    const STATUS_TEXT_DICT = {
        'in_progress': 'In progress',
        'overdue': 'Overdue',
        'completed': 'Completed',
        'pending': 'Pending',
        'not_in_list': 'Unscheduled',
        'unscheduled': 'Unscheduled'
    };

    const STATUS_ICON_DICT = {
        'in_progress': 'fa-clock-o',
        'overdue': 'fa-exclamation',
        'completed': 'fa-check',
        'pending': ' fa-question',
        'unscheduled': 'fa-question'
    };

    import TaskMainInfoLinkedEvent from '../../../projects/task_detail_page/TaskMainInfoLinkedEvent.vue';
    import TaskMainInfoTaskStep from "../../../projects/task_detail_page/TaskMainInfoTaskStep.vue";

    export default {
        name: "TaskMainInfo",
        props: {
            initialTask: Object,
            goalCategory: Object,
            linkedEvents: Array,
            availableCompletionOptions: Array,

            isGoalAdmin: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                task: this.initialTask,
            };
        },
        components: {
            TaskTagsList,
            TaskMainInfoLinkedEvent,
            TaskMainInfoTaskStep,
            TaskMainInfoCompletionData,
            StepAddEditRemovePopup
        },
        computed: {
            viewerCanConfirmTaskAssignment() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                if (this.task.assignee && CurrentUser.id === this.task.assignee.id) {
                    return this.task.confirmed && !this.task.assignee_accepted;
                }
                return false;
            },
            assigneeAvatarStyle() {
                if (this.task.assignee && !this.task.assignee_accepted) {
                    return {'opacity': 0.7};
                }
            },
            canEditTask() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const createdByCurrentUser = this.task.created_by && this.task.created_by.id === CurrentUser.id;
                return this.task.is_admin || createdByCurrentUser;
            },
            showTaskStepsInInfo() {
                // if can edit task so can convert straight task to one with steps
                // or steps already added
                return this.canEditTask || this.task.steps && this.task.steps.length;
            },
            completable() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const currentUserIsAssignee = this.task.assignee && CurrentUser.id === this.task.assignee.id;
                const createdByCurrentUser = this.task.created_by && this.task.created_by.id === CurrentUser.id;

                return currentUserIsAssignee || this.isGoalAdmin || createdByCurrentUser;
            },
            allowSetAssistanceOptions() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const goalOwner = this.task.goal.owner;
                const taskAssignee = this.task.assignee;
                if (CurrentUser.is_guardian_of(goalOwner.id)) {
                    return true;
                }
                if (taskAssignee && CurrentUser.is_guardian_of(taskAssignee.id)) {
                    return true;
                }
                return false;
            },
            assigneeAvatar() {
                if (this.task.assignee) {
                    return this.task.assignee.avatar;
                } else {
                    return config.EMPTY_ASSIGNEE_AVATAR;
                }
            },
            statusIcon() {
                return STATUS_ICON_DICT[this.task.status];
            },
            statusText() {
                return STATUS_TEXT_DICT[this.task.status];
            }
        },
        methods: {
            acceptTaskAssignment() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                $rootScope.show_dimmer();
                GoalTaskResource.accept_assignment(this.task.id)
                    .then(resp => {
                        Vue.notifications.success('Accepted');
                        this.task.assignee_accepted = true;
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally(
                        $rootScope.hide_dimmer
                    );
            },
            rejectTaskAssignment() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                $rootScope.show_dimmer();
                GoalTaskResource.reject_assignment(this.task.id)
                    .then(resp => {
                        Vue.notifications.success('Rejected');
                        this.task.assignee = undefined;
                        this.task.confirmed = false;
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally(
                        $rootScope.hide_dimmer
                    );
            },
            taskCompleted(taskData) {
                this.task.status = taskData.task.status;
                this.task.completed = taskData.task.completed;
                this.task.completion_type = taskData.task.completion_type;
                this.task.completed_by = taskData.task.completed_by;
                this.task.completed_at = taskData.task.completed_at;
            },
            taskInCompleted(taskData) {
                this.task.status = taskData.task.status;
                this.task.completed = taskData.task.completed;
                this.task.completion_type = taskData.task.completion_type;
                this.task.completed_by = taskData.task.completed_by;
                this.task.completed_at = taskData.task.completed_at;
            },
            addStep() {
                this.$refs['step-add-edit-remove-popup'].show(undefined, {
                    saveButton: 'Add',
                    cancelButton: 'Cancel',
                    deleteButtonAllowed: false,
                    header: 'Add Sub-step',
                })
                    .then(result => {
                        this.createStep(result.data);
                    });
            },
            editStep(step) {
                this.$refs['step-add-edit-remove-popup'].show(step, {
                    saveButton: 'Update',
                    cancelButton: 'Cancel',
                    deleteButtonAllowed: true,
                    deleteButton: 'Remove',
                    header: 'Edit Sub-step',
                })
                    .then(result => {
                        if (result.type === 'save') {
                            this.updateStep(step, result.data);
                        }
                        if (result.type === 'delete') {
                            this.deleteStep(step);
                        }
                    });
            },

            updateStep(step, stepData) {
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                GoalTaskResource.update_step_data(this.task.id, step.id, stepData)
                    .then(resp => {
                        step.description = stepData.description;
                        step.name = stepData.name;
                        notifications.success('Updated');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            createStep(stepData) {
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                GoalTaskResource.create_step(this.task.id, stepData)
                    .then(resp => {
                        if (!this.task.steps) {
                            this.task.steps = [];
                        }
                        this.task.steps.push(resp.data.step);
                        this.task.has_steps = true;

                        this.task.status = resp.data.task.status;


                        this.task.completed = resp.data.task.completed;
                        this.task.completion_type = resp.data.task.completion_type;
                        this.task.completed_by = resp.data.task.completed_by;
                        notifications.success('Created');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            deleteStep(step) {
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                $rootScope.show_dimmer();
                GoalTaskResource.remove_step(this.task.id, step.id)
                    .then(resp => {
                        this.task.steps.splice(this.task.steps.indexOf(step), 1);

                        this.task.status = resp.data.task.status;
                        this.task.completed = resp.data.task.completed;
                        this.task.completion_type = resp.data.task.completion_type;
                        this.task.completed_by = resp.data.task.completed_by;
                        this.task.has_steps = resp.data.task.has_steps;
                        notifications.success('Removed');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            }
        }
    };
</script>

<style scoped lang="scss">
    .task-detail-info {
        &__add-step-label {
            cursor: pointer;

        }
    }
</style>