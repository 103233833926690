var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goal_tasks_list__container" },
    [
      _c(
        "h3",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mediaAttachments.length,
              expression: "mediaAttachments.length",
            },
          ],
          staticStyle: { "font-size": "24px", "margin-bottom": "5px" },
        },
        [_vm._v("Media Attachments")]
      ),
      _vm._v(" "),
      !_vm.mediaAttachments.length && !_vm.otherAttachments.length
        ? _c("div", { staticClass: "alert alert-info" }, [
            _vm._v("There has been nothing\n        added yet\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        _vm._l(_vm.mediaAttachments, function (mediaAttachment) {
          return _c(
            "div",
            {
              key: mediaAttachment.id,
              staticStyle: { position: "relative", display: "inline-block" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isAdmin && _vm.editMode,
                      expression: "isAdmin&&editMode",
                    },
                  ],
                  staticStyle: {
                    position: "absolute",
                    right: "3px",
                    top: "3px",
                    "z-index": "1",
                    cursor: "pointer",
                    width: "35px",
                    height: "35px",
                    "border-radius": "50%",
                    "background-color": "#E6493B",
                    color: "white",
                    "text-align": "center",
                    "line-height": "35px",
                    "font-size": "25px",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.removeAttachment(mediaAttachment, "media")
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-times" })]
              ),
              _vm._v(" "),
              _c("folder-item", {
                attrs: {
                  attachment: mediaAttachment.media_attachment,
                  "show-tooltip": false,
                },
                on: {
                  click: function ($event) {
                    return _vm.showAttachment(mediaAttachment)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "h3",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.otherAttachments.length,
              expression: "otherAttachments.length",
            },
          ],
        },
        [_vm._v("Other Attachments")]
      ),
      _vm._v(" "),
      _vm._l(_vm.otherAttachments, function (attachment) {
        return _c(
          "div",
          { key: attachment.id, staticStyle: { display: "inline-block" } },
          [
            attachment.type === "goal"
              ? _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: {
                      href: attachment.goal_attachment.link,
                      target: "_blank",
                    },
                  },
                  [
                    _c("vue-stuff-preview", {
                      attrs: {
                        "stuff-item": attachment.goal_attachment,
                        "stuff-type": "goal",
                        animation: true,
                        deletable: _vm.isAdmin && _vm.editMode,
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.removeAttachment(attachment, "other")
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            attachment.type === "story"
              ? _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: {
                      href: attachment.story_attachment.link,
                      target: "_blank",
                    },
                  },
                  [
                    _c("vue-stuff-preview", {
                      attrs: {
                        "stuff-item": attachment.story_attachment,
                        "stuff-type": "story",
                        animation: true,
                        deletable: _vm.isAdmin && _vm.editMode,
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.removeAttachment(attachment, "other")
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px", "text-align": "right" } },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-mm btn-green",
              on: {
                click: function ($event) {
                  return _vm.attach()
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-plus" }),
              _vm._v(" Add Attachments\n        "),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }