var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "users-list", style: [_vm.listWidth, _vm.listHeight] },
    [
      _c("div", { staticClass: "scale-selector" }, [
        _c(
          "select",
          {
            staticClass: "form-control",
            staticStyle: { padding: "6px 0" },
            attrs: { name: "timelineScale", id: "timelineScale" },
            domProps: { value: _vm.timelineScale },
            on: { change: _vm.onSelectChange },
          },
          [
            _c("option", { attrs: { value: "Days" } }, [_vm._v("Days")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Weeks" } }, [_vm._v("Weeks")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "Months" } }, [_vm._v("Months")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.members, function (member) {
        return _c("member", { key: member.id, attrs: { member: member } })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }