/**
 * Created by orion on 29.05.17.
 */
import _ from 'lodash';

GoalTaskMainOptionsPopupController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$log', 'initial_data', 'popup_config'];

function GoalTaskMainOptionsPopupController($scope, $uibModalInstance, $timeout, $log, initial_data, popup_config) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };
    $scope.task_name = initial_data.name;
    $scope.description = initial_data.description;
    $scope.media_for_avatar = initial_data.media_for_avatar;
    $scope.avatar = initial_data.media_for_avatar || (initial_data.uses_default_avatar ? undefined : initial_data.avatar);

    $scope.popup_config = popup_config;

    $scope.create = create;
    $scope.createNow = createNow;
    $scope.add_avatar = add_avatar;
    $scope.reset_avatar = reset_avatar;

    $scope.load_another_task = load_another_task;

    function dump_goal_task_data() {
        return {
            name: $scope.task_name,
            description: $scope.description,
            media_for_avatar: $scope.media_for_avatar,
            avatar: $scope.avatar
        };
    }

    function create() {
        $uibModalInstance.close({
            type: 'save',
            data: dump_goal_task_data()
        });
    }

    function createNow() {
        $uibModalInstance.close({
            type: 'save',
            data: _.merge({createNow: true}, dump_goal_task_data())
        });
    }

    function add_avatar() {
        $uibModalInstance.close({
            type: 'add_media',
            data: dump_goal_task_data()
        });
    }

    function reset_avatar() {
        $scope.media_for_avatar = undefined;
        $scope.avatar = undefined;
    }

    function load_another_task() {
        $uibModalInstance.close({
            type: 'load_another_task',
            data: dump_goal_task_data()
        });
    }
}

export default GoalTaskMainOptionsPopupController;