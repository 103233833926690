import { render, staticRenderFns } from "./GoalDetailPageExports.vue?vue&type=template&id=081cf2c2&scoped=true&"
import script from "./GoalDetailPageExports.vue?vue&type=script&lang=js&"
export * from "./GoalDetailPageExports.vue?vue&type=script&lang=js&"
import style0 from "./GoalDetailPageExports.vue?vue&type=style&index=0&id=081cf2c2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "081cf2c2",
  null
  
)

export default component.exports