var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "timeline-component" } },
    [
      _c("goal-tags-filter-panel"),
      _vm._v(" "),
      _c("timeline-wrapper", { attrs: { width: _vm.width } }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          _vm._l(_vm.taskOutsideTimeline, function (task) {
            return _c("step", { key: task.id, attrs: { task: task } })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMembersVisibilitySwitch,
              expression: "showMembersVisibilitySwitch",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "strong",
              { staticClass: "pointer", on: { click: _vm.expandMembers } },
              [_vm._v("Show other members.")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showOtherMembers,
              expression: "showOtherMembers",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "strong",
              { staticClass: "pointer", on: { click: _vm.hideMembers } },
              [_vm._v("Hide other members.")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }