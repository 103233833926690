var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _vm.initialized ? _c("goal-detail-page-header") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _vm.initialized || _vm.error
            ? _c("router-view", {
                on: { userSelfDeleted: _vm.userSelfDeleted },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }