<template>
    <modal v-model="filterMenuOpened" :title=filterLabel
           :transition="0">
        <div class="div">
            <div v-for="assignee in availableAssignees" class="assignee">
                <i class="fa fa-check-square-o pointer vertical-middle" :class="checkBoxClass(assignee)"
                   @click="toggleAssignee(assignee)"></i>
                <img :src="assignee.avatar" class="img-circle vertical-middle" width="40" height="40"/>
                <span class="vertical-middle">{{assignee.name}}</span>
            </div>
        </div>
        <div slot="footer">
            <button class="btn btn-mm" @click="selectAll">Select All</button>
            <button class="btn btn-mm" @click="clear">Clear</button>
            <button class="btn btn-mm btn-green" @click="ok">Ok</button>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "GoalTasksFilterPopup",
        data() {
            return {
                filterMenuOpened: false
            };
        },
        methods: {
            show() {
                this.filterMenuOpened = true;
            },
            checkBoxClass(assignee) {
                if (this.assigneesInFilter.indexOf(assignee.id) !== -1) {
                    return 'color-grass-green';
                } else {
                    return 'color-gray';
                }
            },
            toggleAssignee(assignee) {
                if (this.assigneesInFilter.indexOf(assignee.id) !== -1) {
                    this.$store.commit('goalDetailPageStore/set_assignees_in_filter', {id_list: this.assigneesInFilter.filter(id => id !== assignee.id)});
                } else {
                    this.$store.commit('goalDetailPageStore/set_assignees_in_filter', {id_list: this.assigneesInFilter.concat(assignee.id)});
                }
            },
            ok() {
                this.filterMenuOpened = false;
            },
            selectAll() {
                this.$store.commit('goalDetailPageStore/set_assignees_in_filter', {id_list: this.availableAssignees.map(a => a.id)});
            },
            clear() {
                this.$store.commit('goalDetailPageStore/set_assignees_in_filter', {id_list: []});
            }
        },
        computed: {
            filterLabel(){
              return `Filter ${this.$customTerm('Task')} by user`
            },
            availableAssignees() {
                return this.$store.getters['goalDetailPageStore/availableAssignees'];
            },
            assigneesInFilter() {
                return this.$store.state.goalDetailPageStore.assigneesInFilter;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../../styles/const";

    .assignee {
        font-size: 18px;
        .fa {
            font-size: 22px;
        }
        color: #333;
        border-bottom: solid 1px $gray;
        margin-bottom: 5px;
        padding-bottom: 5px;
    }
</style>