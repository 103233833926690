var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { title: _vm.filterLabel, transition: 0 },
      model: {
        value: _vm.filterMenuOpened,
        callback: function ($$v) {
          _vm.filterMenuOpened = $$v
        },
        expression: "filterMenuOpened",
      },
    },
    [
      _c(
        "div",
        { staticClass: "div" },
        _vm._l(_vm.availableAssignees, function (assignee) {
          return _c("div", { staticClass: "assignee" }, [
            _c("i", {
              staticClass: "fa fa-check-square-o pointer vertical-middle",
              class: _vm.checkBoxClass(assignee),
              on: {
                click: function ($event) {
                  return _vm.toggleAssignee(assignee)
                },
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "img-circle vertical-middle",
              attrs: { src: assignee.avatar, width: "40", height: "40" },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "vertical-middle" }, [
              _vm._v(_vm._s(assignee.name)),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.selectAll } },
          [_vm._v("Select All")]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-mm", on: { click: _vm.clear } }, [
          _vm._v("Clear"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-mm btn-green", on: { click: _vm.ok } },
          [_vm._v("Ok")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }