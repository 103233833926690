import EventTaskDatesPopup from '../../../../events/new_event/EventTaskDatesPopup.vue';
import GoalTaskStepsPopup from './GoalTaskStepsPopup.vue';

let _ = require('lodash');
import Vue from 'vue';
import urls from 'DjangoUrls';

let { DjangoUrls } = urls;

import GoalTaskMainOptionsPopupController from './main_options_popup/controller';
import { customTermFilter } from '../../../../../../vue/filters/CustomTerm';
import { selectFactory } from '~/angular/app/shared/select_factory/select_factory';
import GoalResource from '~/angular/app/projects/project_resource';

goalTaskFactory.$inject = ['$q', 'addMedia','simplePopupFactory'];

function goalTaskFactory($q, addMedia, simplePopupFactory) {

    let DEFAULT_GOAL_TASK_DATA = {
        name: '',
        description: '',
        assignee: undefined,
        start_date: undefined,
        end_date: undefined,
        notify_when_due: undefined,
        auto_complete_when_due: undefined,
        media_for_avatar: undefined,
        avatar: ''
    };

    let loadedTaskData = {};

    let task_to_copy_id = undefined;

    let target_goal_id = undefined;

    let promise;

    let goal_task_data = undefined;

    function show_add_media_menu(default_callback, cancel_callback) {
        addMedia.add_media(
            {
                allow_add_from_library: true,
                max_count: 1,
                type: 'image',
                popup_header: 'What sort of media would you like to add?'
            },
            {
                default_callback,
                cancel_callback
            }
        );
    }

    function show_set_assignee_menu(goal_id = undefined, popup_config = {}, allow_multiple = false) {
        const url = DjangoUrls['api.v1:goals-get-goals-members-list-for-action-link'](goal_id || target_goal_id);

        return selectFactory.endpoint_selector(url, allow_multiple, {
            header: popup_config.header || 'Select assignee',
            info: (popup_config.info !== undefined) ? popup_config.info : `Select or de-
            select a user
            from list, or leave this
            if you do not want to assign this ${customTermFilter('Task')}.`,
            button_yes: popup_config.save_button || 'Update',
            button_no: popup_config.cancel_button || 'Cancel',
            additional_filters: popup_config.additional_filters || [],
            empty: 'You do not have any items to select'
        }, !allow_multiple);
    }

    function show_copy_task_menu() {
        let res = $q.defer();
        let selectedGoalId;
        selectFactory.endpoint_selector(GoalResource().get_goals_to_copy_task, false, {
            header: `Select ${customTermFilter('Goal')} to copy ${customTermFilter('Task')}`,
            info: `Select ${customTermFilter('Goal')}
                   from which you want to copy ${customTermFilter('Task')}.`,
            button_yes: 'Select',
            button_no: 'Cancel',
            empty: `You do not have any ${customTermFilter('Goal')} to select`
        }, false)
            .then(goalId => {
                selectedGoalId = goalId;
                return selectFactory.endpoint_selector(_.partial(GoalResource().get_actions_to_copy, goalId), false, {
                    header: `Select ${customTermFilter('Task')} to copy data`,
                    info: `Select ${customTermFilter('Task')} to copy data to new one.`,
                    button_yes: 'Select',
                    button_no: 'Cancel',
                    empty: `You do not have any ${customTermFilter('Tasks')} created by you in that ${customTermFilter('Goal')}.`
                }, false);
            }, err => {
                res.reject();
                return new Promise(_.noop, _.noop);
            })
            .then(
                taskId => {
                    Vue.loadingSpinner.show();
                    return GoalResource().get_task_data_to_copy(selectedGoalId, taskId);
                },
                (cancel) => {
                    res.reject();
                    return new Promise(_.noop, _.noop);
                }
            )
            .then(resp => {
                res.resolve(resp.data);
            }, err => {
                Vue.notifications.error(err || 'Error');
                res.reject();
                return new Promise(_.noop, _.noop);
            })
            .finally(() => Vue.loadingSpinner.hide());
        return res.promise;
    }

    function main_task_options(initial, popup_config = {}, promise_to_resolve) {
        /**
         * @param initial {Object}
         * {name:string,
         * description: string,
         * avatar: string?,
         * auto_avatar: Boolean,
         * media_for_avatar: MediaContent
         * }
         * @return {Object} as above but filled\changed
         */
        let DEFAULT_POPUP_CONFIG = {
            header: `Edit ${customTermFilter('Goal')} ${customTermFilter('Task')}`,
            save_button: 'Save',
            cancel_button: 'Cancel',
            allow_clone: false
        };
        let actual_popup_config = _.merge({}, DEFAULT_POPUP_CONFIG, popup_config);
        let initial_data = _.merge({}, initial);
        let options_result = promise_to_resolve === undefined ? $q.defer() : promise_to_resolve;
        let popup_result = simplePopupFactory.create_popup(GoalTaskMainOptionsPopupController, require('./main_options_popup/template.html'), {
            initial_data,
            popup_config: actual_popup_config
        });

        let add_media_items = (items_list) => {
            initial_data.media_for_avatar = items_list[0];
            main_task_options(initial_data, popup_config, options_result);
        };

        popup_result.then(res => {
            if (res.type === 'add_media') {
                initial_data = res.data;
                show_add_media_menu(add_media_items, () => {
                    main_task_options(initial_data, popup_config, options_result);
                });
            }
            if (res.type === 'load_another_task') {
                initial_data = res.data;
                show_copy_task_menu()
                    .then(taskData => {
                        loadedTaskData = taskData;
                        task_to_copy_id = taskData.id;
                        main_task_options(taskData, popup_config, options_result);
                    }, cancel => {
                        main_task_options(initial_data, popup_config, options_result);
                    });
            }
            if (res.type === 'save') {
                options_result.resolve(res.data);
            }
        }, () => options_result.reject());

        return options_result.promise;
    }

    function steps_options() {
        const {
            popupComponent,
            vm
        } = simplePopupFactory.mount_vue_popup(GoalTaskStepsPopup);

        return popupComponent.show()
            .finally(() => {
                if (vm) {
                    vm.$destroy();
                }
            });
    }

    function date_options(initial, popup_config = {}) {
        let initial_data = _.merge({}, DEFAULT_GOAL_TASK_DATA, initial);
        let options_result = $q.defer();
        let DEFAULT_POPUP_CONFIG = {
            header: `Edit ${customTermFilter('Goal')} ${customTermFilter('Task')} Dates`,
            save_button: 'Save',
            cancel_button: 'Cancel',
            allow_create_steps: false,
        };
        let actual_popup_config = _.merge({}, DEFAULT_POPUP_CONFIG, popup_config);

        const {
            popupComponent,
            vm
        } = simplePopupFactory.mount_vue_popup(EventTaskDatesPopup);
        popupComponent.show(undefined, initial_data, actual_popup_config)
            .then(res => {
                options_result.resolve(res);
            }, () => options_result.reject())
            .finally(() => {
                if (vm) {
                    vm.$destroy();
                }
            });

        return options_result.promise;

    }

    function assignee_options(goal_id, popup_config = {}, allow_multiple = false) {
        let options_result = $q.defer();
        show_set_assignee_menu(goal_id, popup_config, allow_multiple).then(selected_user_id => {
            options_result.resolve(selected_user_id);
        }, () => options_result.reject());
        return options_result.promise;
    }

    function show_create_goal_task_wizard(goal_id, allow_select_assignee = true, dateOptionsFunction = date_options, allow_quick_create = true) {
        target_goal_id = goal_id;
        promise = $q.defer();
        loadedTaskData = {};
        task_to_copy_id = undefined;
        goal_task_data = _.merge({}, DEFAULT_GOAL_TASK_DATA);
        let step1 = main_task_options(goal_task_data, {
            header: `Create new ${customTermFilter('Task')}`,
            save_button: 'Next',
            allow_clone: true,
            allow_quick_create
        });
        let name, description, media_for_avatar, start_date, end_date, notify_when_due, auto_complete_when_due,
            assignee_id, avatar, linkToEvent, createNow, stepsToSave, createSteps = false;
        step1
            .then(res => {
                ({
                    name,
                    description,
                    media_for_avatar,
                    avatar,
                    createNow
                } = res);
                if (createNow) {
                    return { createNow };
                } else {
                    return dateOptionsFunction(loadedTaskData, {
                        header: `Schedule ${customTermFilter('Task')}?`,
                        save_button: 'Next',
                        cancel_button: 'Cancel',
                        message: `Add in start and end dates to schedule your ${customTermFilter('Task')}.`,
                        allow_create_steps: true,  // only from wizard perhaps
                    });
                }
            }, () => promise.reject())
            .then(res => {
                ({
                    start_date,
                    end_date,
                    notify_when_due,
                    auto_complete_when_due,
                    linkToEvent,
                    createNow,
                    createSteps
                } = res);
                if (createNow) {
                    return undefined;
                } else {
                    if (createSteps) {
                        return steps_options();
                    } else {
                        return undefined;
                    }
                }
            }, () => promise.reject())
            .then((_stepsToSave) => {
                if (_stepsToSave && _stepsToSave.length) {
                    stepsToSave = _stepsToSave;
                }
                if (allow_select_assignee) {
                    return assignee_options(undefined, {
                        header: `Select assignee for ${customTermFilter('Task')}?`,
                        save_button: `Create ${customTermFilter('Task')}`,
                        cancel_button: 'Cancel'
                    });
                } else {
                    return undefined;
                }
            }, () => promise.reject())
            .then(res_assignee_id => {
                assignee_id = res_assignee_id;
                promise.resolve({
                    name,
                    description,
                    media_id_for_avatar: media_for_avatar ? media_for_avatar.pk : null,
                    reset_avatar: !media_for_avatar && !avatar,
                    start_date,
                    end_date,
                    notify_when_due,
                    auto_complete_when_due,
                    assignee_id,
                    task_to_copy_id,
                    linkToEvent,
                    steps: stepsToSave,
                });
            }, () => promise.reject());

        return promise.promise;
    }

    function edit_main_options(goal_task) {
        promise = $q.defer();
        goal_task_data = _.merge({}, DEFAULT_GOAL_TASK_DATA, goal_task);
        main_task_options(goal_task_data).then(res => {
            promise.resolve(res);
        });
        return promise.promise;
    }

    function edit_date_options(goal_task) {
        return date_options(goal_task);
    }

    return {
        show_create_goal_task_wizard,
        edit_main_options,
        edit_date_options,
        assignee_options
    };
}

export default goalTaskFactory;
