<template>
    <div class="members-list">
        <div style="float: right" v-if="isAdmin">
            <button class="btn btn-mm btn-default" @click="inviteUsers" v-if="canInviteUsers&&isAdmin"><i
                    class="fa fa-user-plus"></i> Invite Users
            </button>
            <button class="btn btn-mm btn-green" @click="manageAdmins" v-if="editMode&&isAdmin"><i
                    class="fa fa-user-secret"></i> Manage Admins
            </button>
        </div>
        <h3 style="font-size: 24px; margin-bottom: 5px; margin-top: 5px" v-show="admins.length">{{ "Goal"|customTerm }}
            Admins</h3>
        <a :href="member.link" v-for="member in admins" :key="member.id" style="text-decoration: none;">
            <vue-stuff-preview :stuff-item="member"
                               stuff-type="user"
                               :deletable="isAdmin&&editMode"
                               @delete="deleteMember(member)"
                               :animation="true"
                               :online-mark="member.online"
            ></vue-stuff-preview>
        </a>

        <h3 v-show="nonAdmins.length">{{ "Goal"|customTerm }} Participants</h3>
        <a :href="member.link" v-for="member in nonAdmins" :key="member.id" style="text-decoration: none;">
            <vue-stuff-preview :stuff-item="member"
                               stuff-type="user"
                               :deletable="isAdmin&&editMode"
                               @delete="deleteMember(member)"
                               :animation="true"
                               :online-mark="member.online"
            ></vue-stuff-preview>
        </a>
    </div>
</template>

<script>
    import Vue from "vue";
    import {customTermFilter} from "../../../../../vue/filters/CustomTerm";
    import {CurrentUserClass} from "shared/CurrentUser";
    import {DjangoUrls} from 'DjangoUrls';

    const CurrentUser = new CurrentUserClass();

    export default {
        name: "GoalDetailPageMainMembers",
        computed: {
            goal() {
                return this.$store.state.goalDetailPageStore.goal;
            },
            tasks() {
                return this.$store.state.goalDetailPageStore.tasks;
            },
            members() {
                return this.$store.state.goalDetailPageStore.members;
            },
            isAdmin() {
                return this.$store.state.goalDetailPageStore.isAdmin;
            },
            editMode() {
                return this.$store.state.goalDetailPageStore.editMode;
            },
            admins() {
                return this.members.filter(m => m.is_admin);
            },
            nonAdmins() {
                return this.members.filter(m => !m.is_admin);
            },
            canInviteUsers() {
                return Vue.getAngularModule('CurrentUser').has_permission('invite-users-to-join-goals');
            },
        },
        methods: {
            manageAdmins() {
                let do_unadmin = false;
                let new_checked_ids;
                let new_unchecked_ids;
                const selectFactory = Vue.getAngularModule('selectFactory');
                const GoalResource = Vue.getAngularModule('GoalResource');
                const $q = Vue.getAngularModule('$q');
                const $rootScope = Vue.getAngularModule('$rootScope');
                selectFactory
                    .endpoint_selector(DjangoUrls["api.v1:goals-get-admins-for-manage"](this.goal.id), true, {
                        header: 'Manage Admins',
                        info: 'Select or de-select user from list to make them admins',
                        button_yes: 'Save',
                        button_no: 'Cancel',
                        empty: 'You do not have any users to select'
                    })
                    .then(res => {
                        new_checked_ids = res[0];
                        new_unchecked_ids = res[1];
                        $rootScope.show_dimmer();
                        if (new_unchecked_ids.indexOf(CurrentUser.id) !== -1) {
                            do_unadmin = true;
                        }
                        return GoalResource.update_admins(this.goal.id, new_checked_ids, new_unchecked_ids);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        if (do_unadmin) {
                            this.$store.commit('goalDetailPageStore/set_is_admin', false);
                            this.$store.dispatch('goalDetailPageStore/load_tasks');
                        } else {
                            Vue.notifications.success('Updated');
                        }
                        this.$store.commit('goalDetailPageStore/update_members_admin_status', {
                            newAdmins: new_checked_ids,
                            noMoreAdmins: new_unchecked_ids
                        });
                        $rootScope.hide_dimmer();
                    }, err => {
                        $rootScope.hide_dimmer();
                        Vue.notifications.error(err || 'Error');
                    });
            },
            deleteMember(member) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $q = Vue.getAngularModule('$q');
                const GoalResource = Vue.getAngularModule('GoalResource');
                let deletionMode;
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup('Delete Member', `Are you sure you want to delete user '${member.name}' from this ${customTermFilter('Goal')}?`, 'Delete', 'Cancel')
                    .then(y => {
                        const res = $q.defer();
                        const amountOfAssignedTasks = this.tasks.reduce((n, p, i, t) => {
                            if (t[i].assignee && t[i].assignee.id === member.id) {
                                return n + 1;
                            } else {
                                return n;
                            }
                        }, 0);
                        if (amountOfAssignedTasks > 0) {
                            simplePopupFactory.show_popup('Delete Member', `'${member.name}' has ${amountOfAssignedTasks} assigned ${customTermFilter('Tasks')}. would you like to delete these ${customTermFilter('Tasks')} or reset assignee?`, 'Delete', 'Reset assignee')
                                .then(del => {
                                    res.resolve('delete');
                                }, reason => {
                                    if (reason === 'close') {
                                        res.reject();
                                    } else {
                                        res.resolve('reset');
                                    }
                                });
                        } else {
                            res.resolve('reset');// mode does not matter essentially;
                        }
                        return res.promise;
                    }, () => new Promise(()=>{}))
                    .then((resetOrDelete) => {
                        deletionMode = resetOrDelete;
                        $rootScope.show_dimmer();
                        return GoalResource.delete_member(this.goal.id, member.id, deletionMode);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        Vue.notifications.success('Deleted');
                        if (CurrentUser.id === member.id) {
                            this.$emit('userSelfDeleted');
                        }
                        this.$store.commit('goalDetailPageStore/delete_member', {member, mode: deletionMode});
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            inviteUsers() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const selectFactory = Vue.getAngularModule('selectFactory');
                const GoalResource = Vue.getAngularModule('GoalResource');
                const $q = Vue.getAngularModule('$q');
                selectFactory
                    .endpoint_selector(DjangoUrls["api.v1:goals-get-users-for-invite"](this.goal.id), true, {
                        header: 'Invite Users',
                        info: `Select or de-
                        select user
                        from list to invite them to this ${customTermFilter('Goal')}`,
                        button_yes: 'Invite',
                        button_no: 'Cancel',
                        empty: 'You do not have any users to select'
                    })
                    .then(res => {
                        let new_checked_ids = res[0];
                        let new_unchecked_ids = res[1];
                        $rootScope.show_dimmer();
                        return GoalResource.invite_users(this.goal.id, new_checked_ids, new_unchecked_ids);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        Vue.notifications.success('Invited');
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    }).finally($rootScope.hide_dimmer);
            }
        }
    };
</script>

<style scoped>

</style>