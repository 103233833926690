import axios from "axios";

const PREFIX = 'api.v1:goal_filter_settings-';

const goal_filter_settings_resource = {
    get_settings(userId) {
        const url = DjangoUrls[`${PREFIX}get-settings`](userId);
        return axios({
            method: 'GET',
            url,
        });
    },
    set_settings(userId, data) {
        const url = DjangoUrls[`${PREFIX}set-settings`](userId);
        return axios({
            method: 'PUT',
            url,
            data,
        });
    },
};

export default goal_filter_settings_resource;