var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0, "append-to-body": true, backdrop: false },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", {
          attrs: { id: "close_popup" },
          on: { click: _vm.closePopup },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [_vm._v(_vm._s(_vm.header))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "goal-task-steps-popup" }, [
        _c(
          "div",
          { staticClass: "goal-task-steps-popup__container" },
          _vm._l(_vm.steps, function (step, i) {
            return _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12 goal-task-steps-popup__step-fields" },
                [
                  _vm._v(
                    "\n                    Sub-step " + _vm._s(i + 1) + " ("
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.removeStep(step)
                        },
                      },
                    },
                    [_vm._v("Remove")]
                  ),
                  _vm._v(") :\n                    "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: step.name,
                        expression: "step.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      maxlength: "255",
                      placeholder: "Sub-step name",
                    },
                    domProps: { value: step.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(step, "name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !step.description && !step.showDescription,
                          expression:
                            "!step.description&&!step.showDescription",
                        },
                      ],
                      staticClass:
                        "goal-task-steps-popup__step-fields__description-link__container",
                    },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: step.name,
                              expression: "step.name",
                            },
                          ],
                          staticStyle: { "font-size": "13px" },
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.showDescription(step)
                            },
                          },
                        },
                        [_vm._v("+ Add Description")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: step.description,
                        expression: "step.description",
                      },
                      {
                        name: "show",
                        rawName: "v-show",
                        value: step.description || step.showDescription,
                        expression: "step.description||step.showDescription",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      disabled: !step.name,
                      name: "",
                      id: "",
                      rows: "3",
                      placeholder: "Step description",
                      maxlength: "3000",
                    },
                    domProps: { value: step.description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(step, "description", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("hr", { staticClass: "hr10" }),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canAddStep,
                expression: "canAddStep",
              },
            ],
            attrs: { href: "javascript:;" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.addStep.apply(null, arguments)
              },
            },
          },
          [_vm._v("+ Add Sub-step")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm", on: { click: _vm.closePopup } },
          [_vm._v(_vm._s(_vm.cancelButton))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green save-button",
            on: { click: _vm.save },
          },
          [_vm._v("\n            " + _vm._s(_vm.saveButton) + "\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }