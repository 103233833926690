<template>
    <div class="">
        <goal-detail-page-header v-if="initialized"></goal-detail-page-header>
        <div class="">
            <router-view
                    v-if="initialized||error"
                    @userSelfDeleted="userSelfDeleted"
            ></router-view>
        </div>
    </div>
</template>

<script>
    import { DjangoUrls } from 'DjangoUrls';
    import Vue from 'vue';
    import goalDetailPageStore from './store';
    import GoalDetailPageHeader from './GoalDetailPageHeader/GoalDetailPageHeader';
    import { CurrentUserClass } from 'shared/CurrentUser';
    import { GOAL_HEADER_BUS_ACTIONS, goalHeaderBus } from './GoalDetailPageHeader/goalHeaderBus';
    import BackButtonMixin from '../../../../../vue/mixins/BackButtonMixin';
    import { customTermFilter } from '../../../../../vue/filters/CustomTerm';
    import { GoalFactory } from '~/angular/app/projects/goal_settings_factory/goalFactory';
    import { simplePopupFactory } from '~/angular/app/shared/simple_popups/simplePopupFactory';
    import GoalResource from '~/angular/app/projects/project_resource';

    export default {
        name: 'GoalDetailPageMain',
        components: { GoalDetailPageHeader },
        mixins: [BackButtonMixin],
        data() {
            return {
                initialized: false,
                error: false,
            };
        },
        computed: {
            goal() {
                return this.$store.state.goalDetailPageStore.goal;
            },
            defaultBackUrl() {
                return DjangoUrls['projects']();
            }
        },
        methods: {
            $_initGoal() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GoalResource().get_goal_detail_data(this.$route.params.goalId)
                    .then(({ data }) => {
                        this.$store.commit('goalDetailPageStore/set_is_admin', data.is_admin);
                        this.$store.commit('goalDetailPageStore/set_is_member', data.is_member);
                        this.$store.commit('goalDetailPageStore/set_join_request_pending', data.is_join_request_pending);
                        this.$store.commit('goalDetailPageStore/set_goal', data.goal);
                        this.$store.commit('goalDetailPageStore/set_members', data.members);
                        this.$store.commit('goalDetailPageStore/set_current_user', new CurrentUserClass());
                        if (data.is_member) {
                            this.$_fetchTasks();
                        } else {
                            this.$router.replace({
                                name: 'GoalDetailPageMainAbout',
                                query: this.$route.query,
                                hash: this.$route.hash
                            });
                            this.initialized = true;
                            $rootScope.hide_dimmer();
                        }

                    }, err => {
                        if (err.status === 404) {
                            this.$router.replace({ 'name': '404' });
                            this.error = true;
                        }
                        if (err.status === 403) {
                            this.$router.replace({ 'name': '403' });
                            this.error = true;
                        }
                        $rootScope.hide_dimmer();
                    });
            },
            $_fetchTasks() {
                this.$store.dispatch('goalDetailPageStore/load_tasks')
                    .then(() => {
                        this.$nextTick(() => {
                            this.initialized = true;
                        });
                    });
            },
            goBack() {
                this.$_goBack();
            },
            userSelfDeleted() {
                this.goBack();
            },
            editGoal() {
                let resultType;
                const $q = Vue.getAngularModule('$q');
                const gf = new GoalFactory();

                gf.editGoal(this.goal)
                    .then(result => {
                        resultType = result.action;
                        if (resultType === 'delete') {
                            return simplePopupFactory.show_popup(`Delete ${customTermFilter('Goal')}`, `Are you sure you want to delete the ${customTermFilter('Goal')} ${this.goal.name} and all its contents?`, 'Delete', 'Cancel', 'btn-red')
                                .then(y => {
                                    this.$loadingSpinner.show();
                                    return GoalResource().delete_goal(this.goal.id);
                                }, n => {
                                    return $q.defer().promise;
                                });
                        }
                        if (resultType === 'update') {
                            this.$loadingSpinner.show();
                            console.log(result.goalData);
                            return GoalResource().update_main_options(this.goal.id, result.goalData);
                        }
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        if (resultType === 'update') {
                            this.$store.commit('goalDetailPageStore/set_goal', resp.data);
                            Vue.notifications.success('Updated');
                            this.$loadingSpinner.hide();
                        } else {
                            Vue.notifications.success('Deleted');
                            this.goBack();
                        }
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                        this.$loadingSpinner.hide();
                    });
            }
        },
        beforeCreate() {
            this.$store.registerModule('goalDetailPageStore', goalDetailPageStore);
        },
        mounted() {
            this.$_initGoal();
            goalHeaderBus.$on(GOAL_HEADER_BUS_ACTIONS.GO_BACK, this.goBack);
            goalHeaderBus.$on(GOAL_HEADER_BUS_ACTIONS.EDIT_GOAL, this.editGoal);
        },
        beforeDestroy() {
            goalHeaderBus.$off(GOAL_HEADER_BUS_ACTIONS.GO_BACK, this.goBack);
            goalHeaderBus.$off(GOAL_HEADER_BUS_ACTIONS.EDIT_GOAL, this.editGoal);
            this.$store.unregisterModule('goalDetailPageStore');
        },
        metaInfo() {
            const goalTerm = this.$customTerm('Goal');
            const title = `${this.goal ? this.$options.filters.cutName(this.goal.name, 20) + ' - ' : ''}${goalTerm}`;
            return {
                title,
            };
        }
    };
</script>

<style scoped>

</style>
