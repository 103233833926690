<template>
    <div class="">
        <vue-simple-header>
            <span slot="headerTitle">{{ "Goals"|customTerm }} list</span>
            <div class="" slot="panelRight">
                <vue-search-input @search="onSearch" @cancel="onSearchCancel" :min-letters-count="2"></vue-search-input>
            </div>
        </vue-simple-header>
        <my-stuff-list-my-item
                v-show="!query"
                :router-navigation="true"
                :name="myGoalsName"
                :image-url="icon"
                :link="myGoalsHref"
        ></my-stuff-list-my-item>
        <div class="row" v-show="results.length">
            <div class="col-sm-12 list_header" style="font-size: 21px; font-weight: bolder;">
                {{ "Goals"|customTerm }} I manage ({{totalCount}})
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <users-whose-goals-i-manage-page-user :user="user" v-for="user in results"
                                                      :key="user.id"></users-whose-goals-i-manage-page-user>
            </div>
        </div>
        <div class="row text-center" v-show="allLoaded&&results.length===0">
            <div class="col-sm-12">
                <span style="font-size: 20px">No results...</span>
            </div>
        </div>
        <div v-infinite-scroll="loadMore"></div>
    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import InfiniteScrollMixin from "../../../../../vue/mixins/InfiniteScrollMixin";
    import UsersWhoseGoalsIManagePageUser from './UsersWhoseGoalsIManagePageUser.vue';
    import Vue from 'vue';
    import {CurrentUserClass} from "shared/CurrentUser";
    import MyStuffListMyItem from "../../../../../vue/common_components/MyStuffListMyItem";
    import {DASHBOARD_ICONS, DASHBOARD_ITEMS} from "../../../dashboard/dashboard";

    const CurrentUser = new CurrentUserClass();

    export default {
        name: "UsersWhoseGoalsIManagePage",
        mixins: [InfiniteScrollMixin],
        components: {MyStuffListMyItem, UsersWhoseGoalsIManagePageUser},
        computed: {
            myGoalsName(){
              return `My ${this.$customTerm('Goals')}`
            },
            myGoalsHref() {
                return DjangoUrls['projects']();
            },
            icon() {
                return DASHBOARD_ICONS[DASHBOARD_ITEMS.GOALS];
            }
        },
        methods: {
            getListEndpoint() {
                const GoalsListResource = Vue.getAngularModule('GoalsListResource');
                return GoalsListResource.get_goals_i_manage;
            },
            getListEndpointArgs() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return [CurrentUser.id];
            }
        }
    };
</script>

<style scoped>

</style>