var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goal-page-header" },
    [
      _c("div", { staticClass: "goal-page-header__avatar-part" }, [
        _c("div", {
          staticClass: "goal-page-header__avatar-part__avatar",
          style: _vm.avatarStyle,
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "goal-page-header__title-mobile" },
          [_c("goal-detail-page-header-title")],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "goal-page-header__content-part" },
        [
          _c("div", {}, [
            _c(
              "div",
              { staticClass: "goal-page-header__title-desktop" },
              [_c("goal-detail-page-header-title")],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "goal-page-header__content-part__description" },
            [
              _vm._v("\n            " + _vm._s(_vm.goalDescription) + " "),
              _vm.isDescriptionLargerThenPossible
                ? _c(
                    "span",
                    {
                      staticClass:
                        "goal-page-header__content-part__description__show-all",
                      on: { click: _vm.showWholeDescription },
                    },
                    [_vm._v("show all")]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "goal-page-header__content-part__buttons" },
            [
              _c("goal-detail-page-header-buttons-panel", {
                attrs: { "filter-allowed": _vm.filterAllowed },
                on: { filterClicked: _vm.showFilterSettings },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("simple-progress-bar", {
            staticClass: "goal-page-header__simple-progress-bar",
            attrs: {
              size: "medium",
              val: _vm.completedPercentage,
              "bar-color": "#51ac51",
              "bg-color": "#c7c7c7",
              text: _vm.completedPercentage + "%",
              "text-position": "top",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isDescriptionLargerThenPossible
        ? _c(
            "modal",
            {
              attrs: {
                title: "Goal Description",
                size: "lg",
                transition: 0,
                footer: false,
              },
              model: {
                value: _vm.descriptionOpened,
                callback: function ($$v) {
                  _vm.descriptionOpened = $$v
                },
                expression: "descriptionOpened",
              },
            },
            [
              _c(
                "p",
                { staticClass: "goal-page-header__content-part__description" },
                [_vm._v(_vm._s(_vm.goal.description))]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.filterAllowed
        ? _c("goal-tasks-filter-popup", { ref: "filterPopup" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }