var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("goal-tags-filter-panel"),
      _vm._v(" "),
      _c("goal-create-panel", { attrs: { "allow-filter": false } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "goal-tasks-list" },
        [
          _vm._l(_vm.visibleTasks, function (task) {
            return _c("goal-task", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTaskFilteredByTags(task),
                  expression: "isTaskFilteredByTags(task)",
                },
              ],
              key: task.id + "" + (task.children ? task.children.length : ""),
              attrs: {
                "initial-task": task,
                layout: _vm.goalTaskLayout,
                "is-completed-mark-editable": _vm.isTaskCompletedMarkEditable,
                "is-editable": _vm.isTaskEditable(task),
                "is-assignee-editable": _vm.isTaskAssigneeEditable(task),
                "allow-multiple-assign": _vm.isAdmin && !_vm.filterIsActive,
                "allow-drag": _vm.isAdmin && !_vm.filterIsActive,
                "delete-forbidden": _vm.filterIsActive,
                "is-admin": _vm.isAdmin,
                "edit-mode": _vm.isTaskInEditMode(task),
                "tags-in-project": _vm.tagsInProject,
                availableCompletionOptions: _vm.availableCompletionOptions,
                goal: _vm.goal,
                "router-navigation": true,
              },
            })
          }),
          _vm._v(" "),
          !_vm.visibleTasks.length
            ? _c("div", { staticClass: "alert alert-info" }, [
                _vm._v("There has been nothing added yet"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.visibleTasks.length < _vm.flattenedTasks.length &&
      !_vm.isAdmin &&
      !_vm.showOtherUsersTasks &&
      !_vm.assigneesInFilter.length
        ? _c("div", {}, [
            _c(
              "strong",
              {
                staticClass: "pointer",
                on: { click: _vm.expandOtherUsersTasks },
              },
              [_vm._v("Show Tasks assigned to other users.")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showOtherUsersTasks && !_vm.assigneesInFilter.length
        ? _c("div", {}, [
            _c(
              "strong",
              {
                staticClass: "pointer",
                on: { click: _vm.hideOtherUsersTasks },
              },
              [_vm._v("Hide Tasks assigned to other users.")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }