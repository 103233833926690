<template>
    <div class="">
        <join-request
                v-if="showJoinRequestPanel"
                :name="goalTerm"
                :isRequestPending="joinRequestPending"
                @join="sendJoinRequest"
        ></join-request>
        <div class="row">
            <div class="col-sm-12">
                <h3>About {{ 'Goal' |customTerm }}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <story-list
                        :story-resource="storyResource"
                        :target-id="$route.params.goalId"
                        :edit-mode="editMode&&isAdmin"
                        :allow-add-content="isAdmin"
                >
                    <div class="alert-info alert" slot="empty">There has been nothing added to this Goal's story yet
                    </div>
                </story-list>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import JoinRequest from "../../../../../vue/common_components/JoinRequest";
    import {customTermFilter} from "../../../../../vue/filters/CustomTerm";
    import {CurrentUserClass} from "shared/CurrentUser";
    import StoryList from "shared/story/StoryList";


    export default {
        name: "GoalDetailPageMainAbout.vue",
        components: {StoryList, JoinRequest},
        data() {
            return {
                initialized: false,
            };
        },
        computed: {
            goalTerm() {
                return customTermFilter('Goal');
            },
            showJoinRequestPanel() {
                return !this.isMember && new CurrentUserClass().has_permission('join-projects');
            },
            editMode() {
                return this.$store.state.goalDetailPageStore.editMode;
            },
            isAdmin() {
                return this.$store.state.goalDetailPageStore.isAdmin;
            },
            isMember() {
                return this.$store.state.goalDetailPageStore.isMember;
            },
            joinRequestPending() {
                return this.$store.state.goalDetailPageStore.joinRequestPending;
            },
            storyResource() {
                return Vue.getAngularModule('GoalStoryResource');
            }
        },
        methods: {
            sendJoinRequest() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GoalResource = Vue.getAngularModule('GoalResource');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                $rootScope.show_dimmer();
                GoalResource.join_goal(this.$route.params.goalId)
                    .then(resp => {
                        let status = resp.data.status; // 'joined' or 'wait_confirmation'
                        if (status === 'joined') {
                            Vue.notifications.success('Joined');
                            document.location.reload();
                        }
                        if (status === 'wait_confirmation') {
                            $rootScope.hide_dimmer();
                            this.$store.commit('goalDetailPageStore/set_join_request_pending', true);
                            simplePopupFactory.show_popup('Join request sent.', `You will join ${customTermFilter('Goal')} after approval. We will notify you.`, 'OK');
                        }

                    }, err => {
                        Vue.notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            },
        },
        beforeDestroy() {
        },
        mounted() {
        },
        watch: {
        },
    };
</script>

<style scoped>

</style>