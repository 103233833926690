var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.task.has_steps,
          expression: "!task.has_steps",
        },
      ],
      ref: "complete-checkbox-desktop",
      staticClass: "inline-block",
    },
    [
      _vm.completable
        ? _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.tooltip,
                expression: "tooltip",
              },
            ],
            staticClass: "fa fa-check-square-o pointer",
            class: {
              "color-grass-green": _vm.task.completed,
              "color-tag-gray": !_vm.task.completed,
            },
            staticStyle: { "font-size": "48px" },
            attrs: {
              tabindex: "0",
              "aria-label": "Complete Mark",
              role: "checkbox",
              "aria-checked": _vm.task.completed ? "true" : "false",
            },
            on: {
              click: _vm.onCompleteCheckboxClicked,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onCompleteCheckboxClicked.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("goal-task-complete-dropdown", { ref: "complete-dropdown" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }