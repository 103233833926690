var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm._v(
            "\n            Are you sure you want to hide this " +
              _vm._s(_vm._f("customTerm")("Goal")) +
              "?\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.forUser
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "alert alert-info" }, [
                _c("div", [
                  _c("strong", [_vm._v("Attention")]),
                  _vm._v(
                    "! You are managing " +
                      _vm._s(_vm._f("customTerm")("Goals")) +
                      " for\n                    "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.forUser.name))]),
                  _vm._v(".\n                "),
                ]),
                _vm._v(" "),
                _vm.canArchiveForAll
                  ? _c("div", { staticClass: "margin-10-top" }, [
                      _vm._v(
                        "\n                    Hiding this " +
                          _vm._s(_vm._f("customTerm")("Goal")) +
                          " will make it hidden to all users who currently have\n                    access.\n                    "
                      ),
                      _c("div", { staticClass: "margin-10-top" }, [
                        _vm._v(
                          "\n                        Alternatively, use the toggle below to hide this " +
                            _vm._s(_vm._f("customTerm")("Goal")) +
                            " for "
                        ),
                        _c("strong", [_vm._v(_vm._s(_vm.forUser.name))]),
                        _vm._v(" only.\n                    "),
                      ]),
                    ])
                  : _c("div", { staticClass: "margin-10-top" }, [
                      _vm._v(
                        "\n                    Hiding this " +
                          _vm._s(_vm._f("customTerm")("Goal")) +
                          " will make it hidden only for "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.forUser.name))]),
                      _vm._v(".\n                "),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "margin-10-top" }, [
                  _vm._v(
                    "To view hidden goals, select 'Show hidden' in the filter settings. You\n                    can unhide a goal by clicking 'Unhide'.\n                "
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.forUser && _vm.canArchiveForAll
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "alert alert-info" }, [
                _c("div", [
                  _c("strong", [_vm._v("Attention")]),
                  _vm._v(
                    "! Hiding this " +
                      _vm._s(_vm._f("customTerm")("Goal")) +
                      " will make it hidden to all\n                    users who currently have access.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "margin-10-top" }, [
                  _vm._v(
                    "You can also choose to hide this " +
                      _vm._s(_vm._f("customTerm")("Goal")) +
                      " only for you\n                    using the toggle below.\n                "
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canArchiveForAll
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("vue-switch-with-label", {
                  attrs: { label: _vm.finishGoalLabel, name: "finishGoal" },
                  model: {
                    value: _vm.onlyForTarget,
                    callback: function ($$v) {
                      _vm.onlyForTarget = $$v
                    },
                    expression: "onlyForTarget",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-default save-button",
                on: { click: _vm.close },
              },
              [_vm._v("\n                    Cancel\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green save-button",
                on: { click: _vm.finish },
              },
              [_vm._v("\n                    Hide\n                ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }