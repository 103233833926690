<template>
    <div>
        <timeline-container
                :width="width"
        ></timeline-container>
    </div>

</template>

<script>
    import moment from "moment";
    import TimelineContainer from "./TimelineContainer";
    import timeline from "./timeline";

    export default {
        name: "GoalDetailPageMainTimeline",
        components: {TimelineContainer},
        data() {
            return {
                width: document.getElementById('content').offsetWidth,
            };
        },
        beforeCreate() {
            this.$store.registerModule('timeline', timeline);

            const TIME_ZONE = "Europe/London";
            const TODAY = moment.tz(TIME_ZONE).toDate();
            this.$store.commit('timeline/set_current_date', TODAY);
        },
        methods:{
          onWindowResize(){
              this.width = this.$el.offsetWidth;
          }
        },
        mounted() {
            window.addEventListener('resize', this.onWindowResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onWindowResize);
            this.$store.unregisterModule('timeline');
        }
    };
</script>

<style scoped>

</style>
