<template>
    <div class="text-center member" :style="memberRowStyle">
        <img :src="member.avatar" alt="" class="img-circle" width="60" height="60" v-tooltip="member.name">
    </div>
</template>

<script>

    export default {
        name: "Member",
        props: {
            member: {
                type: Object
            }
        },
        computed: {
            rowCounts() {
                return this.$store.getters['timeline/rowCounts'];
            },
            memberRowStyle() {
                return {height: `${this.$store.state.timeline.rowHeight * this.rowCounts[this.member.pk]}px`};
            }
        }
    };
</script>

<style scoped lang="scss">
  .member {
  }
</style>