var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goal-category" },
    [
      _vm.category.name !== "Default"
        ? _c("div", { staticClass: "goal-category__header" }, [
            _c("div", {
              staticClass: "goal-category__header-avatar",
              style: _vm.categoryAvatar,
            }),
            _vm._v(" "),
            _vm.category.audio_description
              ? _c(
                  "div",
                  { staticClass: "goal-category__header-audio-description" },
                  [
                    _c("audio-toggle", {
                      attrs: {
                        "audio-caption": _vm.category.audio_description,
                        "fa-class": "fal",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.category.video_description
              ? _c(
                  "div",
                  {
                    staticClass: "goal-category__header-video-description",
                    style: _vm.videoDescriptionStyle,
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      "aria-label": "Play Video Description",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.playVideoDescription(
                          _vm.category.video_description
                        )
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.playVideoDescription(
                          _vm.category.video_description
                        )
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fal fa-play color-blue pointer",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "goal-category__header-context" }, [
              _c("div", { staticClass: "goal-category__header-title" }, [
                _c("span", [_vm._v(_vm._s(_vm.category.name))]),
                _vm._v(" "),
                _vm.category.is_editable
                  ? _c("i", {
                      staticClass: "fal fa-pencil pointer color-tag-gray-dark",
                      attrs: {
                        tabindex: "0",
                        role: "button",
                        "aria-label": "Edit category",
                      },
                      on: {
                        click: _vm.editCategory,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.editCategory.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.allowCreateGoal
                ? _c(
                    "div",
                    { staticClass: "goal-category__header-create-goal-label" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "pointer",
                          attrs: { tabindex: "0", role: "button" },
                          on: {
                            click: _vm.createGoalInCategory,
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.createGoalInCategory.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.createNewGoalTooltip,
                                expression: "createNewGoalTooltip",
                              },
                            ],
                            staticClass: "fa fa-plus-circle",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.category.goals, function (goal) {
        return _c("goals-list-item", {
          key: goal.id,
          attrs: {
            goal: goal,
            "allow-archive-goal": _vm.allowArchiveGoal,
            "router-navigation": _vm.routerNavigation,
          },
          on: {
            archiveGoal: _vm.archiveGoal,
            unArchiveGoal: _vm.unArchiveGoal,
          },
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.allowLoadMore,
              expression: "allowLoadMore",
            },
          ],
          staticClass: "text-center pointer",
          on: { click: _vm.loadMore },
        },
        [_c("strong", [_vm._v("Load More...")])]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }