/**
 * Created by orion on 09.05.17.
 */

import angular from 'angular';


//pages
import NewGoalsController from '../goals/mainPage/NewGoalsController';
//resources
import GoalsListResource from "./project_list_resource";
import GoalResource from './project_resource';
import GoalTaskResource from './project_task_resource';
import AttachmentsResourceFactory from '../shared/attachments/attachments_resource_factory';
import TasksListResource from "./tasks_list_resource";
//directives

//factories
import linkToGoalFactory from './linkToGoalFactory';
import goalTaskFactory from './GoalDetailPage/goal_tasks/goal_task_options/goal_task_factory';

import LikeStoryResourceFactory from '../shared/story/resource_factory';
import CommentsInfiniteScrollResourceFactory from "../shared/comments/resource_infinite_scroll_factory";
import GoalTaskTagsResource from "./project_task_tags_resource";
import GoalTaskDetailResource from "./task_detail_resource";




angular.module('mmApp')
    .controller('NewGoalsController', NewGoalsController)

    .factory('ActionComments', CommentsInfiniteScrollResourceFactory('task_comments'))
    .factory('GoalResource', GoalResource)
    .factory('GoalsListResource', GoalsListResource)
    .factory('GoalTaskResource', GoalTaskResource)
    .factory('GoalTaskTagsResource', GoalTaskTagsResource)
    .factory('TasksListResource', TasksListResource)
    .factory('GoalTaskDetailResource', GoalTaskDetailResource)
    .factory('GoalAttachmentsResource', AttachmentsResourceFactory('goal_attachments'))
    .factory('TaskAttachmentsResource', AttachmentsResourceFactory('task_attachments'))
    .factory('GoalStoryResource', LikeStoryResourceFactory('goal_story'))
    .factory('goalTaskFactory', goalTaskFactory)
    .factory('linkToGoalFactory', linkToGoalFactory)
;


export default 'mmApp-projects';
