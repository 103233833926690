import ColumnUsersSection from './ColumnUsersSectionFunc.js';
import moment from 'moment';

const API_DATE_FORMAT = "YYYY-MM-DD";

export default {
    name: 'Column',
    components: {
        ColumnUsersSection
    },
    functional: true,
    props: {
        width: {
            type: Number
        },
        date: {
            type: Date
        }
    },
    render(createElement, context) {
        const wrappedDate = moment(context.props.date);
        const state = context.parent.$store.state.timeline;
        const isToday = wrappedDate.format(API_DATE_FORMAT) === moment(context.parent.$store.getters['timeline/currentDate']).format(API_DATE_FORMAT);
        let bottomLabel = (() => {
            if (context.parent.$store.getters['timeline/scale'] === 'weeks') {
                return wrappedDate.add(6, 'days').format('D');
            } else {
                return wrappedDate.format('ddd');
            }
        })();
        let upperLabel = (() => {
            if (context.parent.$store.getters['timeline/scale'] === 'weeks') {
                if (wrappedDate.date() <= 7) {
                    return wrappedDate.format('MMMM');
                }
            } else {
                if (wrappedDate.date() === 1) {
                    return wrappedDate.format('MMMM');
                }
            }
        })();


        const columnStyle = {
            width: `${context.props.width}px`,
            height: `${context.parent.$store.getters['timeline/totalRowCount'] * state.rowHeight + 80}px`,
            position: 'relative',
            'vertical-align': 'top',
            'border-left': 'solid 1px rgba(52, 52, 52, 0.17)'
        };

        const footerRed = '#CF583A';

        const children = [];
        if (isToday) {
            children.push(createElement('div', {
                class: {
                    'today-line': true
                },
                style: {
                    width: 0,
                    height: 0,
                    'border-style': 'solid',
                    'border-width': '10px 5px 0 5px',
                    'border-color': footerRed + ' transparent transparent transparent',

                    position: 'absolute',
                    left: 'calc(50% - 5px)',
                    'z-index': '5',
                    top: 0,
                }
            }));
            children.push(createElement('div', {
                class: {
                    'today-triangle': true
                },
                style: {
                    'position': 'absolute',
                    'left': 'calc(50% - 1px)',
                    'top': 0,
                    'width': 0,
                    'height': '100%',
                    'border-left': 'dashed 2px ' + footerRed,
                    'z-index': '4',
                }
            }));
        }

        children.push(createElement('div', {
                style: {
                    'height': '60px',
                    'text-align': 'center',
                    'font-weight': isToday ? 'bold' : 'inherit'
                }
            }, [
                createElement('div', {
                    class: {
                        'upper-label': true,
                        'color-gray': true
                    },
                    style: {
                        'margin-left': `${context.props.width / 2 - 7}px`,
                        display: 'block',
                        height: '20px',
                        'font-size': '18px'
                    }
                }, [upperLabel]),
                createElement('div', {}, [context.props.date.getDate()]),
                createElement('div', {class: {'color-gray': true}}, [bottomLabel]),
            ])
        );

        for (let member of context.parent.$store.getters['timeline/membersForTimeline']) {
            children.push(createElement(ColumnUsersSection, {
                props: {
                    member,
                    date: context.props.date
                }
            }));
        }

        return createElement('div', {
            class: {
                'inline-block': true,
                'column': true
            },
            style: columnStyle
        }, children);
    }
};
