import Step from './Step.vue';

let dateAsString = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return '' + yyyy + mm + dd;
};

export default {
    name: "ColumnUsersSectionRow",
    functional: true,
    props: {
        member: {
            type: Object
        },
        index: {
            type: Number
        },
        date: {
            type: Date
        },
    },
    render(createElement, context) {
        const taskToAppend = context.parent.$store.getters['timeline/tasksStartHashTable'][`${context.props.member.id}_${dateAsString(context.props.date)}_${context.props.index}`];
        const children = [];
        if (taskToAppend) {
            children.push(createElement(Step, {props: {task: taskToAppend.task, direction: taskToAppend.direction}}));
        }
        return createElement('div',
            {
                class: {'section-row': true},
                style: {height: context.parent.$store.state.timeline.rowHeight + 'px'}
            }, children);
    }
};