var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _vm.showJoinRequestPanel
        ? _c("join-request", {
            attrs: {
              name: _vm.goalTerm,
              isRequestPending: _vm.joinRequestPending,
            },
            on: { join: _vm.sendJoinRequest },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("h3", [_vm._v("About " + _vm._s(_vm._f("customTerm")("Goal")))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "story-list",
              {
                attrs: {
                  "story-resource": _vm.storyResource,
                  "target-id": _vm.$route.params.goalId,
                  "edit-mode": _vm.editMode && _vm.isAdmin,
                  "allow-add-content": _vm.isAdmin,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "alert-info alert",
                    attrs: { slot: "empty" },
                    slot: "empty",
                  },
                  [
                    _vm._v(
                      "There has been nothing added to this Goal's story yet\n                "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }