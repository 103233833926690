<template>
    <div class="goal_tasks_list__container">
        <div style="float: right">
            <button class="btn btn-mm btn-default" @click="createReport"><i
                    class="fa fa-plus"></i> Create Export
            </button>
        </div>
        <h3 style="font-size: 24px; margin-bottom: 5px; margin-top: 5px">{{ 'Goal'|customTerm }} Exports</h3>

        <div class="row margin-15-top">
            <div class="col-sm-12">
                <div class="reports_table_outer_container">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>#ID</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Created at</th>
                            <th>Link</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="report in reports">
                            <td>#{{ report.id }}</td>
                            <td>{{ report.name }}</td>
                            <td>{{ getStatusRepresentation(report) }}</td>
                            <td>{{ report.completed_time }}</td>
                            <td>
                                <a :href="report.link" v-if="report.link">Download</a>
                                <span v-else> - </span>
                            </td>
                            <td class="text-center">
                                <i class="fas fa-trash pointer color-footer-red"
                                   tabindex="0"
                                   style="font-size: 18px;"
                                   role="button"
                                   aria-label="Delete Report"
                                   v-tooltip="'Delete'"
                                   @click="deleteReport(report)"
                                   @keydown.enter="deleteReport(report)"
                                ></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="alert alert-info" v-if="!loading&&reports.length===0">
                    Nothing to show
                </div>
            </div>
        </div>
        <div class="row" v-if="totalPage>1">
            <div class="col-sm-12 text-right">
                <pagination v-model="currentPage" :total-page="totalPage" @change="changePage"/>
            </div>
        </div>


    </div>
</template>

<script>
    import Vue from 'vue';
    import { simplePopupFactory } from '~/angular/app/shared/simple_popups/simplePopupFactory';
    import GoalReportsResource from '~/angular/app/goals/goals_reports_resource';
    import moment from 'moment';

    const STATUSES_VISUAL = {
        3: 'Created',
        0: 'Queued',
        1: 'Processing',
        2: 'Completed',
        4: 'Error',
        5: 'No results',
    };

    const TIME_ZONE = 'Europe/London';
    const TODAY = moment.tz(TIME_ZONE).toDate();
    const VISUAL_DATE_FORMAT = 'DD/MM/YYYY';
    const REPORT_NAME_DATE_FORMAT = 'YYYYMMDD';

    const RESULTS_PER_PAGE = 10;
    export default {
        name: 'GoalDetailPageExports',
        props: {
            goalId: [Number, String],
        },
        data() {
            return {
                reports: [],
                loading: false,
                currentPage: 1,
                totalPage: 1,

                updatingReports: false,
            };
        },
        computed:{
            $_reportsIdsToCheckStatus() {
                return this.reports.filter(r => !r.ready).map(r => r.id);
            },
        },
        mounted() {
            this.fetchReports();
            this.$_enableStatusUpdater();

        },
        beforeDestroy() {
            this.$_disableStatusUpdater();
        },
        methods: {
            $_updateReportsStatus() {
                if (this.updatingReports) {
                    return;
                }
                if (this.$_reportsIdsToCheckStatus.length) {
                    this.updatingReports = true;
                    GoalReportsResource.update_reports_status(this.goalId, this.$_reportsIdsToCheckStatus)
                        .then(resp => {
                            for (const report of resp.data) {
                                const reportToUpdate = this.reports.filter(r => r.id === report.id)[0];
                                if (reportToUpdate) {
                                    Vue.set(this.reports, this.reports.indexOf(reportToUpdate), report);
                                }
                            }
                        }, err => {
                        })
                        .finally(() => {
                            this.updatingReports = false;
                        });
                }
            },
            $_enableStatusUpdater() {
                this.updaterInterval = setInterval(this.$_updateReportsStatus, 2500);
            },
            $_disableStatusUpdater() {
                if (this.updaterInterval !== undefined) {
                    clearInterval(this.updaterInterval);
                }
            },
            getStatusRepresentation(report) {
                return STATUSES_VISUAL[report.status];
            },
            $_suggestName() {
                let name = `Export `;
                name += ` ${moment(TODAY).format(REPORT_NAME_DATE_FORMAT)}`;
                return name.slice(0, 60);
            },
            async fetchReports() {
                this.$loadingSpinner.show('reports');
                try {
                    const { data } = await GoalReportsResource.get_reports(this.goalId, {
                        page: this.currentPage,
                        size: RESULTS_PER_PAGE
                    });
                    this.reports = data.results;
                    this.totalPage = Math.ceil(data.count / RESULTS_PER_PAGE);
                } catch (e) {
                    this.$notifications.error(e);
                }
                this.$loadingSpinner.hide('reports');
                this.$loadingSpinner.hide();
            },
            async changePage() {
                await this.fetchReports();
            },
            async deleteReport(report) {
                const confirmed = await simplePopupFactory.show_popup('Delete Export', 'Are you sure you want to delete this export?', 'Delete', 'Cancel', 'btn-red').catch(_.noop);
                if (!confirmed) {
                    return;
                }
                this.$loadingSpinner.show();
                let restReportsCount;
                try {
                    const { data } = await GoalReportsResource.delete_report(this.goalId, report.id);
                    restReportsCount = data.total_count;
                } catch (e) {
                    this.$notifications.error(e);
                    this.$loadingSpinner.hide();
                    return;
                }
                const reportIndex = this.reports.indexOf(report);
                this.reports.splice(reportIndex, 1);
                const totalPage = Math.ceil(restReportsCount / RESULTS_PER_PAGE);
                if (this.currentPage > totalPage) {
                    this.currentPage = totalPage;
                } else if (this.currentPage < totalPage) {
                    try {
                        await this.fetchReports();
                    } catch (e) {
                    }
                }
                this.$loadingSpinner.hide();
            },
            async createReport() {
                const name = await simplePopupFactory.simple_text_input_popup('Create Export', 'Export name:', 'Create', undefined, this.$_suggestName(), 255);
                if (!name) {
                    return;
                }

                this.$loadingSpinner.show();
                try {
                    const { data } = await GoalReportsResource.create_report(this.goalId, {
                        name,
                    });
                    this.reports.unshift(data.report);
                    this.totalPage = Math.ceil(data.total_count / RESULTS_PER_PAGE);

                    if ((this.reports.length > RESULTS_PER_PAGE) && (this.currentPage === 1)) {
                        this.reports.pop();
                    }
                    this.$notifications.success('Created');
                } catch (e) {
                    console.log(e);
                    this.$notifications.error(e);
                }
                this.$loadingSpinner.hide();
            },
        },
    };
</script>


<style scoped lang="scss">
.reports_table_outer_container{
  overflow: auto;
}
</style>
