var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "task-steps__task__container" }, [
    _c(
      "div",
      {
        staticClass: "task-steps__task",
        class: {
          completed: _vm.step.completed,
          "not-achieved": _vm.isCompletedButNotAchieved,
        },
        style: _vm.stepStyle,
      },
      [
        _c("i", {
          ref: "complete-checkbox-desktop",
          staticClass:
            "far fa-check-square task-steps__task__checkbox vertical-middle",
          class: { pointer: _vm.completable },
          attrs: {
            role: "checkbox",
            "aria-checked": _vm.step.completed ? "true" : "false",
            tabindex: "0",
            "aria-label": _vm.step.name,
          },
          on: {
            click: _vm.onCompleteCheckboxClicked,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.onCompleteCheckboxClicked.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "task-steps__task__name vertical-middle" }, [
          _vm._v(
            _vm._s(_vm.index ? _vm.index + ". " : "") +
              _vm._s(_vm._f("cutName")(_vm.step.name, 450))
          ),
        ]),
        _vm._v(" "),
        _vm.allowEdit
          ? _c(
              "span",
              {
                staticClass:
                  "pointer vertical-middle task-steps__task__remove-label color-black",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.editStep.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-pencil pointer" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step.completed
          ? _c("task-main-info-completion-data", {
              attrs: { "completable-instance": _vm.step },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("goal-task-complete-dropdown", { ref: "complete-dropdown" }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.step.description
      ? _c("div", {}, [
          _c("i", [_vm._v(_vm._s(_vm.stepDescription))]),
          _vm._v(" "),
          !_vm.fullDescriptionRevealed && _vm.descriptionIsTooLarge
            ? _c(
                "i",
                {
                  staticClass: "pointer",
                  on: { click: _vm.showFullDescription },
                },
                [_c("strong", [_vm._v("Show\n        more")])]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }