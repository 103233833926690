var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "members-list" },
    [
      _vm.isAdmin
        ? _c("div", { staticStyle: { float: "right" } }, [
            _vm.canInviteUsers && _vm.isAdmin
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-default",
                    on: { click: _vm.inviteUsers },
                  },
                  [
                    _c("i", { staticClass: "fa fa-user-plus" }),
                    _vm._v(" Invite Users\n        "),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editMode && _vm.isAdmin
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    on: { click: _vm.manageAdmins },
                  },
                  [
                    _c("i", { staticClass: "fa fa-user-secret" }),
                    _vm._v(" Manage Admins\n        "),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h3",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.admins.length,
              expression: "admins.length",
            },
          ],
          staticStyle: {
            "font-size": "24px",
            "margin-bottom": "5px",
            "margin-top": "5px",
          },
        },
        [_vm._v(_vm._s(_vm._f("customTerm")("Goal")) + "\n        Admins")]
      ),
      _vm._v(" "),
      _vm._l(_vm.admins, function (member) {
        return _c(
          "a",
          {
            key: member.id,
            staticStyle: { "text-decoration": "none" },
            attrs: { href: member.link },
          },
          [
            _c("vue-stuff-preview", {
              attrs: {
                "stuff-item": member,
                "stuff-type": "user",
                deletable: _vm.isAdmin && _vm.editMode,
                animation: true,
                "online-mark": member.online,
              },
              on: {
                delete: function ($event) {
                  return _vm.deleteMember(member)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "h3",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nonAdmins.length,
              expression: "nonAdmins.length",
            },
          ],
        },
        [_vm._v(_vm._s(_vm._f("customTerm")("Goal")) + " Participants")]
      ),
      _vm._v(" "),
      _vm._l(_vm.nonAdmins, function (member) {
        return _c(
          "a",
          {
            key: member.id,
            staticStyle: { "text-decoration": "none" },
            attrs: { href: member.link },
          },
          [
            _c("vue-stuff-preview", {
              attrs: {
                "stuff-item": member,
                "stuff-type": "user",
                deletable: _vm.isAdmin && _vm.editMode,
                animation: true,
                "online-mark": member.online,
              },
              on: {
                delete: function ($event) {
                  return _vm.deleteMember(member)
                },
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }