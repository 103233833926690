var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12 text-center" }, [
          _c(
            "div",
            {
              staticClass: "create-category-popup__avatar-container",
              style: _vm.avatarStyle,
              attrs: {
                tabindex: "0",
                role: "button",
                "aria-label": "Set avatar",
              },
              on: {
                click: _vm.setAvatar,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.setAvatar.apply(null, arguments)
                },
              },
            },
            [
              !_vm.avatarReset && (_vm.avatarImage || _vm.avatarBytes)
                ? _c(
                    "div",
                    {
                      staticClass: "create-category-popup__reset-avatar-button",
                      attrs: {
                        tabindex: "0",
                        role: "button",
                        "aria-label": "Reset avatar",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.resetAvatar.apply(null, arguments)
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.resetAvatar.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("small", [_vm._v("Click to update picture")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("strong", [_vm._v("Category Name:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              ref: "categoryName",
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "Give your Category a name",
                maxlength: "63",
              },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-4" },
          [
            _vm.audioDescription
              ? _c("media-preview", {
                  attrs: {
                    item: _vm.audioDescription,
                    "no-border": "",
                    "allow-delete": "",
                    "show-name": "",
                  },
                  on: { onDelete: _vm.resetAudio },
                })
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-blue text-left",
                    staticStyle: { width: "100%" },
                    attrs: { tabindex: "0" },
                    on: { click: _vm.addAudio },
                  },
                  [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "vertical-middle",
                          staticStyle: {
                            "text-align": "center",
                            display: "inline-block",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "far fa-volume-up create-category-popup__media-button-icon",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "create-category-popup__media-button-label",
                        },
                        [_vm._v(" + Add Audio")]
                      ),
                    ]),
                  ]
                ),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.audioDescription
          ? _c("div", { staticClass: "col-sm-8" }, [
              _vm._v("\n            Add an audio description\n        "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c(
          "div",
          { staticClass: "col-sm-4" },
          [
            _vm.videoDescription
              ? _c("media-preview", {
                  attrs: {
                    item: _vm.videoDescription,
                    "no-border": "",
                    "allow-delete": "",
                    "show-name": "",
                  },
                  on: { onDelete: _vm.resetVideo },
                })
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-blue text-left",
                    staticStyle: { width: "100%" },
                    attrs: { tabindex: "0" },
                    on: { click: _vm.addVideo },
                  },
                  [
                    _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "vertical-middle",
                          staticStyle: {
                            "text-align": "center",
                            display: "inline-block",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fa fa-video-camera create-category-popup__media-button-icon",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "create-category-popup__media-button-label",
                        },
                        [_vm._v(" + Add Video")]
                      ),
                    ]),
                  ]
                ),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.videoDescription
          ? _c("div", { staticClass: "col-sm-8" }, [
              _vm._v("\n            Add an introduction video\n        "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "col-xs-4 text-left" }, [
            _vm.editMode
              ? _c(
                  "button",
                  { staticClass: "btn btn-mm btn-red", on: { click: _vm.del } },
                  [_vm._v("Delete")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-8 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-default",
                on: { click: _vm.close },
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green",
                attrs: { disabled: !_vm.allowSave },
                on: { click: _vm.ok },
              },
              [_vm._v(_vm._s(_vm.saveButtonText))]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }