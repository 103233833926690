var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "goals-i-manage-goal" }, [
    _c("div", { staticClass: "goals-i-manage-goal__image-part" }, [
      _c("a", { attrs: { href: _vm.link + "?from_list=1" } }, [
        _c("img", {
          staticClass: "img-circle goals-i-manage-goal__image-part__image",
          attrs: { src: _vm.user.avatar },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "goals-i-manage-goal__content-part" }, [
      _c("a", { attrs: { href: _vm.link } }, [
        _c("img", {
          staticClass: "goals-i-manage-goal__content-part__icon",
          attrs: { src: _vm.icon, alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: _vm.link } }, [
        _vm._v(
          _vm._s(_vm.user.name) + "'s " + _vm._s(_vm._f("customTerm")("Goals"))
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }