<template>
    <span
            v-show="!task.has_steps"
            ref="complete-checkbox-desktop"
            class="inline-block"
    >
        <i class="fa fa-check-square-o pointer"
           tabindex="0"
           :class="{'color-grass-green':task.completed, 'color-tag-gray':!task.completed}"
           v-tooltip="tooltip"
           v-if="completable"
           aria-label="Complete Mark"
           role="checkbox"
           :aria-checked="task.completed?'true':'false'"
           @click="onCompleteCheckboxClicked"
           @keydown.enter="onCompleteCheckboxClicked"
           style="font-size: 48px;"
        ></i>

        <goal-task-complete-dropdown
                ref="complete-dropdown"
        ></goal-task-complete-dropdown>
    </span>
</template>

<script>
    import Vue from 'vue';
    import GoalTaskCompleteDropdown
        from "../GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskCompleteDropdown.vue";

    export default {
        components: {GoalTaskCompleteDropdown},
        props: {
            task: Object,
            isGoalAdmin: Boolean,
            availableCompletionOptions: Array

        },
        computed: {
            tooltip() {
                if (this.task.completed) {
                    return 'Sign off as incomplete';
                } else {
                    return 'Sign off as complete';
                }
            },
            completable() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const currentUserIsAssignee = this.task.assignee && CurrentUser.id === this.task.assignee.id;
                const createdByCurrentUser = this.task.created_by && this.task.created_by.id === CurrentUser.id;

                return currentUserIsAssignee || this.isGoalAdmin || createdByCurrentUser;
            },
            allowSetAssistanceOptions() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const goalOwner = this.task.goal.owner;
                const taskAssignee = this.task.assignee;
                if (CurrentUser.is_guardian_of(goalOwner.id)) {
                    return true;
                }
                if (taskAssignee && CurrentUser.is_guardian_of(taskAssignee.id)) {
                    return true;
                }
                return false;
            },
        },
        methods: {
            markTaskAsCompleted(task, withAssistanceParams = false, providedAssistance = []) {
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GoalTaskResource.mark_task_as_completed(task.id, withAssistanceParams, withAssistanceParams ? providedAssistance : undefined)
                    .then(resp => {
                        this.task.status = resp.data.task.status;
                        this.task.completed = resp.data.task.completed;
                        this.task.provided_assistance = resp.data.task.provided_assistance;
                        this.task.completion_type = resp.data.task.completion_type;
                        this.task.completed_by = resp.data.task.completed_by;
                        Vue.notifications.success('Updated');
                        this.$emit('completedStateUpdated', task);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally(() => $rootScope.hide_dimmer());
            },
            updateCompletionOptions(task, withSupport, providedAssistance = []) {
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GoalTaskResource.update_task_completion_params(task.id, withSupport, withSupport ? providedAssistance : undefined)
                    .then(resp => {
                        this.task.status = resp.data.task.status;
                        this.task.completed = resp.data.task.completed;
                        this.task.provided_assistance = resp.data.task.provided_assistance;
                        this.task.completion_type = resp.data.task.completion_type;
                        this.task.completed_by = resp.data.task.completed_by;
                        Vue.notifications.success('Updated');
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally(() => $rootScope.hide_dimmer());
            },
            markTaskAsIncompleted(task) {
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GoalTaskResource.mark_task_as_incompleted(task.id)
                    .then(resp => {
                        this.task.status = resp.data.task.status;
                        this.task.completed = resp.data.task.completed;
                        this.task.provided_assistance = resp.data.task.provided_assistance;
                        this.task.completion_type = resp.data.task.completion_type;
                        this.task.completed_by = resp.data.task.completed_by;
                        Vue.notifications.success('Updated');
                        this.$emit('completedStateUpdated', task);
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    })
                    .finally(() => $rootScope.hide_dimmer());
            },
            showCompletionOptions() {
                this.$refs['complete-dropdown'].show(false, this.task.provided_assistance, this.availableCompletionOptions)
                    .then(({result, withSupport, options}) => {
                        this.markTaskAsCompleted(this.task, withSupport, options);
                    });
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs['complete-dropdown']?.focusOnFirstOption();
                    }, 50);
                });

            },
            showIncompletionOptions() {
                this.$refs['complete-dropdown'].show(true, this.task.provided_assistance, this.availableCompletionOptions)
                    .then(({result, withSupport, options}) => {
                        if (result === 'complete') {
                            this.updateCompletionOptions(this.task, withSupport, options);
                        } else {
                            this.markTaskAsIncompleted(this.task);
                        }
                    });
            },
            onCompleteCheckboxClicked() {
                if (this.allowSetAssistanceOptions) {
                    if (this.task.completed) {
                        this.showIncompletionOptions();
                    } else {
                        this.showCompletionOptions();
                    }

                } else {
                    this.toggleCompletedStatus();
                }
            },
            toggleCompletedStatus() {
                if (this.task.completed) {
                    this.markTaskAsIncompleted(this.task);
                } else {
                    this.markTaskAsCompleted(this.task);
                }
            },
        },
        name: "TaskDetailCompleteCheckbox",
        mounted() {
            if (this.$refs['complete-dropdown']) {
                const ignoredElements = [this.$refs['complete-dropdown'].$refs.dropdown.$el, this.$refs['complete-checkbox-desktop']];
                this.$refs['complete-dropdown'].addIgnoredElements(ignoredElements);
            }
        }
    };
</script>

<style scoped>

</style>