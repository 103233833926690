<template>
    <div class="">
        <goal-tags-filter-panel></goal-tags-filter-panel>
        <goal-create-panel :allow-filter="false"></goal-create-panel>
        <div class="goal-tasks-list">
            <goal-task
                    v-for="task in visibleTasks"
                    v-show="isTaskFilteredByTags(task)"
                    :key="task.id+''+(task.children?task.children.length:'')"
                    :initial-task="task"
                    :layout="goalTaskLayout"
                    :is-completed-mark-editable="isTaskCompletedMarkEditable"
                    :is-editable="isTaskEditable(task)"
                    :is-assignee-editable="isTaskAssigneeEditable(task)"

                    :allow-multiple-assign="isAdmin&&!filterIsActive"
                    :allow-drag="isAdmin&&!filterIsActive"
                    :delete-forbidden="filterIsActive"

                    :is-admin="isAdmin"
                    :edit-mode="isTaskInEditMode(task)"
                    :tags-in-project="tagsInProject"

                    :availableCompletionOptions="availableCompletionOptions"

                    :goal="goal"

                    :router-navigation="true"

            ></goal-task>
            <div class="alert alert-info" v-if="!visibleTasks.length">There has been nothing added yet</div>
        </div>
        <div class=""
             v-if="(visibleTasks.length<flattenedTasks.length&&!isAdmin)&&!showOtherUsersTasks && !assigneesInFilter.length">
            <strong class="pointer" @click="expandOtherUsersTasks">Show Tasks assigned to other users.</strong>
        </div>
        <div class="" v-if="showOtherUsersTasks && !assigneesInFilter.length">
            <strong class="pointer" @click="hideOtherUsersTasks">Hide Tasks assigned to other users.</strong>
        </div>

    </div>

</template>

<script>
    import GoalTask from '../../../goalTaskComponents/GoalTask.vue';
    import GoalCreatePanel from '../../../GoalCreatePanel.vue';
    import GoalTagsFilterPanel from '../../../GoalTagsFilterPanel.vue';
    import ScreenWidthAwareMixin from "../../../../../../vue/mixins/ScreenWidthAwareMixin";
    import GoalTasksFilterPopup from './GoalTasksFilterPopup.vue';
    import {taskActionsBus, TASK_ACTION_BUS_ACTIONS} from '../../../taskActionsBus';
    import _ from 'lodash';
    import Vue from 'vue';
    import {customTermFilter} from "../../../../../../vue/filters/CustomTerm";

    export default {
        name: "GoalDetailPageMainTasks",
        components: {GoalTask, GoalCreatePanel, GoalTagsFilterPanel},
        mixins: [ScreenWidthAwareMixin],
        data() {
            return {
                availableCompletionOptions: []
            };
        },
        computed: {
            goal() {
                return this.$store.state.goalDetailPageStore.goal;
            },
            tasks() {
                return this.$store.state.goalDetailPageStore.tasks;
            },
            showOtherUsersTasks() {
                return this.$store.state.goalDetailPageStore.showOtherUsersTasks;
            },
            visibleTasks() {
                return this.$store.getters['goalDetailPageStore/visibleToMeTasks'];
            },
            flattenedTasks() {
                return this.$store.getters['goalDetailPageStore/flattenedTasks'];
            },
            filterIsActive() {
                return this.$store.getters['goalDetailPageStore/filterIsActive'];
            },
            goalTaskLayout() {
                if (this.windowWidth >= 600) {
                    return 'desktop';
                } else {
                    return 'mobile';
                }
            },
            assigneesInFilter() {
                return this.$store.state.goalDetailPageStore.assigneesInFilter;
            },
            isAdmin() {
                return this.$store.state.goalDetailPageStore.isAdmin;
            },
            tagsInProject() {
                return this.$store.getters['goalDetailPageStore/tagsInProject'];
            }
        },
        methods: {
            isTaskCompletedMarkEditable(task) {
                const currentUserId = this.$store.state.goalDetailPageStore.currentUser.id;

                return (task.assignee && currentUserId === task.assignee.id) || (currentUserId === task.created_by.id) || this.$store.state.goalDetailPageStore.isAdmin;
            },
            isTaskEditable(task) {
                return this.$store.state.goalDetailPageStore.isAdmin || task.created_by.id === this.$store.state.goalDetailPageStore.currentUser.id;
            },
            isTaskAssigneeEditable(task) {
                if (this.$store.state.goalDetailPageStore.isAdmin) {
                    return true;
                }
                if (this.isTaskEditable(task)) {
                    if (this.$store.state.goalDetailPageStore.goal.hide_tasks) {
                        return false;
                    } else {
                        return true;
                    }
                }
                return false;
            },
            isTaskFilteredByTags(task) {
                return this.$store.state.goalDetailPageStore.tagsInFilter.length === 0 || _.intersection(task.tags, this.$store.state.goalDetailPageStore.tagsInFilter).length > 0;
            },
            isTaskInEditMode(task) {
                return this.$store.state.goalDetailPageStore.editMode && this.isTaskEditable(task);
            },
            expandOtherUsersTasks() {
                this.$store.commit('goalDetailPageStore/set_show_other_users_tasks', true);
            },
            hideOtherUsersTasks() {
                this.$store.commit('goalDetailPageStore/set_show_other_users_tasks', false);
            },

            loadAvailableCompletionOptions() {
                const GoalResource = Vue.getAngularModule('GoalResource');
                GoalResource.get_available_assistance()
                    .then(({data}) => {
                        this.availableCompletionOptions = data;
                    }, err => {
                        Vue.notifications.error('Can\'t load available assistance');
                    });
            },
            runTaskWizard() {
                const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
                goalTaskFactory.show_create_goal_task_wizard(this.goal.id, this.isAdmin || !this.goal.hide_tasks)
                    .then(taskData => {
                        this.$store.dispatch('goalDetailPageStore/create_task', taskData);
                    }, err => {

                    });
            },
            quickCreate(taskName) {
                const data = {name: taskName};
                if (!this.$store.state.goalDetailPageStore.goal.hide_tasks || this.$store.state.goalDetailPageStore.isAdmin) {
                    data.assignee_id = this.$store.state.goalDetailPageStore.goal.owner.id;
                }

                this.$store.dispatch('goalDetailPageStore/create_task', data);
            },
            setAssigneesInFilter(value) {
                this.$router.replace({query: {'af': value, from: this.$route.query.from}});
            },
            setTagsInFilter(value) {
                this.$router.replace({query: {'tags': value, from: this.$route.query.from}});
            },
            deleteTask(task) {
                this.$store.commit('goalDetailPageStore/delete_task', task);
                Vue.notifications.success('Deleted');
            },
            setAssignee(task) {
                const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $q = Vue.getAngularModule('$q');
                goalTaskFactory.assignee_options(this.goal.id).then(res => {
                    $rootScope.show_dimmer();
                    return GoalTaskResource.update_assignee_options(task.id, {id: res});
                }, () => new Promise(()=>{}))
                    .then(resp => {
                        this.$store.commit('goalDetailPageStore/update_task', resp.data.updated_task_data);
                        if (resp.data.added_member) {
                            this.$store.commit('goalDetailPageStore/add_member', resp.data.added_member);
                        }
                        Vue.notifications.success('Updated');
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            cloneForMultipleAssignees(task) {
                const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const $q = Vue.getAngularModule('$q');
                goalTaskFactory.assignee_options(this.goal.id, {
                    header: `Duplicate and assign this ${customTermFilter('Task')} to other users`,
                    info: `Select or de-
                    select users
                    from list to assign this ${customTermFilter('Task')}.`
                }, true)
                    .then(res => {
                        return GoalTaskResource.clone_for_multiple_assignees(task.id, res[0]);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        for (let task of resp.data.created_tasks) {
                            this.$store.commit('goalDetailPageStore/add_task', task);
                        }
                        for (let member of resp.data.added_members) {
                            this.$store.commit('goalDetailPageStore/add_member', member);
                        }
                        if (resp.data.created_tasks.length === 1) {
                            Vue.notifications.success(`New ${customTermFilter('Task')} was created`);
                        } else {
                            Vue.notifications.success(`${resp.data.created_tasks.length} new ${customTermFilter('Tasks')} were created`);
                        }

                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            applyFiltersFromUrl() {
                if (this.$route.query.tags && this.$route.query.tags.length) {
                    let tagsInFilter = this.$route.query.tags;
                    if (_.isString(tagsInFilter)) {
                        tagsInFilter = [tagsInFilter];
                    }
                    this.$store.commit('goalDetailPageStore/set_tags_in_filter', tagsInFilter);
                } else {
                    this.$store.commit('goalDetailPageStore/set_tags_in_filter', []);
                }
                if (this.$route.query.af && this.$route.query.af.length) {
                    let assigneesInFilter = this.$route.query.af;
                    if (_.isString(assigneesInFilter)) {
                        assigneesInFilter = [assigneesInFilter];
                    }
                    this.$store.commit('goalDetailPageStore/set_assignees_in_filter', {
                        id_list: assigneesInFilter.map(a => parseInt(a)),
                        raise_event: false
                    });
                } else {
                    this.$store.commit('goalDetailPageStore/set_assignees_in_filter', {
                        id_list: [],
                        raise_event: false
                    });
                }
            },
            $_bindListeners() {
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.RUN_WIZARD, this.runTaskWizard);
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.QUICK_CREATE, this.quickCreate);
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.SET_ASSIGNEES_IN_FILTER, this.setAssigneesInFilter);
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.SET_TAGS_IN_FILTER, this.setTagsInFilter);
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.DELETE_TASK, this.deleteTask);
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.SET_ASSIGNEE, this.setAssignee);
                taskActionsBus.$on(TASK_ACTION_BUS_ACTIONS.CLONE_FOR_MULTIPLE_ASSIGNEE, this.cloneForMultipleAssignees);
            },
            $_unbindListeners() {
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.RUN_WIZARD, this.runTaskWizard);
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.QUICK_CREATE, this.quickCreate);
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.SET_ASSIGNEES_IN_FILTER, this.setAssigneesInFilter);
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.SET_TAGS_IN_FILTER, this.setTagsInFilter);
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.DELETE_TASK, this.deleteTask);
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.SET_ASSIGNEE, this.setAssignee);
                taskActionsBus.$off(TASK_ACTION_BUS_ACTIONS.CLONE_FOR_MULTIPLE_ASSIGNEE, this.cloneForMultipleAssignees);
            }
        },
        mounted() {
            this.applyFiltersFromUrl();
            this.loadAvailableCompletionOptions();
            this.$_bindListeners();
        },
        beforeDestroy() {
            this.$_unbindListeners();
        }
    };
</script>

<style scoped lang="scss">
</style>