var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { style: _vm.staticBackgroundStyle }),
    _vm._v(" "),
    _vm.fullScreenEnabled || _vm.fullScreenFallbackEnabled
      ? _c(
          "div",
          {
            attrs: { id: "timeline-cancel-fullscreen-panel" },
            on: { click: _vm.onStopFullscreen },
          },
          [
            _c("i", {
              staticClass: "far fa-compress-arrows-alt",
              attrs: { id: "timeline-cancel-fullscreen-panel__collapse-icon" },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "timeline-container",
        style: _vm.timelineContainerStyle,
        attrs: { id: "timeline-container" },
      },
      [
        _c(
          "div",
          {
            ref: "timeline-container__inner",
            staticClass: "timeline-container__inner",
            style: _vm.timelineContainerInnerStyle,
          },
          [
            _c("members-list", {
              attrs: {
                width: _vm.membersListWidth,
                "content-height": _vm.requiredTimelineContentHeight,
              },
            }),
            _vm._v(" "),
            _c("timeline", {
              ref: "timeline",
              attrs: {
                width: _vm.timelineWidth,
                "content-height": _vm.requiredTimelineContentHeight,
              },
              on: { offsetYChanged: _vm.onTimelineOffsetYChanged },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("scroller", {
          staticStyle: { "z-index": "5" },
          attrs: { width: _vm.usedWidth },
          on: { onPosChanged: _vm.scrollerPosChanged },
        }),
        _vm._v(" "),
        _c(
          "modal",
          {
            ref: "modal",
            attrs: { transition: 0, "append-to-body": false },
            model: {
              value: _vm.opened,
              callback: function ($$v) {
                _vm.opened = $$v
              },
              expression: "opened",
            },
          },
          [
            _vm.openedStep
              ? _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("a", {
                    attrs: { id: "close_popup" },
                    on: { click: _vm.closePopup },
                  }),
                  _vm._v(" "),
                  _c("h3", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.openedStep.name)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.openedStep
              ? _c("div", [
                  _vm._v(
                    "\n                Show some basic task data here.\n                e.g. description, steps, comments count.\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-mm btn-green",
                      on: { click: _vm.openDetailedTaskPage },
                    },
                    [_vm._v("Open Detail")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-mm save-button",
                      on: { click: _vm.closePopup },
                    },
                    [
                      _vm._v(
                        "\n                            OK\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }