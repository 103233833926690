import {DjangoUrls} from 'DjangoUrls';
import axios from "axios";

const PREFIX = 'api.v1:goal_categories-';
const goal_categories_resource = {
    create_category(data) {
        const url = DjangoUrls[`${PREFIX}create-category`]();
        return axios({
            method: 'POST',
            url,
            data
        });
    },
    update_category(categoryId, data) {
        const url = DjangoUrls[`${PREFIX}update-category`](categoryId);
        return axios({
            method: 'PATCH',
            url,
            data
        });
    },
    delete_category(categoryId) {
        const url = DjangoUrls[`${PREFIX}delete-category`](categoryId);
        return axios({
            method: 'DELETE',
            url,
        });
    },
};

export default goal_categories_resource;