<template>
    <div class="">
        <div class="navigation-group">
            <router-link :to="{name:'GoalDetailPageMainTasks', query: $route.query}" v-show="isMember" replace>
                <vue-folder-button :active="$route.name==='GoalDetailPageMainTasks'" :text="tasksLabel"
                                   icon-class="fa-tasks"></vue-folder-button>
            </router-link>
            <router-link class="hidden-xs" :to="{name:'GoalDetailPageMainTimeline', query: $route.query}"
                         v-show="isMember" replace>
                <vue-folder-button :active="$route.name==='GoalDetailPageMainTimeline'" text="Timeline"
                                   icon-class="fa-clock-o"></vue-folder-button>
            </router-link>

            <router-link :to="{name:'GoalDetailPageMainAbout', query: $route.query}" replace>
                <vue-folder-button :active="$route.name==='GoalDetailPageMainAbout'" text="About"
                                   icon-class="fa-info"></vue-folder-button>
            </router-link>
            <router-link class="hidden-xs" :to="{name:'GoalDetailPageMainAttachments', query: $route.query}" replace
                         v-show="isMember&&(!isShowMoreNeeded||showMorePressed)">
                <vue-folder-button :active="$route.name==='GoalDetailPageMainAttachments'" text="Attachments"
                                   icon-class="fa-file-text"></vue-folder-button>
            </router-link>

            <router-link class="hidden-xs" :to="{name:'GoalDetailPageMainMembers', query: $route.query}" replace
                         v-show="isMember&&(!isShowMoreNeeded||showMorePressed)">
                <vue-folder-button :active="$route.name==='GoalDetailPageMainMembers'" text="Members"
                                   icon-class="fa-users"></vue-folder-button>
            </router-link>

            <vue-folder-button class="hidden-xs" text="" icon-class="fa-ellipsis-h" v-show="isShowMoreNeeded"
                               color="color-header"
                               inner-color="#9c9c9c"
                               @click.native="showMore"></vue-folder-button>
        </div>
        <div class="actions-group">
            <router-link class="hidden-xs" :to="{name:'GoalDetailPageExports', query: $route.query}" replace
                         v-show="isAdmin">
                <vue-folder-button
                        no-text-on-mobile
                        :active="$route.name==='GoalDetailPageExports'"
                        text="Export PDF"
                        icon-class="fa-arrow-down"></vue-folder-button>
            </router-link>
            <vue-folder-button
                    class="hidden-xs"
                    v-show="$route.name==='GoalDetailPageMainTasks'"
                    v-if="filterAllowed"
                    :color="filterButtonColor"
                    :text="filterTasksLabel"
                    :max-width="100"
                    icon-class="fa-filter"
                    @click.native="editFilter"
                    :no-text-on-mobile="true"
            ></vue-folder-button>
            <vue-folder-button class="hidden-xs" text="Full Screen" icon-class="fa-expand-arrows-alt"
                               v-if="isFullScreenPossible"
                               @click.native="runFullscreen" :no-text-on-mobile="true"></vue-folder-button>

            <vue-folder-button :text="editModeButtonLabel" icon-class="fa-pencil" v-if="isEditModePossible"
                               @click.native="toggleEditMode" :no-text-on-mobile="true" icon-prefix="far">
                <span
                        v-if="editMode"
                        slot="icon"
                        class="fa-stack"
                        style="width: 32px;"
                      >
                        <i class="far fa-pencil fa-stack-1x" style="position: absolute; top:-1px" />
                        <i class="far fa-slash fa-stack-1x fa-inverse" style="position: absolute; top:-1px" />
                      </span>
            </vue-folder-button>

            <vue-folder-button text="Make Me Admin" icon-class="fa-user-secret" v-if="iCanMakeMeAdmin"
                               @click.native="makeMeAdmin" :no-text-on-mobile="true"></vue-folder-button>

            <vue-folder-button class="hidden-xs" text="Back" color="blue" icon-class="fa-reply" @click.native="goBack"
                               :no-text-on-mobile="true"></vue-folder-button>
            <dropdown menu-right>
                <vue-folder-button
                        icon-class="fa-ellipsis-v"
                        no-text-on-mobile
                        text="Menu"
                        role="menu"
                        id=" menu-dropdown"
                        class="dropdown-toggle hidden-sm hidden-md hidden-lg"
                ></vue-folder-button>
                <template slot="dropdown">
                    <li class="dropdown-menu-link"
                        :class="{'dropdown-menu-link_active':$route.name==='GoalDetailPageMainTimeline'}"
                        v-if="isMember">
                        <router-link :to="{name:'GoalDetailPageMainTimeline', query: $route.query}" replace role="button" aria-owns="menu-dropdown"
                        >
                            <i class="far fa-clock"></i> Timeline
                        </router-link>
                    </li>

                    <li class="dropdown-menu-link"
                        :class="{'dropdown-menu-link_active':$route.name==='GoalDetailPageMainAttachments'}"
                        v-if="isMember">
                        <router-link :to="{name:'GoalDetailPageMainAttachments', query: $route.query}" replace role="button" aria-owns="menu-dropdown"
                        >
                            <i class="far fa-file-alt"></i> Attachments
                        </router-link>
                    </li>

                    <li class="dropdown-menu-link"
                        :class="{'dropdown-menu-link_active':$route.name==='GoalDetailPageMainMembers'}"
                        v-if="isMember">
                        <router-link :to="{name:'GoalDetailPageMainMembers', query: $route.query}" replace role="button" aria-owns="menu-dropdown"
                        >
                            <i class="far fa-users"></i> Members
                        </router-link>
                    </li>

                    <li class="dropdown-menu-link"
                        :class="{'dropdown-menu-link_active':$route.name==='GoalDetailPageExports'}"
                        v-if="isAdmin">
                        <router-link :to="{name:'GoalDetailPageExports', query: $route.query}" replace role="button" aria-owns="menu-dropdown"
                        >
                            <i class="far fa-arrow-down"></i> Export PDF
                        </router-link>
                    </li>
                    <li class="dropdown-menu-link"
                        v-if="filterAllowed&&$route.name==='GoalDetailPageMainTasks'" aria-owns="menu-dropdown">
                        <a href="#" role="button" @click.stop.prevent="editFilter" tabindex="0">
                            <i class="far fa-filter"></i> {{ filterTasksLabel }}
                        </a>
                    </li>
                    <li class="dropdown-menu-link" v-if="isFullScreenPossible" aria-owns="menu-dropdown">
                        <a href="#" role="button" @click.stop.prevent="runFullscreen" tabindex="0">
                            <i class="far fa-expand-arrows-alt"></i> Full Screen
                        </a>
                    </li>
                    <li role="separator" class="divider"></li>
                    <li class="dropdown-menu-link" aria-owns="menu-dropdown">
                        <a href="#" role="button" @click.stop.prevent="goBack" tabindex="0">
                            <i class="far fa-reply"></i> Back
                        </a>
                    </li>
                </template>
            </dropdown>
        </div>
    </div>
</template>

<script>
    import { GOAL_HEADER_BUS_ACTIONS, goalHeaderBus } from './goalHeaderBus.js';
    import { customTermFilter } from '~/vue/filters/CustomTerm';

    export default {
        name: 'GoalDetailPageHeaderButtonsPanel',
        props: {
            filterAllowed: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showMorePressed: false
            };
        },
        computed: {
            assigneesInFilter() {
                return this.$store.state.goalDetailPageStore.assigneesInFilter;
            },
            filterButtonColor() {
                if (this.assigneesInFilter.length) {
                    return 'grass-green';
                }
            },
            filterTasksLabel() {
                return `Filter ${this.$customTerm('Tasks')}`;
            },
            tasksLabel() {
                return this.$customTerm('Tasks');
            },
            isFullScreenPossible() {
                return this.$route.name === 'GoalDetailPageMainTimeline';
            },
            iCanMakeMeAdmin() {
                return this.$store.getters['goalDetailPageStore/iCanMakeMeAdmin'];
            },
            isMember() {
                return this.$store.state.goalDetailPageStore.isMember;
            },
            isAdmin() {
                return this.$store.state.goalDetailPageStore.isAdmin;
            },
            isEditModePossible() {
                return this.$store.getters['goalDetailPageStore/isEditModePossible'];
            },
            isShowMoreNeeded() {
                return this.isMember && !this.showMorePressed;
            },
            activeUrl() {
                return this.$store.state.goalDetailPageStore.activeUrl;
            },
            editMode() {
                return this.$store.state.goalDetailPageStore.editMode;
            },
            editModeButtonClass() {
                if (this.$store.state.goalDetailPageStore.editMode) {
                    return 'fa-slash fa-stack-1x fa-inverse';
                } else {
                    return 'fa-pencil';
                }
            },
            editModeButtonLabel() {
                if (this.$store.state.goalDetailPageStore.editMode) {
                    return `View`;
                } else {
                    return `Edit`;
                }
            }
        },
        methods: {
            makeMeAdmin() {
                this.$store.dispatch('goalDetailPageStore/make_me_admin');
            },
            goBack() {
                goalHeaderBus.$emit(GOAL_HEADER_BUS_ACTIONS.GO_BACK);
            },
            toggleEditMode() {
                this.$store.commit('goalDetailPageStore/set_edit_mode', {
                    value: !this.$store.state.goalDetailPageStore.editMode,
                    emitEvent: true
                });
            },
            showMore() {
                this.showMorePressed = true;
            },
            runFullscreen() {
                goalHeaderBus.$emit(GOAL_HEADER_BUS_ACTIONS.RUN_FULLSCREEN_ON_TIMELINE);
            },
            editFilter() {
                this.$emit('filterClicked');
            }
        },
        mounted() {
            if (this.isShowMoreNeeded && ['GoalDetailPageExports', 'GoalDetailPageMainMembers', 'GoalDetailPageMainAttachments', 'GoalDetailPageMainAbout'].includes(this.$route.name)) {
                this.showMore();
            }
        }

    };
</script>

<style scoped lang="scss">
  @import "../../../../styles/const";

  .dropdown-menu-link {
    font-size: 18px;
    height: 34px;

    &_active{
      & > a{
        font-weight: bold !important;
      }

    }

    & > a {
      padding-left: 10px!important;
      &:active,&:hover,&:focus{
        background: none;
        background-color: $lightgray!important;
      }
    }

    & > a > i {
      width: 24px;
      text-align: center;
    }

    & > a:hover {
      background-image: none !important;
      background-color: $lightgray;
    }
  }

  .dropdown-toggle {
    box-shadow: none !important;
  }

  .navigation-group {
    float: left;
  }

  .actions-group {
    float: right;
  }
</style>
