<template>
    <div class="tasks-list-page">
        <vue-simple-header>
            <span slot="panelLeft">
                <span class="tasks-list-page__page-title">{{ "Tasks"|customTerm }}</span>
                <br>
                <span>
                    <router-link :to="{name:'myGoalsListPageMain'}" style="color: #333;">{{
                            "Goals"|customTerm
                        }}</router-link>
                </span>
            </span>
            <div slot="panelRight">
                <vue-folder-button text="Filter" icon-class="fa-filter" :active="filterIsActive"
                                   active-color="grass-green"
                                   @click.native="openFilterPopup"></vue-folder-button>
                <vue-search-input :initial-query="filter.query" @search="search"
                                  @cancel="cancelSearch"></vue-search-input>
                <router-link :to="{name:'myGoalsListPageMain'}">
                    <vue-folder-button text="Back" icon-class="fa-reply" color="blue"></vue-folder-button>
                </router-link>
            </div>
        </vue-simple-header>
        <div v-if="popularTags.length">
            <tasks-list-page-tags-filter
                    :tags="popularTags"
                    @tagsFilterChanged="tagsFilterChanged"
            ></tasks-list-page-tags-filter>
        </div>
        <div class="" v-infinite-scroll="loadMore">
            <tasks-list-page-task v-for="task in tasks" :key="task.id" :task="task"
                                  :router-navigation="true"
                                  :layout="goalTaskLayout"></tasks-list-page-task>
        </div>
        <tasks-list-page-filter :available-assignees="availableAssignees"
                                :available-owners="availableOwners"
                                :available-categories="availableCategories"
                                :initial="filter"
                                ref="filterPopup"
                                @filterChanged="filterChanged"
        ></tasks-list-page-filter>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    import TasksListPageTask from '../../projects/tasks_list_page/TasksListPageTask.vue';
    import TasksListPageFilter from '../../projects/tasks_list_page/TasksListPageFilter.vue';
    import TasksListPageTagsFilter from '../../projects/tasks_list_page/TasksListPageTagsFilter.vue';
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import TasksListMixin from '../../projects/tasks_list_page/TasksListMixin.js';
    import {CurrentUserClass} from "shared/CurrentUser";

    const CurrentUser = new CurrentUserClass();


    export default {
        name: "MyTasksList",
        components: {TasksListPageTask, TasksListPageFilter, TasksListPageTagsFilter},
        mixins: [ScreenWidthAwareMixin, TasksListMixin],
        props: {
            initialFilter: {
                type: Object,
                default: () => ({
                    query: '',
                    assignees: [CurrentUser.id],
                    owners: [],
                    categories: [],
                    show_archived: false,
                })
            },
        },
        data() {
            return {};
        },
        computed: {
        },
        methods: {},
        created() {
            _.merge(this.filter, this.initialFilter);
            let TasksListResource = Vue.getAngularModule('TasksListResource');
            let CurrentUser = Vue.getAngularModule('CurrentUser');
            this.loadTasksResource = _.partial(TasksListResource.get_my_tasks, CurrentUser.id);
            this.loadTagsResource = _.partial(TasksListResource.get_popular_tags, CurrentUser.id);
            this.loadFilterDataResource = _.partial(TasksListResource.get_data_for_filter, CurrentUser.id);
        },
        metaInfo() {
            const tasksTerm = this.$customTerm('Tasks');
            const title = tasksTerm;
            return {
                title,
            };
        }
    };
</script>

<style scoped lang="scss">
  .tasks-list-page {
    &__page-title {
      font-size: 24px;
      font-weight: bold;
    }
  }
</style>