import Vue from 'vue';
import _ from 'lodash';

export default {
    data() {
        return {
            totalTasksCount: 0,
            allLoaded: false,
            initialized: false,
            loadingBlock: false,

            availableCategories: [],
            availableAssignees: [],
            availableOwners: [],
            popularTags: [],

            tasks: [],

            filter: {
                owners: [],
                assignees: [],
                categories: [],
                show_archived: false,
                query: undefined,
                tags: []
            },

            loadTasksResource: undefined,
            loadTagsResource: undefined,
            loadFilterDataResource: undefined,
        };
    },
    computed: {
        goalTaskLayout() {
            if (this.windowWidth >= 600) {
                return 'desktop';
            } else {
                return 'mobile';
            }
        },
        filterIsActive() {
            if (this.filter.owners.length > 0) {
                return true;
            }
            if (this.filter.categories.length > 0) {
                return true;
            }
            if (this.filter.assignees.length > 0) {
                return true;
            }
            if (this.filter.show_archived !== false) {
                return true;
            }
            return false;
        }
    },
    methods: {
        loadTasks() {
            let $rootScope = Vue.getAngularModule('$rootScope');
            this.loadingBlock = true;
            $rootScope.show_dimmer();
            this.loadTasksResource(_.merge({
                limit: 20,
                offset: this.tasks.length
            }, this.filter))
                .then(resp => {
                    this.tasks = this.tasks.concat(resp.data.results);
                    this.totalTasksCount = resp.data.count;
                    this.allLoaded = (this.tasks.length >= this.totalTasksCount);
                    this.initialized = true;

                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => {
                    $rootScope.hide_dimmer();
                    this.loadingBlock = false;
                });
        },
        loadMore() {
            if (this.allLoaded || !this.initialized || this.loadingBlock) {
                return;
            }
            this.loadTasks();
        },
        openFilterPopup() {
            this.$refs.filterPopup.open();
        },
        resetLoadData() {
            this.totalTasksCount = 0;
            this.allLoaded = false;
            this.initialized = false;
            this.tasks = [];
        },
        filterChanged(filterData) {
            this.filter.assignees = filterData.assignees;
            this.filter.categories = filterData.categories;
            this.filter.owners = filterData.owners;
            this.filter.show_archived = filterData.show_archived;
            this.$emit('filterChanged', this.filter);
            this.resetLoadData();
            this.loadTasks();
            this.loadTags();
        },
        search(val) {
            this.filter.query = val;
            this.$emit('filterChanged', this.filter);
            this.resetLoadData();
            this.loadTasks();
        },
        cancelSearch() {
            this.filter.query = undefined;
            this.$emit('filterChanged', this.filter);
            this.resetLoadData();
            this.loadTasks();
        },
        tagsFilterChanged(tags) {
            this.filter.tags = tags;
            // this.$emit('filterChanged', this.filter);
            this.resetLoadData();
            this.loadTasks();
        },
        loadTags() {
            this.loadTagsResource(this.filter)
                .then(resp => {
                    this.popularTags = resp.data.popular_tags;
                }, err => {
                    Vue.notifications.error(err || 'Can\'t load tags for filter');
                });
        },
        loadFilterData() {
            this.loadFilterDataResource()
                .then(resp => {
                    this.availableCategories = resp.data.available_categories;
                    this.availableAssignees = resp.data.available_assignees;
                    this.availableOwners = resp.data.available_owners;

                }, err => {
                    Vue.notifications.error(err || 'Can\'t load data for filter');
                });
        }
    },
        mounted() {
            this.loadTasks();
            this.loadFilterData();
            this.loadTags();
        }
};