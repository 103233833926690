var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _vm._v(
            "\n            Are you sure you want unhide this " +
              _vm._s(_vm._f("customTerm")("Goal")) +
              "?\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isCurrentlyFinished
        ? _c("div", [
            _c("div", { staticClass: "row margin-15-top" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "alert alert-info" }, [
                  _c("strong", [_vm._v("Attention")]),
                  _vm._v(
                    "! Unhiding this " +
                      _vm._s(_vm._f("customTerm")("Goal")) +
                      " will make it visible to\n                    all\n                    its members.\n                "
                  ),
                ]),
              ]),
            ]),
          ])
        : _c("div", [
            _vm.forUser
              ? _c("div", { staticClass: "row margin-15-top" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "alert alert-info" }, [
                      _c("strong", [_vm._v("Attention")]),
                      _vm._v("! You are managing "),
                      _c("strong", [_vm._v(_vm._s(_vm.forUser.name))]),
                      _vm._v(
                        "'s\n                    " +
                          _vm._s(_vm._f("customTerm")("Goals")) +
                          ".\n                    "
                      ),
                      _c("div", [
                        _vm._v(
                          "Unhiding this " +
                            _vm._s(_vm._f("customTerm")("Goal")) +
                            " will make it visible for "
                        ),
                        _c("strong", [_vm._v(_vm._s(_vm.forUser.name))]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-default save-button",
                on: { click: _vm.close },
              },
              [_vm._v("\n                    Cancel\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-mm btn-green save-button",
                on: { click: _vm.finish },
              },
              [_vm._v("\n                    Unhide\n                ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }