var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visibleTags.length,
          expression: "visibleTags.length",
        },
      ],
      staticClass: "task-tags-list-filter",
    },
    [
      _c("div", { staticClass: "task-tags-list-filter__title" }, [
        _vm._v("Tags in this " + _vm._s(_vm._f("customTerm")("Goal")) + ":"),
      ]),
      _vm._v(" "),
      _vm._l(_vm.visibleTags, function (tag) {
        return _c(
          "div",
          {
            staticClass: "task-tags-list-filter__tag",
            class: { active: tag.active },
            on: {
              click: function ($event) {
                return _vm.toggleTagFilter(tag)
              },
            },
          },
          [_vm._v(_vm._s(tag.name) + "\n    ")]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowAllButtonVisible,
              expression: "isShowAllButtonVisible",
            },
          ],
          staticClass: "task-tags-list-filter__show-all",
          on: { click: _vm.showAll },
        },
        [_vm._v("show all...")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }