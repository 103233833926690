<template>
    <modal v-model="opened" :transition='0'>
        <div slot="header">
            <a id="close_popup" @click="close"></a>
            <h3 class="modal-title">Filter {{ "Tasks"|customTerm }}</h3>
        </div>
        <div slot="footer">
            <button class="btn btn-mm btn-default" @click="reset">Reset Filter</button>
            <button class="btn btn-mm btn-default" @click="close">Cancel</button>
            <button class="btn btn-mm btn-green" @click="apply">Apply</button>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <div class="inline-block">
                    <vue-switch id="show_archived" name="show_archived" v-model="show_archived"
                                class="vertical-middle"></vue-switch>
                    <span class="vertical-middle"> Show tasks from Archived {{ "Goals"|customTerm }}</span>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-if="assigneeOptions.length">
            <div class="col-sm-12">
                <div>Filter by {{ "Task"|customTerm }} Assignee: <a href="javascript:;" class="pointer" @click="resetAssigneeFilter"
                                                 v-show="assignees.length">(reset)</a></div>
                <multi-select v-model="assignees" :options="assigneeOptions" size="lg" block/>
            </div>
        </div>
        <div class="row margin-15-top" v-if="ownerOptions.length">
            <div class="col-sm-12">
                <div>Filter by {{ "Goal"|customTerm }} Owner: <a href="javascript:;" class="pointer" @click="resetOwnerFilter"
                                              v-show="owners.length">(reset)</a></div>
                <multi-select v-model="owners" :options="ownerOptions" size="lg" block/>
            </div>
        </div>
        <div class="row margin-15-top" v-if="categoryOptions.length>1">
            <div class="col-sm-12">
                <div>Filter by {{ "Goal"|customTerm }} Category: <a href="javascript:;" class="pointer" @click="resetCategoriesFilter"
                                                 v-show="categories.length">(reset)</a></div>
                <multi-select v-model="categories" :options="categoryOptions" size="lg" block/>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "TasksListPageFilter",
        data() {
            return {
                opened: false,
                assignees: [],
                owners: [],
                categories: [],
                show_archived: false,
                backup: {}
            };
        },
        props: {
            initial: {
                type: Object
            },
            availableOwners: Array,
            availableAssignees: Array,
            availableCategories: Array,
        },
        computed: {
            assigneeOptions() {
                return this.availableAssignees.map(a => ({value: a.id, label: a.name}));
            },
            ownerOptions() {
                return this.availableOwners.map(a => ({value: a.id, label: a.name}));
            },
            categoryOptions() {
                return this.availableCategories.map(a => ({value: a.id, label: a.name}));
            },
        },
        methods: {
            dumpData() {
                return {
                    assignees: this.assignees.map(a => a),
                    owners: this.owners.map(a => a),
                    categories: this.categories.map(a => a),
                    show_archived: this.show_archived
                };
            },
            backUpData() {
                this.backup = this.dumpData();
            },
            open() {
                this.opened = true;
            },
            reset() {
                this.assignees = []; //this.backup.assignees.map(a => a);
                this.owners = [];
                this.categories = [];
                this.show_archived = false;
                this.apply();
            },
            close() {
                this.opened = false;
            },
            apply() {
                this.$emit('filterChanged', this.dumpData());
                this.opened = false;
            },
            resetAssigneeFilter() {
                this.assignees = [];
            },
            resetOwnerFilter() {
                this.owners = [];
            },
            resetCategoriesFilter() {
                this.categories = [];
            },
        },
        created() {
            this.assignees = this.initial.assignees;
            this.owners = this.initial.owners;
            this.categories = this.initial.categories;
            this.show_archived = this.initial.show_archived;
            this.backUpData();
        }
    };
</script>

<style scoped>

</style>