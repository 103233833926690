/**
 * Created by orion on 09.05.17.
 */
import {DjangoUrls} from 'DjangoUrls';

GoalTaskTagsResource.$inject = ['$http'];

function GoalTaskTagsResource($http) {
    const PREFIX = 'api.v1:goal_task_tags-';
    return {
        add_tag: function (taskId, tagName, children_to_update_id_list) {
            const url = DjangoUrls[`${PREFIX}add-tag`](taskId);
            return $http({
                method: 'POST',
                url,
                data: {name: tagName, children_to_update_id_list}
            });
        },
        delete_tag: function (taskId, tagName, children_to_update_id_list) {
            const url = DjangoUrls[`${PREFIX}delete-tag`](taskId);
            return $http({
                method: 'POST',
                url,
                data: {name: tagName, children_to_update_id_list}
            });
        }
    };
}

export default GoalTaskTagsResource;