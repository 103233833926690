<template>
    <div class="goal-page-header">
        <div class="goal-page-header__avatar-part">
            <div class="goal-page-header__avatar-part__avatar" :style="avatarStyle"></div>
            <div class="goal-page-header__title-mobile">
                <goal-detail-page-header-title></goal-detail-page-header-title>
            </div>
        </div>
        <div class="goal-page-header__content-part">
            <div class="">
                <div class="goal-page-header__title-desktop">
                    <goal-detail-page-header-title></goal-detail-page-header-title>
                </div>
            </div>
            <div class="goal-page-header__content-part__description">
                {{ goalDescription }} <span class="goal-page-header__content-part__description__show-all"
                                            v-if="isDescriptionLargerThenPossible"
                                            @click="showWholeDescription">show all</span>
            </div>
            <div class="goal-page-header__content-part__buttons">
                <goal-detail-page-header-buttons-panel @filterClicked="showFilterSettings"
                                                       :filter-allowed="filterAllowed"></goal-detail-page-header-buttons-panel>
            </div>
            <simple-progress-bar size="medium" :val="completedPercentage" bar-color="#51ac51" bg-color="#c7c7c7"
                                 :text="completedPercentage+'%'" text-position="top"
                                 class="goal-page-header__simple-progress-bar"></simple-progress-bar>
        </div>
        <modal v-if="isDescriptionLargerThenPossible" v-model="descriptionOpened" title="Goal Description" size="lg"
               :transition="0" :footer="false">
            <p class="goal-page-header__content-part__description">{{ goal.description }}</p>
        </modal>
        <goal-tasks-filter-popup v-if="filterAllowed" ref="filterPopup"></goal-tasks-filter-popup>
    </div>
</template>

<script>
    import GoalDetailPageHeaderButtonsPanel from './GoalDetailPageHeaderButtonsPanel.vue';
    import GoalDetailPageHeaderTitle from './GoalDetailPageHeaderTitle.vue';
    import SimpleProgressBar from 'vue-simple-progress';
    import GoalTasksFilterPopup from "../GoalDetailPageMainTasks/GoalTasksFilterPopup";

    const MAX_VISIBLE_DESCRIPTION_LENGTH = 250;

    export default {
        name: "GoalDetailPageHeader",
        components: {
            GoalDetailPageHeaderButtonsPanel,
            GoalDetailPageHeaderTitle,
            SimpleProgressBar,
            GoalTasksFilterPopup
        },
        props: {},
        data() {
            return {
                descriptionExpanded: false,
            };
        },
        computed: {
            filterAllowed() {
                return this.$store.state.goalDetailPageStore.isAdmin || !this.$store.state.goalDetailPageStore.goal.hide_tasks;
            },
            isDescriptionLargerThenPossible() {
                if (this.descriptionExpanded) {
                    return false;
                }
                return this.goal.description && this.goal.description.length > MAX_VISIBLE_DESCRIPTION_LENGTH;
            },
            goalDescription() {
                if (this.isDescriptionLargerThenPossible) {
                    return this.$options.filters.cutName(this.goal.description, MAX_VISIBLE_DESCRIPTION_LENGTH);
                }
                return this.goal.description;
            },

            editMode() {
                return this.$store.state.goalDetailPageStore.editMode;
            },
            completedPercentage() {
                return this.$store.getters['goalDetailPageStore/completedPercentage'];
            },
            goal() {
                return this.$store.state.goalDetailPageStore.goal;
            },
            isMember() {
                return this.$store.state.goalDetailPageStore.isMember;
            },
            isAdmin() {
                return this.$store.state.goalDetailPageStore.isAdmin;
            },
            avatarStyle() {
                const st = {
                    'background-image': `url('${this.goal.avatar}')`
                };
                if (this.goal.uses_default_avatar && !this.goal.uses_generated_avatar) {
                    st['border-radius'] = 0;
                    st['background-size'] = '90%';
                    st['background-position'] = 'center';
                }
                return st;
            },
            progress() {
                return 15;
            }
        },
        methods: {
            showFilterSettings() {
                this.$refs.filterPopup.show();
            },
            showWholeDescription() {
                this.descriptionExpanded = true;
            }
        }
    };
</script>

<style lang="scss">
  @import "../../../../styles/const";
  @import "../../../../styles/mixins";

  .goal-page-header {
    position: relative;
    padding: 5px;
    min-height: 100px;
    width: 100%;
    background-color: #e2e2e2;
    margin-bottom: 15px;

    &__simple-progress-bar {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;

      .vue-simple-progress-text {
        @media (max-width: 1000px) {
          display: none;
        }
      }
    }

    &__title-mobile {
      @media (min-width: 600px) {
        display: none;
      }
      @media (max-width: 599px) {
        display: inline-block;
        width: 100%;
      }
    }

    &__title-desktop {
      @media (max-width: 599px) {
        display: none;
      }
      @media (min-width: 600px) {
        display: inline-block;
      }
    }

    &__avatar-part {
      vertical-align: top;
      display: inline-block;

      @media (min-width: 600px) {
        width: 100px;
      }
      @media (max-width: 599px) {
        width: 100%;
      }

      &__avatar {
        margin-top: 6px;
        position: relative;
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        @media (max-width: 599px) {
          display: inline-block;
          vertical-align: top;
        }
        @media (max-width: 599px) {
          display: none;
        }
      }
    }

    &__content-part {
      vertical-align: top;
      @media (min-width: 600px) {
        width: calc(100% - 105px);
      }
      @media (max-width: 599px) {
        width: 100%;
      }
      display: inline-block;

      &__buttons {
        margin-top: 5px;
        display: inline-block;
        width: 100%;
        vertical-align: top;
      }

      &__description {
        clear: both;

        &__show-all {
          font-weight: bolder;
          cursor: pointer;
        }
      }

      &__progress-bar {
        clear: both;
        margin-top: 15px;

        .progress {
          margin-bottom: 0;
        }
      }

    }
  }
</style>