<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>

        <div class="row">
            <div class="col-sm-12">
                Are you sure you want to hide this {{ 'Goal'|customTerm }}?
            </div>
        </div>

        <div class="row margin-15-top" v-if="forUser">
            <div class="col-sm-12">
                <div class="alert alert-info">
                    <div>
                        <strong>Attention</strong>! You are managing {{ 'Goals'| customTerm }} for
                        <strong>{{ forUser.name }}</strong>.
                    </div>
                    <div v-if="canArchiveForAll" class="margin-10-top">
                        Hiding this {{ 'Goal'| customTerm }} will make it hidden to all users who currently have
                        access.
                        <div class="margin-10-top">
                            Alternatively, use the toggle below to hide this {{ 'Goal'| customTerm }} for <strong>{{
                                forUser.name
                            }}</strong> only.
                        </div>
                    </div>
                    <div v-else class="margin-10-top">
                        Hiding this {{ 'Goal'| customTerm }} will make it hidden only for <strong>{{
                            forUser.name
                        }}</strong>.
                    </div>
                    <div class="margin-10-top">To view hidden goals, select 'Show hidden' in the filter settings. You
                        can unhide a goal by clicking 'Unhide'.
                    </div>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-if="!forUser&&canArchiveForAll">
            <div class="col-sm-12">
                <div class="alert alert-info">
                    <div>
                        <strong>Attention</strong>! Hiding this {{ 'Goal'| customTerm }} will make it hidden to all
                        users who currently have access.
                    </div>
                    <div class="margin-10-top">You can also choose to hide this {{ 'Goal'| customTerm }} only for you
                        using the toggle below.
                    </div>
                </div>
            </div>
        </div>

        <div class="row margin-15-top" v-if="canArchiveForAll">
            <div class="col-sm-12">
                <vue-switch-with-label :label="finishGoalLabel" name="finishGoal" v-model="onlyForTarget"/>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-xs-12 ">
                    <button class="btn btn-mm btn-default save-button" @click="close">
                        Cancel
                    </button>
                    <button class="btn btn-mm btn-green save-button" @click="finish">
                        Hide
                    </button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from '~/vue/mixins/PopupPromiseMixin';

    export default {
        mixins: [PopupPromiseMixin],
        props: {
            canArchiveForAll: Boolean,
            forUser: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                onlyForTarget: false,
            };
        },
        computed: {
            forAll() {
                return this.canArchiveForAll && !this.onlyForTarget;
            },
            header() {
                return `Hide ${this.$customTerm('Goal')}`;
            },
            finishGoalLabel() {
                if (this.forUser) {
                    return `Hide this ${this.$customTerm('Goal')} for ${this.forUser.name} only`;
                } else {
                    return `Hide this ${this.$customTerm('Goal')} only for me`;
                }
            },
        },
        methods: {
            finish() {
                this.save({
                    forAll: this.forAll,
                });
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
