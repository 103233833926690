import urls from 'DjangoUrls';
/*
* Non-angular resource, but compatible
 */
let {DjangoUrls} = urls;
import _ from 'lodash';

function TaskCommentsByCodeResource($http, access_code) {

    return {
        get_list(target_id, params) {
            const url = DjangoUrls[`api.v1:task_comments-get-list`](target_id);
            return $http({
                method: 'GET',
                url,
                params: _.merge({access_code}, params)
            });
        }
    };
}

export default TaskCommentsByCodeResource;