<template>
    <div class="header-title__info">
        <div class="header-title__info__avatar" :style="avatarStyle"></div>
        <div class="header-title__info__title">
            <span class="header-title__title-label" :class="{'header-title__title-label_finished': goal.is_finished}">
                {{ goalTitle }}
                {{ goal.is_finished ? '(Finished)' : '' }}
            </span>

            <span class="header-title__info__title__show-all"
                  v-if="isTitleLargerThenPossible"
                  @click="showWholeTitle">show all</span>
            <audio-toggle :audio-caption="goal.audio_caption"></audio-toggle>
            <i class="fa fa-cog pointer edit-title-pencil" v-show="editMode&&isAdmin"
               @click="editGoalMainParams"></i>
        </div>
        <div class="header-title__info__created">
            {{ 'Goal'|customTerm }} created by <b><a :href="goal.created_by.link">{{ goal.created_by.name }}</a></b>
            <span v-if="goal.owner.id!==goal.created_by.id">for <b><a
                    :href="goal.owner.link">{{ goal.owner.name }}</a></b></span>
            on {{ goal.created_at | gbdate }}
        </div>
        <modal v-if="isTitleLargerThenPossible" v-model="titleOpened" :title="modalTitle"
               :transition="0" :footer="false">
            <p class="goal-page-header__content-part__description">{{ goal.name }}</p>
        </modal>
    </div>
</template>

<script>
    import { GOAL_HEADER_BUS_ACTIONS, goalHeaderBus } from './goalHeaderBus.js';

    const MAX_VISIBLE_TITLE_LENGTH = 200;
    export default {
        name: 'GoalDetailPageHeaderTitle',
        data() {
            return {
                titleExpanded: false,
            };
        },
        computed: {
            modalTitle() {
                return `${this.$customTerm('Goal')} Name`;
            },
            editMode() {
                return this.$store.state.goalDetailPageStore.editMode;
            },
            isAdmin() {
                return this.$store.state.goalDetailPageStore.isAdmin;
            },
            goal() {
                return this.$store.state.goalDetailPageStore.goal;
            },
            avatarStyle() {
                const st = {
                    'background-image': `url('${this.goal.avatar}')`
                };
                if (this.goal.uses_default_avatar && !this.goal.uses_generated_avatar) {
                    st['border-radius'] = 0;
                    st['background-size'] = '90%';
                    st['background-position'] = 'center';
                }
                return st;
            },
            isTitleLargerThenPossible() {
                if (this.titleExpanded) {
                    return false;
                }
                return this.goal.name && this.goal.name.length > MAX_VISIBLE_TITLE_LENGTH;
            },
            goalTitle() {
                if (this.isTitleLargerThenPossible) {
                    return this.$options.filters.cutName(this.goal.name, MAX_VISIBLE_TITLE_LENGTH);
                }
                return this.goal.name;
            },
        },
        methods: {
            editGoalMainParams() {
                goalHeaderBus.$emit(GOAL_HEADER_BUS_ACTIONS.EDIT_GOAL);
            },
            showWholeTitle() {
                this.titleExpanded = true;
            },
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../../styles/const";
  .edit-title-pencil {
    line-height: 12px;
    font-size: 26px;
  }

  .header-title {
    &__title-label{
      &_finished {
        color: $tag-gray;
      }
    }
    &__info {
      display: inline-block;
      @media (max-width: 599px) {
        width: 100%;
      }

      &__title {
        font-size: 22px;
        font-weight: bolder;

        &__show-all {
          font-weight: normal;
          cursor: pointer;
        }
      }

      &__avatar {
        margin-right: 5px;
        position: relative;
        float: left;
        width: 90px;
        height: 90px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        @media (min-width: 600px) {
          display: none;
        }
      }
    }
  }


</style>
