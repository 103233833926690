<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}</h3>
        </div>

        <div class="row">
            <div class="col-sm-12">
                Are you sure you want unhide this {{ 'Goal'|customTerm }}?
            </div>
        </div>
        <div v-if="isCurrentlyFinished">
            <div class="row margin-15-top">
                <div class="col-sm-12">
                    <div class="alert alert-info">
                        <strong>Attention</strong>! Unhiding this {{ 'Goal'|customTerm }} will make it visible to
                        all
                        its members.
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row margin-15-top" v-if="forUser">
                <div class="col-sm-12">
                    <div class="alert alert-info">
                        <strong>Attention</strong>! You are managing <strong>{{ forUser.name }}</strong>'s
                        {{ 'Goals'| customTerm }}.
                        <div>Unhiding this {{ 'Goal'|customTerm }} will make it visible for <strong>{{
                                forUser.name
                            }}</strong></div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer">
            <div class="row">
                <div class="col-xs-12 ">
                    <button class="btn btn-mm btn-default save-button" @click="close">
                        Cancel
                    </button>
                    <button class="btn btn-mm btn-green save-button" @click="finish">
                        Unhide
                    </button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from '~/vue/mixins/PopupPromiseMixin';

    export default {
        mixins: [PopupPromiseMixin],
        props: {
            canArchiveForAll: Boolean,
            forUser: {
                type: Object,
                required: false,
            },
            isCurrentlyFinished: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                forAll: false,
            };
        },
        computed: {
            header() {
                return `Unhide ${this.$customTerm('Goal')}`;
            },
        },
        methods: {
            finish() {
                this.save({
                    forAll: this.canArchiveForAll && this.isCurrentlyFinished && this.forAll,
                });
            }
        },
        mounted() {
            if (this.canArchiveForAll && this.isCurrentlyFinished) {
                this.forAll = true;
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
